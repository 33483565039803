// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";

interface AddChartWidgetProps {
  onSubmit: (query: String) => void;
  onCancel: () => void;
}

function AddChartWidget({
  onSubmit,
  onCancel,
  processing,
  loading,
}: AddChartWidgetProps) {
  const [query, setQuery] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(query);
    setQuery("");
  }
  return (
    <div className="charts-container-chart">
      {processing || loading ? (
        processing ? (
          <>We're processing your query...</>
        ) : (
          <>Loading your charts...</>
        )
      ) : (
        <>
          <div className="chart-delete-button" onClick={onCancel}>
            <ion-icon size="small" name="close-circle-sharp"></ion-icon>
          </div>
          <div className="input-container">
            <label>What would you like this chart to show?&nbsp;</label>
            <input
              value={query}
              onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
              onChange={(e) => setQuery(e.target.value)}
              data-testid="chartQueryInput"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AddChartWidget;
