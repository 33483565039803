import React from "react";
import "./FinStatusIndicator.css";
import useBenchmarkResults from "../../hooks/useBenchmarkResults";
import Tooltip from "../Tooltip/Tooltip";

interface FinStatusIndicatorProps {
  full: boolean;
}

const FinStatusIndicator = ({ full }: FinStatusIndicatorProps) => {
  const { results } = useBenchmarkResults();
  if (results?.length === 0) {
    return null;
  }
  const totalSuccesses = results[0]?.total_successes;
  const totalTests = totalSuccesses + results[0]?.total_failures;
  const successRate = totalSuccesses / totalTests;
  return (
    <Tooltip
      position="bottom"
      text={`Operating at ${Math.round(successRate * 100)}%`}
      zIndex={1000}
    >
      <div className="fin-status-indicator">
        <span
          className={`status-dot ${successRate >= 0.9 ? "green" : "yellow"}`}
        ></span>
        {full && <strong>Fin Status</strong>}
      </div>
    </Tooltip>
  );
};

export default FinStatusIndicator;
