import axios from "axios";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";

import Alert from "../components/Alert/Alert.tsx";

import "./Role.css";

export default function Role() {
  const navigate = useNavigate();

  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const [advisorEmail, setAdvisorEmail] = useState("");

  function handleInputChange(e) {
    e.preventDefault();
    setAdvisorEmail(e.target.value);
  }

  function handleSubmit(e) {
    if (advisorEmail === "") {
      setAlertProps({
        text: "You must enter an advisor email to continue.",
        type: "error",
      });
      return;
    }

    axios
      .patch("/api/me", { advisor: { email: advisorEmail } })
      .then(() => navigate("/u/onboarding"))
      .catch((e) => setAlertProps({ text: e.message, type: "error" }));
  }

  return (
    <div className="overlay">
      <div className="modal">
        <h2>Referring advisor's email</h2>
        {alertProps.text && <Alert {...alertProps} />}
        <p>
          Please enter your referring advisor's email address. Please contact
          your financial advisor for more information.
        </p>
        <input
          className="advisor-email"
          type="text"
          value={advisorEmail}
          onChange={(e) => handleInputChange(e)}
          onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
        />
        <div className="button-container">
          <button className="button primary" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
