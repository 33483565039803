import React from "react";
import Button from "../Button/Button";
import { TOTAL_WALKTHROUGH_STEPS } from "../../constants/global.ts";
import "./Pendo.css";

const Pendo = ({
  content,
  targetRef,
  next,
  prev,
  currentStep,
  setIsActive,
  width = "200px",
  handleClose,
}) => {
  if (!targetRef.current) return null;

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === TOTAL_WALKTHROUGH_STEPS - 1;

  const handleNext = () => {
    if (isLastStep) {
      localStorage.setItem("hideWalkthrough", "true");
      setIsActive(false);
      window.location.pathname = "/u/app/fin/activity";
    }
    next();
  };

  const containerStyle = {
    maxWidth: width,
  };

  return (
    <div className="pendo-container" style={containerStyle}>
      <button className="close-pendo-button" onClick={handleClose}>
        <ion-icon size="small" name="close-circle-sharp"></ion-icon>
      </button>
      {content}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        {!isFirstStep && (
          <Button color="Secondary" size="Small" onClick={prev}>
            Previous
          </Button>
        )}
        <Button color="Secondary" size="Small" onClick={handleNext}>
          {isLastStep ? "Exit" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Pendo;
