import React from "react";
import "./DataTable.css";

interface DataTableProps {
  columnLabels: String[];
  data: any[][];
}

const DataTable = ({ columnLabels, data }: DataTableProps) => {
  return (
    <div className="data-table">
      <table>
        <thead>
          {columnLabels.map((label, i) => (
            <th key={`header${i}`}>{label}</th>
          ))}
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={`row${i}`}>
              {row.map((field, i) => (
                <td key={`cell${i}`}>{field}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
