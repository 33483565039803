import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useWalkthrough } from "../Walkthrough/Walkthrough.tsx";
import Pendo from "../../components/Pendo/Pendo.tsx";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext.tsx";
import ProgressBar from "../../components/ProgressBar/ProgressBar.tsx";
import HeaderAlert from "../../components/Alert/HeaderAlert.tsx";
import Modal from "../../components/Modal/Modal.tsx";
import Checklist from "../../components/Checklist/Checklist.tsx";
import Button from "../../components/Button/Button.tsx";
import { goToHelp, logout } from "../../utils/shared.ts";
import "./Navbar.css";


const NUM_ONBOARDING_TOUCH_POINTS = 100;
const NUM_INITIAL_ONBOARDING_TOUCH_POINTS = 5;

function Navbar() {
  const {
    state: { user, errorInfo, usage, tasks, selectedRoute },
    actions: { updateTaskData, setSelectedRoute },
  } = useAppContext();

  const onboardingTasks = tasks.filter(
    (task) => task.category === "onboarding",
  );

  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const {
    registerRef,
    unregisterRef,
    currentStep,
    refs,
    nextStep,
    prevStep,
    isActive,
    setIsActive,
    setCurrentStep,
    setActiveSlide,
    handleClose,
  } = useWalkthrough();
  const stepId = "walkthrough";
  const ref = useRef(null);

  useEffect(() => {
    registerRef(stepId, ref);
    return () => unregisterRef(stepId);
  }, [registerRef, unregisterRef, stepId]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const logoutAndCloseDropdown = (e) => {
    logout(e);
    setDropdownOpen(false);
  };

  const navigateToPage = (path) => {
    setDropdownOpen(false);
    setSelectedRoute("/u/app/settings/profile");
    navigate(path, { replace: true });
  };

  const NAVBAR_DROPDOWN_ITEMS = [
    {
      label: "Profile",
      action: navigateToPage,
      icon: "person-circle-outline",
      path: "/u/app/settings/profile",
      enabled: true,
    },
    {
      label: "Fin Settings",
      action: navigateToPage,
      icon: "cog-outline",
      path: "/u/app/settings/fin-settings",
      enabled: true,
    },
    {
      label: "Data Controls",
      action: navigateToPage,
      icon: "server-outline",
      path: "/u/app/settings/data-controls",
      enabled: true,
    },
    {
      label: "Help",
      action: goToHelp,
      icon: "help-circle-outline",
      enabled: true,
    },
    {
      label: "Logout",
      action: logoutAndCloseDropdown,
      icon: "log-out-outline",
      enabled: true,
    },
  ];

  useEffect(() => {
    if (usage) {
      setProgress(usage.total);
    }
  }, [usage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the listener when the component is unmounted or if dropdown is closed
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const allComplete = onboardingTasks.every((task) => task.is_completed);
  const avatarLetter = user ? user.email[0] : "";
  const isDemo = user?.subscription?.name.includes("demo");
  const showChecklist = !allComplete && !isDemo;
  const showProgress =
    allComplete &&
    progress >= NUM_INITIAL_ONBOARDING_TOUCH_POINTS &&
    progress < NUM_ONBOARDING_TOUCH_POINTS &&
    !isDemo;

  return (
    <div className="navbar">
      <div className="navbar-left-container">
        {showChecklist && (
          <div className="nav-onboarding-wrapper">
            <Button
              color="Secondary"
              onClick={() => setIsChecklistOpen(!isChecklistOpen)}
            >
              Onboarding Checklist
            </Button>
          </div>
        )}
        <Modal
          isOpen={isChecklistOpen}
          onClose={() => setIsChecklistOpen(false)}
          closeOnClickOutside
          hasCloseButton
        >
          <Checklist tasks={onboardingTasks} updateTask={updateTaskData} />
        </Modal>
        <div className="home-sidebar-items">
          <Link
            className="home-sidebar-item"
            to="/u/app/fin/activity"
            onClick={() => setSelectedRoute("/u/app/fin/activity")}
          >
            <span
              className={`nav-item-text ${
                selectedRoute === "/u/app/fin/activity" ? "active" : ""
              }`}
            >
              <ion-icon name="home-outline"></ion-icon>
              <span>Home</span>
            </span>
          </Link>
          {user?.role == "advisor" && (
            <Link
              className="home-sidebar-item"
              to="/u/app/investors"
              onClick={() => setSelectedRoute("/u/app/investors")}
            >
              <span
                className={`nav-item-text ${
                  selectedRoute === "/u/app/investors" ? "active" : ""
                }`}
              >
                <ion-icon name="person-outline"></ion-icon>
                <span>Clients</span>
              </span>
            </Link>
          )}
          <Link
            className="home-sidebar-item"
            to="/u/app/settings/fin-settings"
            onClick={() => setSelectedRoute("/u/app/settings/fin-settings")}
          >
            <span
              className={`nav-item-text ${
                selectedRoute === "/u/app/settings/fin-settings" ? "active" : ""
              }`}
            >
              <ion-icon name="settings-outline"></ion-icon>
              <span>Fin</span>
            </span>
          </Link>
        </div>
        <HeaderAlert errorInfo={errorInfo} />
      </div>
      {showProgress && (
        <div className="nav-progress-wrapper">
          <div className="nav-progress-container">
            <ProgressBar progress={progress} />
          </div>
          <div className="nav-progress-message">{`${
            NUM_ONBOARDING_TOUCH_POINTS - progress
          } touch points until Fin is fully calibrated`}</div>
        </div>
      )}
      <div className="nav-items">
        {user && (
          <div className="dropdown-container" ref={dropdownRef}>
            <button
              className="avatar-btn"
              data-testid="avatarButton"
              onClick={toggleDropdown}
            >
              {avatarLetter}
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu">
                {NAVBAR_DROPDOWN_ITEMS.map((item, i) => {
                  if (!item.enabled) {
                    return null;
                  }
                  return (
                    <button
                      key={i}
                      className="navbar-dropdown-button"
                      onClick={(e) => {
                        if (item.path) {
                          item.action(item.path);
                        } else {
                          item.action(e);
                        }
                      }}
                    >
                      <div className="nav-dropdown-item">
                        <ion-icon name={item.icon}></ion-icon>
                        {item.label}
                      </div>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;