import React from "react";
import "./SettingsForm.css";

const SettingsForm = ({ children }) => {
  return <div className="settings-form">{children}</div>;
};

interface SettingsMainProps {
  children: React.ReactNode;
  full?: boolean;
}

const SettingsMain = ({ children, full }: SettingsMainProps) => {
  return (
    <div className={`settings-main ${full ? "full" : ""}`}>{children}</div>
  );
};

const SettingsSide = ({ children }) => {
  return (
    <>
      <div className="flex-column-divider"></div>
      <div className="settings-side">{children}</div>
    </>
  );
};

interface SettingsGroupProps {
  title?: string;
  align?: "column" | "row";
  children: React.ReactNode;
}

const SettingsGroup = ({
  title,
  align = "column",
  children,
}: SettingsGroupProps) => {
  return (
    <div className={`settings-group ${align}`}>
      <h3>{title}</h3>
      {children}
    </div>
  );
};

SettingsForm.Main = SettingsMain;
SettingsForm.Side = SettingsSide;
SettingsForm.Group = SettingsGroup;

export default SettingsForm;
