import React from "react";

interface Props {
  children: React.ReactNode;
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  style: React.CSSProperties;
}

const Pill = ({ children, selected, onClick, style }: Props) => {
  return (
    <div
      className={`pill ${selected ? "selected" : ""}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  );
};

export default Pill;
