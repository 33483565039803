// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext.tsx";
import { Link, Outlet } from "react-router-dom";
import summarizeSVG from "../../../../assets/summarize.svg";
import Chatwindow from "../../components/Chatbox/chatwindow.tsx";
import getString from "../../utils/getString.ts";
import { ERRORS } from "../../constants/error.ts";
import { useWalkthrough } from "../../features/Walkthrough/Walkthrough.tsx";

import "./Fin.css";

const FIN_TABS = [
  {
    name: "activity",
    path: "/u/app/fin/activity",
  },
  {
    name: "upload",
    path: "/u/app/fin/upload",
  },
  {
    name: "assignments",
    path: "/u/app/fin/assignments",
  },
  // {
  //   name: "optimize",
  //   path: "/u/app/fin/optimize",
  // },
  {
    name: "files",
    path: "/u/app/fin/files",
  },
];

function Fin() {
  const {
    state: { user },
    actions: { setErrorInfo },
  } = useAppContext();
  const { isActive, activeSlide, currentStep } = useWalkthrough();
  const reports = [
    // {
    //   name: getString("dailyRiskReport"),
    //   status: getString("complete"),
    //   iconName: "checkmark-circle-outline"
    // },
    // {
    //   name: getString("monthlyReviewDeck"),
    //   status: getString("inProgress"),
    //   iconName: "cog-sharp"
    // },
    // {
    //   name: getString("quarterlyFundReview"),
    //   status: getString("actionNeeded"),
    //   iconName: "alert-circle-outline"
    // },
  ];

  return (
    <div className="home-wrapper">
      <div className="fin-content">
        {reports.length > 0 && (
          <div className="header-stats-container">
            {reports.map((r, idx) => {
              return (
                <div key={idx} className="header-stats-item">
                  <div className="icon-container">
                    <ion-icon name={r.iconName}></ion-icon>
                  </div>
                  <div className="header-stats-item-text">
                    <div className="header-stats-item-key">{r.name}</div>
                    <div className="header-stats-item-value">
                      <strong>{r.status}</strong>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
}

export default Fin;
