import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

interface WalkthroughContextType {
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  registerRef: (id: string, ref: React.RefObject<any>) => void;
  unregisterRef: (id: string) => void;
  refs: { [key: string]: React.RefObject<any> };
  location: string | null;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  setCurrentStep: (step: number) => void;
  activeSlide: number;
  setActiveSlide: (slide: number) => void;
  handleClose: () => void;
}

const defaultState: WalkthroughContextType = {
  currentStep: 0,
  nextStep: () => {},
  prevStep: () => {},
  registerRef: () => {},
  unregisterRef: () => {},
  refs: {},
  location: null,
  isActive: true,
  setIsActive: () => {},
  setCurrentStep: () => {},
  activeSlide: 0,
  setActiveSlide: () => {},
  handleClose: () => {},
};

const WalkthroughContext = createContext<WalkthroughContextType>(defaultState);

const stepActions = [
  { step: 3, prevLocation: "/u/app/home" },
  { step: 5, toLocation: "/u/app/settings/fin-settings" },
  { step: 2, toLocation: "/u/app/home" },
  { step: 4, prevLocation: "/u/app/home" },
];

export const WalkthroughProvider: React.FC<{
  children: React.ReactNode;
  totalSteps: number;
}> = ({ children, totalSteps }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [refs, setRefs] = useState<{ [key: string]: React.RefObject<any> }>({});
  const [location, setLocation] = useState<string | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const hideWalkthrough = true; //localStorage.getItem("hideWalkthrough") === "true";

  const completeWalkthrough = () => setIsActive(false);

  useEffect(() => {
    if (currentStep >= totalSteps || hideWalkthrough) {
      completeWalkthrough();
    }
  }, [currentStep, totalSteps]);

  const nextStep = useCallback(() => {
    setCurrentStep((s) => {
      const nextStep = s + 1;
      const action = stepActions.find((action) => action.step === nextStep);
      if (action) {
        setLocation(action.toLocation);
      }

      setActiveSlide(0);
      return nextStep;
    });
  }, [stepActions]);

  const prevStep = useCallback(() => {
    setCurrentStep((s) => {
      const prevStep = Math.max(0, s - 1);
      const action = stepActions.find((action) => action.step === prevStep);

      if (action && action.prevLocation) {
        setLocation(action.prevLocation);
      }

      return prevStep;
    });
  }, [stepActions]);

  const handleClose = () => {
    localStorage.setItem("hideWalkthrough", "true");
    setIsActive(false);
    window.location.pathname = "/u/app/home";
  };

  const registerRef = useCallback((id: string, ref: React.RefObject<any>) => {
    setRefs((prevRefs) => ({ ...prevRefs, [id]: ref }));
  }, []);

  const unregisterRef = useCallback((id: string) => {
    setRefs((prevRefs) => {
      const newRefs = { ...prevRefs };
      delete newRefs[id];
      return newRefs;
    });
  }, []);

  return (
    <WalkthroughContext.Provider
      value={{
        currentStep,
        nextStep,
        prevStep,
        registerRef,
        unregisterRef,
        refs,
        location,
        isActive,
        setIsActive,
        setCurrentStep,
        activeSlide,
        setActiveSlide,
        handleClose,
      }}
    >
      {children}
    </WalkthroughContext.Provider>
  );
};

export const useWalkthrough = (): WalkthroughContextType =>
  useContext(WalkthroughContext);
