import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getBenchmarkResults } from "../api/api";

interface Props {
  onError?: (message: string) => void;
}

const useBenchmarkResults = (props?: Props) => {
  const [results, setResults] = useState([]);
  const [processing, setProcessing] = useState(false);

  const refresh = () => {
    setProcessing(true);
    getBenchmarkResults()
      .then((data) => {
        setProcessing(false);
        setResults(data);
      })
      .catch((e) => {
        if (props?.onError) props.onError(e);
        setProcessing(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  return {
    processing,
    results,
  };
};

export default useBenchmarkResults;
