// @ts-nocheck

import React from "react";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";

import ChartDataLabels from "chartjs-plugin-datalabels";

import { LinearScale, CategoryScale, Chart } from "chart.js";
import {
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Legend,
} from "chart.js";

const backgroundColors = [
  "rgba(40,94,110,0.3)",
  "rgba(57,124,128,0.3)",
  "rgba(155,200,183,0.3)",
  "rgba(225,158,55,0.3)",
  "rgba(189,108,39,0.3)",
  "rgba(173,71,30,0.3)",
  "rgba(40,94,110,0.6)",
  "rgba(57,124,128,0.6)",
  "rgba(155,200,183,0.6)",
  "rgba(225,158,55,0.6)",
  "rgba(189,108,39,0.6)",
  "rgba(173,71,30,0.6)",
];

const borderColors = [
  "rgba(40,94,110,0)",
  "rgba(57,124,128,0)",
  "rgba(155,200,183,0)",
  "rgba(225,158,55,0)",
  "rgba(189,108,39,0)",
  "rgba(173,71,30,0)",
];

Chart.register(LinearScale, CategoryScale);
Chart.register(
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Legend,
  ChartDataLabels
);

export const TextChart = ({ data, title }) => {
  return (
    <div className="chart-canvas-container">
      <p>{data}</p>
      <strong className="">{title}</strong>
    </div>
  );
};

export const BarChart = ({ bars: df, title }) => {
  const metric = Object.keys(df)[0];
  const bars = df[metric];
  const data = {
    labels: Object.keys(bars),
    datasets: [
      {
        data: Object.keys(bars).map((key) => {
          const s = bars[key];
          if (typeof s == "string") {
            const withoutPercent = s.replace("%", "");
            const decimalValue = parseFloat(withoutPercent);
            return decimalValue;
          } else {
            return s;
          }
        }),
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
  };

  return (
    <div className="chart-canvas-container">
      <Bar data={data} options={options} />
      <strong className="">{title}</strong>
      <span>({metric})</span>
    </div>
  );
};

export const LineChart = ({ lineData, title }) => {
  const labels = [];
  const datasets = [];

  Object.keys(lineData).forEach((k, idx) => {
    if (idx === 0) {
      Object.keys(lineData[k]).forEach((ts) => {
        labels.push(ts);
      });
    }
    datasets.push({
      label: k,
      data: Object.values(lineData[k]),
      backgroundColor: backgroundColors[idx % backgroundColors.length],
      borderColor: backgroundColors[idx % backgroundColors.length],
      tension: 0.1, // This adds curviness to the lines. Can be adjusted as needed.
    });
  });

  const data = {
    labels: Object.keys(lineData),
    datasets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  return (
    <div className="chart-canvas-container">
      <Line data={data} options={options} />
      <strong className="">{title}</strong>
    </div>
  );
};

export const StyledTable = ({ tableData, title }) => {
  const data = Object.keys(tableData).map((key) => {
    return {
      key: key,
      value: Object.values(tableData[key])[0],
    };
  });

  return (
    <div className="chart-canvas-container">
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: borderColors[0],
        }}
      >
        <thead>
          <tr style={{ backgroundColor: backgroundColors[0] }}>
            <th
              style={{
                border: borderColors[0],
                padding: "10px",
              }}
            >
              Key
            </th>
            <th
              style={{
                border: borderColors[0],
                padding: "10px",
              }}
            >
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} style={{ borderBottom: borderColors[1] }}>
              <td
                style={{
                  border: borderColors[1],
                  padding: "10px",
                }}
              >
                {row.key}
              </td>
              <td
                style={{
                  border: borderColors[1],
                  padding: "10px",
                }}
              >
                {row.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <strong className="">{title}</strong>
    </div>
  );
};

export const PieChart = ({ allocation: df, title }) => {
  const metric = Object.keys(df)[0];
  const allocation = df[metric];
  const data = {
    labels: Object.keys(allocation),
    datasets: [
      {
        label: metric,
        data: Object.values(allocation),
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    tooltips: {
      enabled: true,
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      datalabels: {
        formatter: (value, ctx) => {
          return value.toFixed(1) + "%";
        },
        color: "#555",
      },
    },
  };

  return (
    <div className="chart-canvas-container">
      <Pie data={data} options={options} />
      <strong className="">{title}</strong>
      <span>({metric})</span>
    </div>
  );
};
