import React from "react";
import { useAssignmentContext } from "./../../contexts/AssignmentContext";
import getString from "../../utils/getString";
import "./Assignment.css";

function Description() {
  const {
    state: { assignmentDescription },
    actions: { setAssignmentDescription },
  } = useAssignmentContext();

  const handleDescriptionChange = (e) => {
    setAssignmentDescription(e.target.value);
  };

  return (
    <div>
      <h4 className="description-header">{getString("description")}</h4>
      <textarea
        className="description-text-area"
        maxLength={1000}
        onChange={handleDescriptionChange}
        placeholder="Example: Monthly performance summary with bar chart"
        rows={5}
        value={assignmentDescription}
      />
    </div>
  );
}

export default Description;
