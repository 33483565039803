import React from "react";

const usePortfolioUtils = (portfolios) => {
  const getOptimizationPreferences = (portfolioId: string | "all") => {
    if (portfolioId !== "all") {
      const portfolio = portfolios.find(
        (portfolio) => portfolio.id === parseInt(portfolioId),
      );
      return {
        metric: portfolio.optimization_preferences.metric,
        lower_bound: portfolio.optimization_preferences.lower_bound,
        upper_bound: portfolio.optimization_preferences.upper_bound,
      };
    } else {
      return {
        metric: getSharedOptimizationPreference("metric"),
        lower_bound: getSharedOptimizationPreference("lower_bound"),
        upper_bound: getSharedOptimizationPreference("upper_bound"),
      };
    }
  };

  const getSharedOptimizationPreference = (property) => {
    if (
      portfolios.filter(
        (portfolio) =>
          portfolio.optimization_preferences[property] !==
          portfolios[0].optimization_preferences[property],
      ).length > 0
    ) {
      return "-";
    } else {
      return portfolios[0].optimization_preferences[property];
    }
  };

  return { getOptimizationPreferences };
};

export default usePortfolioUtils;
