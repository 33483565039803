import React, { useState } from "react";
import Alert from "../../components/Alert/Alert";
import SettingsForm from "../../features/SettingsForm/SettingsForm";
import ContactForm from "../../components/ContactForm/ContactForm";
import { DATA_CONTROLS_FORM_FIELDS } from "../../constants/forms";
import { useAppContext } from "../../contexts/AppContext";

const DataControls = () => {
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });

  const {
    state: { user },
  } = useAppContext();

  return (
    <>
      {!user?.subscription?.has_profile_access && (
        <div className="feature-disabled"></div>
      )}
      {alertProps.text && <Alert {...alertProps} />}
      <SettingsForm>
        <SettingsForm.Main>
          <h2 className="settings-header">Data Controls</h2>
          <SettingsForm.Group title="Your data is your data.">
            <p>
              You own all uploaded data, queries, and generated output. Your
              data is only used to improve your individual model and is not used
              to train our models or other users' models.
            </p>
            <p>
              Read our{" "}
              <a href="/policy/privacy" target="_blank">
                Privacy Policy
              </a>{" "}
              to learn more.
            </p>
            <p>
              For requests related to regulatory compliance, or if you would
              like to delete your chat history or reset Fin, please fill out the
              form below.
            </p>
          </SettingsForm.Group>
          <SettingsForm.Group title="Data Requests">
            <ContactForm
              name="Data Controls Form"
              fields={DATA_CONTROLS_FORM_FIELDS}
              onResponse={setAlertProps}
            />
          </SettingsForm.Group>
        </SettingsForm.Main>
      </SettingsForm>
    </>
  );
};

export default DataControls;
