import React, { useState } from "react";
import { useAppContext } from "../contexts/AppContext";
import { disableService as apiDisableService } from "../api/api";
import { IntegrationService } from "../types/types";

const useIntegrations = () => {
  const {
    state: { integrationServices },
    actions: { setIntegrationServices },
  } = useAppContext();
  const [selectedService, setSelectedService] =
    useState<IntegrationService>(null);

  const enableService = (service: IntegrationService) => {
    const updatedService = integrationServices;
    updatedService[service.key].connected = true;
    setIntegrationServices({
      ...integrationServices,
      ...updatedService,
    });
    switch (service?.key) {
      case "wealthbox":
        let clientId;
        if (window.location.hostname.includes("app.quantumai.dev")) {
          clientId = "b27jvq48DAHRWDwqP3jWGQELKdmjBqICz5s0HuryjKs";
        } else {
          clientId = "kWEe3VFUspRpuJpquImzJVi40V3CdVOqmgT52mr9Ftg";
        }
        const callbackUri = encodeURIComponent(
          `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? ":" + window.location.port : ""
          }/api/oauth/wealthbox`,
        );
        window.location.href = `https://app.crmworkspace.com/oauth/authorize?client_id=${clientId}&redirect_uri=${callbackUri}&response_type=code&scope=login+data`;
        break;
      case "orion":
        const orionRedirectUri = encodeURIComponent(
          `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? ":" + window.location.port : ""
          }/api/oauth/orion`,
        );
        if (window.location.hostname.includes("app.quantumai.dev")) {
          window.location.href = `https://api.orionadvisor.com/api/oauth/?response_type=code&redirect_uri=${orionRedirectUri}&client_id=1997&state=${window.location}`;
        } else {
          window.location.href = `https://stagingapi.orionadvisor.com/api/oauth/?response_type=code&redirect_uri=${orionRedirectUri}&client_id=1997&state=${window.location}`;
        }
        break;
      case "linkedin":
        const linkedInRedirectUri = encodeURIComponent(
          `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? ":" + window.location.port : ""
          }/api/oauth/linkedin`,
        );
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&redirect_uri=${linkedInRedirectUri}&client_id=86zt7t02ylnkot&state=${window.location}&scope=w_member_social%20profile%20email%20openid`;
        break;
      case "redtail":
        setSelectedService(service);
        break;
    }
  };

  const disableService = (service: string) => {
    apiDisableService({
      name: service,
    }).then(() => {
      const updatedService = integrationServices;
      updatedService[service].connected = false;
      setIntegrationServices({
        ...integrationServices,
        ...updatedService,
      });
    });
  };

  const isServiceConnected = (service: string) => {
    return integrationServices[service]?.connected;
  };

  const isIntegrationProcessing = (service: string) => {
    return (
      isServiceConnected(service) && !integrationServices[service]?.complete
    );
  };

  return {
    enableService,
    disableService,
    isServiceConnected,
    isIntegrationProcessing,
    selectedService,
    setSelectedService,
  };
};

export default useIntegrations;
