import React from "react";
import TextInput from "./TextInput";
import { Field } from "../../types/types";

import "./Fieldset.css";

interface Props {
  values: {};
  fields: Field[];
  errors?: {}[];
  onChange: (newValues: {}) => void;
}

const Fieldset = ({ values, fields, onChange, errors }: Props) => {
  if (!values) return null;
  return (
    <div className="fieldset">
      {fields.map((field) => (
        <TextInput
          key={field.id}
          label={field.label}
          value={values[field.id] ?? ""}
          placeholder={field.placeholder}
          multiline={field.multiline}
          required={field.required}
          errors={errors && errors[field.id]}
          onChange={(e) => {
            const newValues = { ...values };
            newValues[field.id] = e.target.value;
            onChange(newValues);
          }}
        />
      ))}
    </div>
  );
};

export default Fieldset;
