import React, { useEffect, useRef, ReactNode } from "react";
import "./Modal.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  hasCloseButton?: boolean;
  closeOnClickOutside?: boolean;
  width?: number;
  height?: number;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  height,
  hasCloseButton = false,
  closeOnClickOutside = false,
  width = 300,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        closeOnClickOutside &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeOnClickOutside, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div
        className="modal"
        ref={modalRef}
        style={{ width: `${width}px`, height: height ? `${height}px` : "auto" }}
      >
        {hasCloseButton && (
          <button
            className="close-button"
            data-testid="closeButton"
            onClick={onClose}
          >
            <ion-icon size="small" name="close-circle-sharp"></ion-icon>
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
