import React, { useState, useEffect } from "react";
import "./Stats.css";
import axios from "axios";
import SlideoutBox from "../../components/SlideoutBox/SlideoutBox.tsx";
import Checklist from "../../components/Checklist/Checklist.tsx";
import AUMSummary from "../../components/AUMSummary/AUMSummary.tsx";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext.tsx";

const Stats = () => {
  const [stats, setStats] = useState([]);
  const navigate = useNavigate();

  const {
    state: { user, numAttentionRequired },
    actions: { setSelectedRoute },
  } = useAppContext();

  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);
  const [slideoutContent, setSlideoutContent] = useState(null);
  const [slideoutPosition, setSlideoutPosition] = useState(null);

  function getStats(advisor_id) {
    axios.get(`/api/advisor/${advisor_id}/stats`).then((response) => {
      setStats(response.data);
    });
    // .catch((e) => setAlertProps({ text: e.message, type: "error" }));
  }

  useEffect(() => {
    if (user && user.advisor_id) {
      getStats(user.advisor_id);
    }
  }, [user]);

  const handleClickAccounts = () => {
    navigate(`/u/app/investors`);
    setSelectedRoute(`/u/app/investors`);
  };

  const handleClickAUM = (rect) => {
    setSlideoutContent("aum");
    setSlideoutPosition(rect);
    setIsSlideoutOpen((prevState) => !prevState);
  };

  const handleClickAttention = (rect) => {
    setSlideoutContent("attention");
    setSlideoutPosition(rect);
    setIsSlideoutOpen((prevState) => !prevState);
  };

  const statItems = [
    {
      title: "Total AUM",
      value: stats && stats.total_aum,
      icon: "calculator-outline",
      onClick: handleClickAUM,
    },
    {
      title: "Accounts",
      value: stats && stats.n_portfolios,
      icon: "folder-outline",
      onClick: user?.role == "advisor" ? handleClickAccounts : null,
    },
    {
      title: "Attention Required",
      value: numAttentionRequired,
      icon: "alert-circle-outline",
      onClick: handleClickAttention,
    },
  ];

  const SlideoutContent = ({ content }) => {
    switch (content) {
      case "aum":
        return <AUMContent />;
      case "attention":
        return <AttentionContent />;
      default:
        return null;
    }
  };

  const AUMContent = () => {
    return <AUMSummary />;
  };

  const AttentionContent = () => {
    // Implement the content for the Attention Required slideout
    return <Checklist tasks={tasks} updateTask={updateTaskData} />;
  };

  const {
    state: { tasks },
    actions: { updateTaskData },
  } = useAppContext();

  return (
    <div className="header-stats-container">
      {statItems.map((item, index) => (
        <StatItem key={index} {...item} />
      ))}
      <SlideoutBox
        isOpen={isSlideoutOpen}
        onClose={() => setIsSlideoutOpen(false)}
        position={slideoutPosition}
      >
        <SlideoutContent content={slideoutContent} />
      </SlideoutBox>
    </div>
  );
};

const StatItem = React.forwardRef(({ title, value, icon, onClick }, ref) => (
  <div
    className={`header-stats-item ${onClick ? "button-item" : ""}`}
    onClick={(e) => {
      if (onClick) {
        const rect = e.currentTarget.getBoundingClientRect();
        onClick(rect);
      }
    }}
    ref={ref}
  >
    <div className="header-icon-container">
      <ion-icon name={icon} size="small"></ion-icon>
    </div>
    <div className="header-stats-item-text">
      <div className="header-stats-item-key">{title}</div>
      <div className="header-stats-item-value">{value}</div>
    </div>
  </div>
));

export default Stats;
