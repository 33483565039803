import { useState } from "react";
import { createLinkedinPost } from "../api/api.ts";
import { useAppContext } from "./../contexts/AppContext";

const usePostLinkedin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const {
    actions: { setAlertInfo },
  } = useAppContext();

  const postToLinkedin = ({ content }) => {
    setLoading(true);
    setError(null);

    return createLinkedinPost({ content })
      .then((response) => {
        setResponse(response.data);
        setAlertInfo({
          text: "Successfully posted to Linkedin",
          type: "success",
        });
        return response.data;
      })
      .catch((err) => {
        setError(err.message || "An unexpected error occurred.");
        setAlertInfo({ text: "Error posting to Linkedin", type: "error" });
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    response,
    postToLinkedin,
  };
};

export default usePostLinkedin;
