import React, {
  RefObject,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  AlertInfo,
  ErrorInfo,
  Usage,
  Task,
  Services,
  File,
} from "../types/types";
import useFetchTasks from "../hooks/useFetchTasks";
import { useLocation } from "react-router-dom";

export enum ActionTypes {
  SET_ERROR_INFO = "SET_ERROR_INFO",
  SET_ALERT_INFO = "SET_ALERT_INFO",
  SET_USAGE = "SET_USAGE",
  SET_USER = "SET_USER",
  SET_TASKS = "SET_TASKS",
  SET_FILES = "SET_FILES",
  SET_NUM_ATTENTION_REQUIRED = "SET_NUM_ATTENTION_REQUIRED",
  UPLOAD_PIN_FILES = "UPLOAD_PIN_FILES",
  SET_PINNED_FILENAMES = "SET_PINNED_FILENAMES",
  SET_INTEGRATION_SERVICES = "SET_INTEGRATION_SERVICES",
  SET_SELECTED_ROUTE = "SET_SELECTED_ROUTE",
  SET_CONTAINER_REF = "SET_CONTAINER_REF",
}
export const AppContext = createContext({} as AppStore);

type ContextState = {
  errorInfo: ErrorInfo;
  alertInfo: AlertInfo;
  user: any;
  usage: Usage;
  tasks: Task[];
  numAttentionRequired: number;
  uploadPinnedFiles: File[];
  pinnedFilenames: string[];
  integrationServices: Services;
  selectedRoute: string;
  containerRef: RefObject<HTMLDivElement>;
  files: File[];
};

type setActionType = {
  type: ActionTypes;
  payload: any;
};

type AppContextProviderProps = {
  children: any;
  user: any;
  usage: Usage;
  containerRef: RefObject<HTMLDivElement>;
};

const reducer = (state: ContextState, action: setActionType): ContextState => {
  switch (action.type) {
    case ActionTypes.SET_ERROR_INFO: {
      return {
        ...state,
        errorInfo: action.payload,
      };
    }
    case ActionTypes.SET_ALERT_INFO: {
      return {
        ...state,
        alertInfo: action.payload,
      };
    }
    case ActionTypes.SET_FILES: {
      return {
        ...state,
        files: action.payload,
      };
    }
    case ActionTypes.SET_CONTAINER_REF: {
      return {
        ...state,
        containerRef: action.payload,
      };
    }
    case ActionTypes.SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case ActionTypes.SET_USAGE: {
      return {
        ...state,
        usage: action.payload,
      };
    }
    case ActionTypes.SET_TASKS: {
      return {
        ...state,
        tasks: action.payload,
      };
    }
    case ActionTypes.SET_NUM_ATTENTION_REQUIRED: {
      return {
        ...state,
        numAttentionRequired: action.payload,
      };
    }
    case ActionTypes.UPLOAD_PIN_FILES: {
      return {
        ...state,
        uploadPinnedFiles: action.payload,
      };
    }
    case ActionTypes.SET_PINNED_FILENAMES: {
      return {
        ...state,
        pinnedFilenames: action.payload,
      };
    }
    case ActionTypes.SET_INTEGRATION_SERVICES: {
      return {
        ...state,
        integrationServices: action.payload,
      };
    }
    case ActionTypes.SET_SELECTED_ROUTE: {
      return {
        ...state,
        selectedRoute: action.payload,
      };
    }
    default:
      console.warn("Not a valid action type");
      return state;
  }
};

const defaultState: ContextState = {
  containerRef: null,
  errorInfo: null,
  alertInfo: null,
  user: null,
  usage: null,
  tasks: [],
  selectedRoute: "",
  numAttentionRequired: 0,
  uploadPinnedFiles: [],
  pinnedFilenames: [],
  files: [],
  integrationServices: {
    googleDrive: { connected: false, complete: false },
    googleCalendar: { connected: false, complete: false },
    outlook: { connected: false, complete: false },
    orion: { connected: false, complete: false },
    linkedin: { connected: false, complete: false },
    redtail: { connected: false, complete: false },
    wealthbox: { connected: false, complete: false },
  },
};

type AppStore = {
  state: ContextState;
  actions: {
    setContainerRef: (ref: RefObject<HTMLDivElement>) => void;
    setSelectedRoute: (route: string) => void;
    setErrorInfo: (errorInfo: any) => void;
    setAlertInfo: (errorInfo: any) => void;
    setUsage: (usage: Usage) => void;
    updateTaskData: (task: Task) => void;
    setTasks: (tasks: Task[]) => void;
    setUploadPinnedFiles: (files: File[]) => void;
    setPinnedFilenames: (filenames: string[]) => void;
    setIntegrationServices: (services: Services) => void;
    setFiles: (files: File[]) => void;
  };
};

export function AppContextProvider({
  children,
  user,
  usage,
  containerRef,
}: AppContextProviderProps) {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { tasks, updateTask } = useFetchTasks();

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_CONTAINER_REF,
      payload: containerRef,
    });
    dispatch({
      type: ActionTypes.SET_USER,
      payload: user,
    });
    dispatch({
      type: ActionTypes.SET_USAGE,
      payload: usage,
    });
    dispatch({
      type: ActionTypes.SET_TASKS,
      payload: tasks,
    });
    dispatch({
      type: ActionTypes.SET_NUM_ATTENTION_REQUIRED,
      payload: tasks.length,
    });
    dispatch({
      type: ActionTypes.SET_SELECTED_ROUTE,
      payload: location.pathname,
    });
  }, [user, usage, tasks]);

  const store: AppStore = {
    state: {
      containerRef: state.containerRef,
      selectedRoute: state.selectedRoute,
      errorInfo: state.errorInfo,
      alertInfo: state.alertInfo,
      user: state.user,
      usage: state.usage,
      tasks: state.tasks,
      numAttentionRequired: state.numAttentionRequired,
      uploadPinnedFiles: state.uploadPinnedFiles,
      pinnedFilenames: state.pinnedFilenames,
      integrationServices: state.integrationServices,
      files: state.files,
    },
    actions: {
      setContainerRef: (ref) => {
        dispatch({
          type: ActionTypes.SET_CONTAINER_REF,
          payload: ref,
        });
      },
      setFiles: (files) => {
        dispatch({
          type: ActionTypes.SET_FILES,
          payload: files,
        });
      },
      setSelectedRoute: (route) => {
        dispatch({
          type: ActionTypes.SET_SELECTED_ROUTE,
          payload: route,
        });
      },
      setErrorInfo: (errorInfo) => {
        dispatch({
          type: ActionTypes.SET_ERROR_INFO,
          payload: errorInfo,
        });
      },
      setAlertInfo: (alertInfo) => {
        dispatch({
          type: ActionTypes.SET_ALERT_INFO,
          payload: alertInfo,
        });
      },
      setUsage: (usage) => {
        dispatch({
          type: ActionTypes.SET_USAGE,
          payload: usage,
        });
      },
      setUploadPinnedFiles: (files) => {
        dispatch({
          type: ActionTypes.UPLOAD_PIN_FILES,
          payload: files,
        });
      },
      setPinnedFilenames: (filenames) => {
        dispatch({
          type: ActionTypes.SET_PINNED_FILENAMES,
          payload: filenames,
        });
      },
      setTasks: (tasks) => {
        dispatch({
          type: ActionTypes.SET_TASKS,
          payload: tasks,
        });
      },
      setIntegrationServices: (services) => {
        dispatch({
          type: ActionTypes.SET_INTEGRATION_SERVICES,
          payload: services,
        });
      },
      updateTaskData: (task) => {
        const index = tasks.findIndex((t) => t.id === task.id);
        const newTasks = [...tasks];
        newTasks[index].is_completed = !newTasks[index].is_completed;
        const incompleteTasks = newTasks.filter((t) => !t.is_completed);
        dispatch({
          type: ActionTypes.SET_TASKS,
          payload: newTasks,
        });
        dispatch({
          type: ActionTypes.SET_NUM_ATTENTION_REQUIRED,
          payload: incompleteTasks.length,
        });
        updateTask(task);
      },
    },
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
}

export function useAppContext(): AppStore {
  const context = useContext(AppContext);

  if (context === undefined) {
    console.warn("useAppContext has to be used within the AppContextProvider");
  }

  return context;
}
