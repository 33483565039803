import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import usePostLinkedin from "../../hooks/usePostLinkedin";

import "./Social.css";

interface LinkedinModalProps {
  text: string;
  isLinkedinModalOpen: boolean;
  setIsLinkedinModalOpen: (isOpen: boolean) => void;
}

const LinkedinModal = ({
  text,
  isLinkedinModalOpen,
  setIsLinkedinModalOpen,
}: LinkedinModalProps) => {
  const [editedText, setEditedText] = useState("");
  const { postToLinkedin } = usePostLinkedin();

  useEffect(() => {
    if (text !== null && text.length) {
      setEditedText(text);
    }
  }, [text]);

  useEffect(() => {
    if (isLinkedinModalOpen && localStorage.getItem("selectedMessageId")) {
      localStorage.removeItem("selectedMessageId");
    }
  }, [isLinkedinModalOpen]);

  const handleTextChange = (e) => {
    setEditedText(e.target.value);
  };

  const handlePost = () => {
    postToLinkedin({ content: editedText });
    setIsLinkedinModalOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={isLinkedinModalOpen}
        onClose={() => setIsLinkedinModalOpen(false)}
        closeOnClickOutside
        hasCloseButton
        width={400}
      >
        <div className="linkedin-content">
          <div className="linkedin-content-header">Post Preview</div>
          <textarea
            className="linkedin-textarea"
            onChange={handleTextChange}
            rows={5}
            value={editedText}
          />
          <div className="linkedin-post-button">
            <Button color="Secondary" size="Medium" onClick={handlePost}>
              Post
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LinkedinModal;
