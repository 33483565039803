import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Pendo from "../../components/Pendo/Pendo.tsx";
import { Carousel } from "../../components/Carousel/Carousel.tsx";
import { logout } from "../../utils/shared.ts";
import { useWalkthrough } from "../../features/Walkthrough/Walkthrough.tsx";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext.tsx";
import "./Sidebar.css";
import FinStatusIndicator from "../../components/FinStatusIndicator/FinStatusIndicator.tsx";
import HelpPopup from "../../components/Help/HelpPopup.tsx";
import { useLocation } from "react-router-dom";
import useFetchHelp from "../../hooks/useFetchHelp";

const getPage = () => {
  const location = useLocation();
  return String(location.pathname.split("/").filter(Boolean).pop());
};

const slidesHome = [
  <div>You can see your daily Activity here.</div>,
  <div>You can see your high level metrics and create charts here.</div>,
  <div>
    You can create Assignments you want Fin to run at a regular cadence.
  </div>,
];

const slidesFin = [
  <div>
    Update Fin's Calibration Settings here so Fin is personalized to you.
  </div>,
  <div>
    You can use the Upload feature to store information related to your
    portfolios or information you want give Fin when chatting with him.
  </div>,
];

const ACTIVITY_LINKS = [
  { label: "Activity", path: "/u/app/fin/activity", icon: "globe-outline" },
  { label: "Dashboard", path: "/u/app/home", icon: "bar-chart-outline" },
  {
    label: "Assignments",
    path: "/u/app/fin/assignments",
    icon: "clipboard-outline",
  },
];

const FIN_LINKS = [
  {
    label: "Settings",
    path: "/u/app/settings/fin-settings",
    icon: "cog-outline",
  },
  {
    label: "Upload/Files",
    path: "/u/app/fin/upload",
    icon: "arrow-up-circle-outline",
  },
];

const SETTING_LINKS = [
  {
    label: "Profile",
    path: "/u/app/settings/profile",
    icon: "person-circle-outline",
  },
  {
    label: "Fin Settings",
    path: "/u/app/settings/fin-settings",
    icon: "cog-outline",
  },
  {
    label: "Data Controls",
    path: "/u/app/settings/data-controls",
    icon: "server-outline",
  },
];

const CONFIG = {
  "/u": ACTIVITY_LINKS,
  "/u/app/fin/activity": ACTIVITY_LINKS,
  "/u/app/home": ACTIVITY_LINKS,
  "/u/app/fin/assignments": ACTIVITY_LINKS,
  "/u/app/settings/fin-settings": FIN_LINKS,
  "/u/app/fin/upload": FIN_LINKS,
  "/u/app/fin/files": FIN_LINKS,
  "/u/app/investors": [],
  "/u/app/chat": [],
  "/u/app/settings/profile": SETTING_LINKS,
  "/u/app/settings/data-controls": SETTING_LINKS,
};

const Sidebar = () => {
  const {
    state: { selectedRoute },
    actions: { setSelectedRoute },
  } = useAppContext();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isHelpPopupOpen, setIsHelpPopupOpen] = useState(false);
  const [helpContent, setHelpContent] = useState(null);

  const toggleSidebar = () => setIsExpanded(!isExpanded);
  const navigate = useNavigate();
  const {
    currentStep,
    refs,
    nextStep,
    prevStep,
    location,
    isActive,
    setIsActive,
    handleClose,
    registerRef,
    unregisterRef,
    activeSlide,
  } = useWalkthrough();
  const stepId = "fin";
  const ref = useRef(null);
  const showPendo =
    (currentStep === 4 || currentStep === 5) && refs[stepId] && isActive;

  const toggleHelpPopup = () => {
    setIsHelpPopupOpen(!isHelpPopupOpen);
  };

  const helpType = getPage();
  const { help } = useFetchHelp({ helpType });
  console.log(help.content);

  useEffect(() => {
    if (help.content) {
      setHelpContent({ content: help.content });
    } else {
      setHelpContent(null);
    }
  }, [help.content]);

  useEffect(() => {
    if (currentStep === 5) {
      setSelectedRoute("/u/app/settings/fin-settings");
    }
    if (currentStep === 4) {
      setSelectedRoute("/u/app/fin/activity");
    }
  }, [currentStep, setSelectedRoute]);

  useEffect(() => {
    if (isActive && location) {
      navigate({ pathname: location, replace: true });
    }
  }, [navigate, location, isActive]);

  useEffect(() => {
    registerRef(stepId, ref);
    return () => unregisterRef(stepId);
  }, [registerRef, unregisterRef, stepId]);

  return (
    <>
      <div
        className={`sidebar ${isExpanded ? "expanded" : "collapsed"} ${
          isActive ? "active" : ""
        }`}
      >
        <div className="sidebar-control-btn">
          <button onClick={toggleSidebar}>
            {isExpanded ? (
              <ion-icon name="chevron-back-outline"></ion-icon>
            ) : (
              <ion-icon name="chevron-forward-outline"></ion-icon>
            )}
          </button>
        </div>
        <div className="sidebar-items-wrapper">
          {CONFIG[selectedRoute] && (
            <div className={`sidebar-links ${isActive ? "active" : ""}`}>
              {selectedRoute === "fin" && (
                <FinStatusIndicator full={isExpanded} />
              )}
              <ul>
                {CONFIG[selectedRoute].map((route, index) => (
                  <li key={index}>
                    <Link
                      className={`sidebar-link ${
                        (currentStep === 4 || currentStep === 5) &&
                        activeSlide === index
                          ? "active"
                          : ""
                      }`}
                      to={route.path}
                    >
                      <ion-icon name={route.icon}></ion-icon>
                      {isExpanded && (
                        <span className="text">{route.label}</span>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="sidebar-static-items">
            <Link className="sidebar-link" to={""} onClick={logout}>
              <ion-icon name="log-out-outline"></ion-icon>
              {isExpanded && <span className="text">Logout</span>}
            </Link>
            {helpContent && (
              <button className="sidebar-link" onClick={toggleHelpPopup}>
                <ion-icon name="help-circle-outline"></ion-icon>
                {isExpanded && <span className="text">Help</span>}
              </button>
            )}
          </div>
        </div>
      </div>
      {helpContent && helpContent.content && (
        <HelpPopup
          isOpen={isHelpPopupOpen}
          onClose={toggleHelpPopup}
          helpContent={helpContent}
        />
      )}
    </>
  );
};

export default Sidebar;
