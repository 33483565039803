import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "../IconButton/IconButton";

import "./Copy.css";

interface CopyProps {
  text: string;
}

const Copy = ({ text }: CopyProps) => {
  const [copySuccess, setCopySuccess] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      },
      (err) => {
        setCopySuccess("Failed to copy!");
        console.error("Could not copy text: ", err);
      },
    );
  };

  return (
    <div className="copy">
      <div className="copy-inner">
        <div className="copy-success">
          {copySuccess && (
            <span style={{ marginLeft: "10px" }}>{copySuccess}</span>
          )}
        </div>
        <IconButton onClick={handleCopy}>
          <ion-icon name="clipboard-outline"></ion-icon>
        </IconButton>
      </div>
    </div>
  );
};

export default Copy;
