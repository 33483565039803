import { useEffect, useState } from "react";
import {
  updateProfileDataset,
  getProfileDataset as apiGetProfileDataset,
  getInvestors,
} from "../api/api";
import { sortByProperty } from "../utils/sort.ts";

const useInvestors = (pageSize = 20) => {
  const [investors, setInvestors] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchInvestors = async (pageNumber) => {
    setProcessing(true);
    try {
      const result = await getInvestors(pageNumber, pageSize);
      setProcessing(false);
      return result;
    } catch (error) {
      setProcessing(false);
      throw error;
    }
  };

  const refresh = async () => {
    const result = await fetchInvestors(1);
    const sortedInvestors = sortByProperty(result.results, "name");
    setInvestors(sortedInvestors);
    setPage(2);
    setHasMore(result.results.length === pageSize);
  };

  const loadMore = async () => {
    if (processing || !hasMore) return;
    const result = await fetchInvestors(page);
    if (result.next === null) {
      setHasMore(false);
      return;
    }
    setInvestors((prevInvestors) => [...prevInvestors, ...result.results]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(result.results.length === pageSize);
  };

  const saveProfileDataset = async (investorId, datasetId, data) => {
    setProcessing(true);
    try {
      const dataset = await updateProfileDataset(investorId, datasetId, data);
      setProcessing(false);
      return dataset;
    } catch (error) {
      setProcessing(false);
      throw error;
    }
  };

  const getProfileDataset = async (investorId, datasetId) => {
    try {
      const dataset = await apiGetProfileDataset(investorId, datasetId);
      return dataset;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return {
    saveProfileDataset,
    getProfileDataset,
    investors,
    processing,
    loadMore,
    hasMore,
  };
};

export default useInvestors;
