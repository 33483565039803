import React, { useEffect, useState } from "react";
import "./Alert.css";

interface AlertProps {
  type: string;
  text: string;
  time: number;
}

const Alert = ({ type, text, time = 3000 }: AlertProps) => {
  const [showAlert, setShowAlert] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, time);

    return () => clearTimeout(timer);
  }, [showAlert, time]);

  return (
    <div>
      {showAlert && <div className={`alert alert-${type}`}>{text}</div>}
    </div>
  );
};

export default Alert;
