import axios from "axios";
import React, { useEffect, useState } from "react";
import { ProfileSettings, UserInfo } from "../types/types";
import { updateProfile, getProfile } from "../api/api";

interface Props {
  onError?: (message: string) => void;
}

const useProfileSettings = (props?: Props) => {
  const [settings, setSettings] = useState<UserInfo & ProfileSettings>({
    first_name: "",
    middle_name: "",
    last_name: "",
    full_name: "",
    date_format: null,
    time_format: null,
    country: null,
    user_timezone: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const saveSettings = async (settings: ProfileSettings) => {
    setIsLoading(true);
    const response = await updateProfile(settings);
    getSettings();
    setIsLoading(false);
    return response;
  };

  const getSettings = () => {
    getProfile()
      .then((response) => {
        setSettings({
          ...response.user_info,
          ...response.profile_setting,
        });
      })
      .catch((e) => {
        if (props?.onError) props.onError(e);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  return { saveSettings, settings, isLoading };
};

export default useProfileSettings;
