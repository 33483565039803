import React, { useEffect, useState } from "react";
import { getNewsSources, updateNewsSources } from "../api/api";

const useFetchNewsSources = () => {
  const [newsSources, setNewsSources] = useState<any[]>([]);
  const [numTopSources, setNumTopSources] = useState<any[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNewsSources = async () => {
    setIsLoading(true);
    getNewsSources()
      .then((data) => {
        setNewsSources(data.sources);
        setNumTopSources(data.top_source_count);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  const updateUserNewsSources = async (newsSource) => {
    setIsLoading(true);
    const updatedSources = newsSources.map((source) =>
      source.id === newsSource.id
        ? { ...source, is_selected: !source.is_selected }
        : source,
    );
    setNewsSources(updatedSources);
    updateNewsSources(updatedSources).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchNewsSources();
  }, []);

  return {
    newsSources,
    numTopSources,
    isLoading,
    error,
    updateUserNewsSources,
  };
};

export default useFetchNewsSources;
