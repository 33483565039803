import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Filter.css";

const FilterForm = ({
  filters,
  setFilter,
  resetFilters,
  inputConfig,
  className,
}) => {
  const renderInput = (config) => {
    switch (config.type) {
      case "select":
        return (
          <select
            className="filter-form-select"
            value={filters[config.name] ?? ""}
            onChange={(e) => setFilter(config.name, e.target.value)}
          >
            <option value="">{config.label}</option>
            {config.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };

  return (
    <form className={className}>
      {inputConfig.map((config, index) => {
        return (
          <div key={index}>
            <label>{renderInput(config)}</label>
          </div>
        );
      })}
      <button className="filter-reset" type="button" onClick={resetFilters}>
        Reset Filters
      </button>
    </form>
  );
};

export default FilterForm;
