import React from "react";
import { COLORS } from "../Palette/Palette";
import "./Button.css";

interface ButtonProps {
  color: "Primary" | "Secondary" | "Text" | "White";
  size?: "Small" | "Medium" | "Large";
  text?: string;
  onClick?: (e?: React.FormEvent<HTMLFormElement>) => void;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
  children?: any;
}

const Button = ({
  color,
  size,
  text,
  onClick,
  disabled,
  type,
  children,
}: ButtonProps) => {
  const getButtonColor = () => {
    if (disabled) {
      return COLORS.gray50;
    }
    switch (color) {
      case "Primary":
        return COLORS.gray80;
      case "Secondary":
        return COLORS.blue80;
      case "Text":
        return COLORS.black100;
      case "White":
        return COLORS.white;
      default:
        return COLORS.gray50;
    }
  };

  const getSizeStyle = () => {
    switch (size) {
      case "Small":
        return {
          padding: "6px 12px",
          fontSize: "14px",
        };
      case "Medium":
        return {
          padding: "6px 20px",
          fontSize: "16px",
        };
      case "Large":
        return {
          padding: "12px 20px",
          fontSize: "18px",
        };
      default:
        return {};
    }
  };

  const buttonStyles = {
    backgroundColor: getButtonColor(),
    cursor: disabled ? "not-allowed" : "pointer",
    pointerEvents: disabled && "none",
    ...getSizeStyle(),
  };

  return (
    <div className={`button-wrapper ${disabled && "disabled"}`}>
      <button
        className={`button ${color} ${disabled && "disabled"} ${
          color === "Primary" || (color === "White" && "black-font")
        }`}
        onClick={onClick}
        type={type}
        style={buttonStyles}
        disabled={disabled}
      >
        <div className="button-content">
          {children && <div>{children}</div>}
          {text && <div>{text}</div>}
        </div>
      </button>
    </div>
  );
};

export default Button;
