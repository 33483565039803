import React from "react";
import "./TextInput.css";

interface TextInputProps {
  label?: string;
  value: string;
  placeholder?: string;
  multiline?: boolean;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  "data-testid"?: string;
  width?: string;
  type?: string;
  errors?: string[];
  align?: "row" | "row-reverse" | "column" | "column-reverse";
}

const TextInput = ({
  label,
  value,
  placeholder,
  multiline,
  required,
  onChange,
  onKeyDown,
  "data-testid": testId,
  width = "100%",
  type = "text",
  errors,
  align = "column",
}: TextInputProps) => {
  return (
    <div
      className={`text-input-container ${errors ? "error" : ""}`}
      style={{ flexDirection: align }}
    >
      {label && <label className="input-label">{label}</label>}
      {multiline ? (
        <textarea
          data-testid={testId}
          value={value}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          onKeyDown={onKeyDown}
          rows={5}
        />
      ) : (
        <input
          type={type}
          data-testid={testId}
          value={value}
          required={required}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          style={{ width }}
        />
      )}
      {errors && (
        <div className="error-message">
          {errors
            .map((error) =>
              error
                .replace("a float", "a number")
                .replace("an integer", "a number"),
            )
            .join(" ")}
        </div>
      )}
    </div>
  );
};

export default TextInput;
