import React from "react";
import CheckboxList from "../../components/Checkbox/CheckboxList";
import getString from "../../utils/getString";
import { useAssignmentContext } from "../../contexts/AssignmentContext";

import "./Assignment.css";

const FREQUENCY_OPTIONS = ["One Time", "Daily", "Monthly", "Quarterly"];

function Frequency() {
  const {
    state: { frequency },
    actions: { setFrequency },
  } = useAssignmentContext();

  const handleFrequencyToggle = (option) => {
    if (frequency.includes(option)) {
      setFrequency(frequency.filter((item) => item !== option));
    } else {
      setFrequency([...frequency, option]);
    }
  };

  return (
    <div className="section">
      <div>{getString("frequency")}</div>
      <CheckboxList
        options={FREQUENCY_OPTIONS}
        selectedOptions={frequency}
        onSelect={handleFrequencyToggle}
      />
    </div>
  );
}

export default Frequency;
