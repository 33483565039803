export const DATE_FORMATS = [
  { id: "MM/DD/YYYY", name: "MM/DD/YYYY (e.g., 12/31/2024)" },
  { id: "DD-MM-YYYY", name: "DD-MM-YYYY (e.g., 31-12-2024)" },
  { id: "YYYY-MM-DD", name: "YYYY-MM-DD (e.g., 2024-12-31)" },
  { id: "DD/MM/YYYY", name: "DD/MM/YYYY (e.g., 31/12/2024)" },
  { id: "MMMM Do, YYYY", name: "MMMM Do, YYYY (e.g., December 31st, 2024)" },
  { id: "YYYY/MM/DD", name: "YYYY/MM/DD (e.g., 2024/12/31)" },
  { id: "Do MMM YYYY", name: "Do MMM YYYY (e.g., 31st Dec 2024)" },
  { id: "DD-MMM-YYYY", name: "DD-MMM-YYYY (e.g., 31-Dec-2024)" },
  { id: "MM-DD-YYYY HH:mm", name: "MM-DD-YYYY HH:mm (e.g., 12-31-2024 23:59)" },
  {
    id: "YYYY-MM-DD HH:mm:ss",
    name: "YYYY-MM-DD HH:mm:ss (e.g., 2024-12-31 23:59:59)",
  },
  {
    id: "DD/MM/YYYY HH:mm:ss A",
    name: "DD/MM/YYYY HH:mm:ss A (e.g., 31/12/2024 11:59:59 PM)",
  },
  {
    id: "MMMM Do, YYYY h:mm A",
    name: "MMMM Do, YYYY h:mm A (e.g., December 31st, 2024 11:59 PM)",
  },
  {
    id: "ddd, DD MMM YYYY HH:mm:ss [GMT]",
    name: "ddd, DD MMM YYYY HH:mm:ss [GMT] (e.g., Tue, 31 Dec 2024 23:59:59 GMT)",
  },
];

export const TIME_FORMATS = [
  { id: "HH:mm", name: "24h (HH:mm)" },
  { id: "HH:mm:ss", name: "24h with seconds (HH:mm:ss)" },
  { id: "hh:mm A", name: "12h (hh:mm AM/PM)" },
  { id: "hh:mm:ss A", name: "12h with seconds (hh:mm:ss AM/PM)" },
  { id: "HH:mm Z", name: "24h with timezone (HH:mm Z)" },
  { id: "HH:mm:ss Z", name: "24h with seconds and timezone (HH:mm:ss Z)" },
  { id: "hh:mm A Z", name: "12h with timezone (hh:mm AM/PM Z)" },
  {
    id: "hh:mm:ss A Z",
    name: "12h with seconds and timezone (hh:mm:ss AM/PM Z)",
  },
  { id: "HH:mm [UTC]Z", name: "24h UTC offset (HH:mm [UTC]Z)" },
  {
    id: "HH:mm:ss [UTC]Z",
    name: "24h with seconds UTC offset (HH:mm:ss [UTC]Z)",
  },
  { id: "hh:mm A [UTC]Z", name: "12h UTC offset (hh:mm AM/PM [UTC]Z)" },
  {
    id: "hh:mm:ss A [UTC]Z",
    name: "12h with seconds UTC offset (hh:mm:ss AM/PM [UTC]Z)",
  },
];
