import React from "react";
import Filter from "../../components/Filter/Filter";
import useFetchNewsSources from "../../hooks/useFetchNewsSources";

const NewsSourcesFilter = () => {
  const { newsSources, numTopSources, updateUserNewsSources } =
    useFetchNewsSources();

  const onSelect = (source) => {
    updateUserNewsSources(source);
  };

  return (
    <div>
      <Filter
        numTopOptions={numTopSources}
        options={newsSources}
        onSelect={onSelect}
        placeholder="Select sources..."
      />
    </div>
  );
};

export default NewsSourcesFilter;
