import React from "react";

interface SelectProps {
  label: string;
  options: { value?: string; text: string }[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  value: string;
  "data-testid": string;
}

const Select = ({
  label,
  options,
  onChange,
  value,
  "data-testid": testId,
}: SelectProps) => {
  return (
    <div className="input-container">
      <label>{label}</label>
      <select value={value} data-testid={testId} onChange={onChange}>
        <option hidden>-</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
