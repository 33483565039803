import React, { ReactNode } from "react";
import Modal from "../../components/Modal/Modal";

interface TermsProps {
  children: ReactNode;
  isTermsModalOpen: boolean;
  closeTermsModal: () => void;
  closeOnClickOutside: boolean;
}

const Terms: React.FC<TermsProps> = ({
  closeTermsModal,
  isTermsModalOpen,
  children,
  closeOnClickOutside,
}) => {
  return (
    <div className="terms-container">
      <Modal
        isOpen={isTermsModalOpen}
        onClose={closeTermsModal}
        closeOnClickOutside={closeOnClickOutside}
        width={400}
      >
        {children}
      </Modal>
    </div>
  );
};

export default Terms;
