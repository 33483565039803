import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "./../contexts/AppContext";
import { getUsage } from "../api/api";

interface Props {
  onError?: (message: String) => void;
}

const useCharts = (props?: Props) => {
  const [charts, setCharts] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    actions: { setUsage },
  } = useAppContext();

  const createChart = (query: String) => {
    setProcessing(true);
    axios
      .post("/api/fin/charts", { query })
      .then((response) => {
        refresh();
        getUsage().then((response) => setUsage(response));
      })
      .catch((e) => {
        if (props?.onError) props?.onError(e);
        setProcessing(false);
      });
  };

  const deleteChart = (chartId: String) => {
    axios
      .delete(`/api/fin/chart/${chartId}`)
      .then(() => refresh())
      .catch((e) => {
        if (props?.onError) props.onError(e);
      });
  };

  const refresh = () => {
    axios
      .get("/api/fin/charts")
      .then((response) => {
        setCharts(response.data);
        setProcessing(false);
        setLoading(false);
      })
      .catch((e) => {
        if (props?.onError) props.onError(e);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  return {
    charts,
    processing,
    createChart,
    deleteChart,
    loading,
  };
};

export default useCharts;
