import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  createBrowserRouter,
  RouteObject,
} from "react-router-dom";

Sentry.init({
  dsn: "https://3418602cde83addec602354512ff13a6@o4507426200616960.ingest.us.sentry.io/4507430477692928",
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^http:\/\/localhost:3000\//,
    /^http:\/\/localhost:8000\//,
    /^https:\/\/app\.quantumai\.dev\//,
    /^https:\/\/quail-dev-testing-4gctfqqbja-uc.a.run.app\//,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const sentryCreateBrowserRouter: (
  routes: RouteObject[],
) => ReturnType<typeof createBrowserRouter> =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
