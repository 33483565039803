import React from "react";
import "./Checkbox.css";

interface CheckboxProps {
  option: any;
  onSelect: (option: any) => void;
}

function Checkbox({ option, onSelect }: CheckboxProps) {
  return (
    <div className="checkbox-wrapper">
      <input
        type="checkbox"
        value={option}
        checked={option.is_selected}
        onChange={() => onSelect(option)}
      ></input>
      {option.name}
    </div>
  );
}

export default Checkbox;
