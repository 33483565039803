import React from "react";
import "./Box.css";

const Box = ({ color, text, image, top, left, right, width, fadeBottom }) => {
  return (
    <div
      className={`box ${fadeBottom ? 'fade-bottom' : ''}`}
      style={{
        backgroundColor: color,
        top: top,
        left: left,
        right: right,
        width: width,
      }}
    >
      {image ? (
        <img src={image} alt="Box content" className="box-image" />
      ) : (
        <div className="box-text">{text}</div>
      )}
    </div>
  );
};

export default Box;
