import React, { useState, useEffect } from "react";
import IconButton from "../IconButton/IconButton";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import { useAppContext } from "../../contexts/AppContext";
import getString from "../../utils/getString";
import { updatePlanMessage } from "../../utils/getMessage.tsx";

import "./chatwindow.css";

interface ChatValidationButtonProps {
  isValid: boolean;
  setValidationOpen: (isOpen: boolean) => void;
}

const ChatValidationButton = ({
  isValid,
  setValidationOpen,
}: ChatValidationButtonProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    state: { user },
  } = useAppContext();

  useEffect(() => {
    const dataProvenanceAccess = user?.subscription?.has_data_provenance_access;
    setIsDisabled(!dataProvenanceAccess);
  }, [user?.subscription?.has_data_provenance_access]);

  return (
    <div className="chat-validation">
      <Tooltip
        position="top"
        isHidden={!isDisabled}
        text={updatePlanMessage(
          getString("dataProvenanceAccess"),
          user?.subscription?.plan_type,
        )}
        zIndex={1}
      >
        <IconButton
          disabled={!isValid || isDisabled}
          onClick={() => setValidationOpen(true)}
        >
          <ion-icon name="information-circle-outline"></ion-icon>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ChatValidationButton;
