import { useState, useEffect } from "react";

const useHashQueryParams = () => {
  const [queryParams, setQueryParams] = useState(new URLSearchParams());

  useEffect(() => {
    const hash = window.location.hash;
    if (hash.includes("?")) {
      const queryString = hash.split("?")[1]; // Get the part after ?
      setQueryParams(new URLSearchParams(queryString));
    }
  }, []);

  return queryParams;
};

export default useHashQueryParams;
