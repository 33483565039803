import React from "react";
import CheckboxList from "../../components/Checkbox/CheckboxList";
import { useAssignmentContext } from "../../contexts/AssignmentContext";
import "./Assignment.css";

const DELIVERY_TYPES = ["Email", "Download"];

function DeliveryType() {
  const {
    state: { deliveryType },
    actions: { setDeliveryType },
  } = useAssignmentContext();

  const handleDeliveryToggle = (option: string) => {
    if (deliveryType.includes(option)) {
      setDeliveryType(deliveryType.filter((item) => item !== option));
    } else {
      setDeliveryType([...deliveryType, option]);
    }
  };

  return (
    <div className="section">
      <CheckboxList
        options={DELIVERY_TYPES}
        selectedOptions={deliveryType}
        onSelect={handleDeliveryToggle}
      />
    </div>
  );
}

export default DeliveryType;
