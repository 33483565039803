import React from "react";
import CheckboxList from "../../components/Checkbox/CheckboxList";
import { useAssignmentContext } from "../../contexts/AssignmentContext";
import getString from "../../utils/getString";
import "./Assignment.css";

const DOCUMENT_TYPES = ["Word", "PDF", "CSV", "PowerPoint"];

function DocumentType() {
  const {
    state: { documentType },
    actions: { setDocumentType },
  } = useAssignmentContext();

  const handleSetDocumentType = (option) => {
    if (documentType.includes(option)) {
      setDocumentType(documentType.filter((item) => item !== option));
    } else {
      setDocumentType([...documentType, option]);
    }
  };

  return (
    <div className="section">
      <div>{getString("documentType")}</div>
      <CheckboxList
        options={DOCUMENT_TYPES}
        selectedOptions={documentType}
        onSelect={handleSetDocumentType}
      />
    </div>
  );
}

export default DocumentType;
