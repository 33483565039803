import { Carousel } from "../../components/Carousel/Carousel";
import Pendo from "../../components/Pendo/Pendo";
import { useWalkthrough } from "../Walkthrough/Walkthrough";
import React, { useEffect, useRef } from "react";
import welcome1 from "../../assets/welcome1.png";
import welcome2 from "../../assets/welcome2.png";
import welcome3 from "../../assets/welcome3.png";
import welcome4 from "../../assets/welcome4.png";
import welcome5 from "../../assets/welcome5.png";

import "./Welcome.css";

const Welcome = () => {
  const {
    registerRef,
    unregisterRef,
    currentStep,
    refs,
    nextStep,
    prevStep,
    isActive,
    setIsActive,
    handleClose,
  } = useWalkthrough();
  const stepIdWelcome = "welcome";
  const welcomeRef = useRef(null);
  const hideWalkthrough = true; //localStorage.getItem("hideWalkthrough") === "true";

  useEffect(() => {
    registerRef(stepIdWelcome, welcomeRef);
    return () => unregisterRef(stepIdWelcome);
  }, [registerRef, unregisterRef, stepIdWelcome]);
  const showPendoWelcome =
    currentStep === 0 && refs["welcome"] && isActive && !hideWalkthrough;

  return (
    <div ref={welcomeRef}>
      <div className="welcome-pendo-container">
        {showPendoWelcome && (
          <Pendo
            content={<Carousel slides={slides} width={400} height={300} />}
            targetRef={welcomeRef}
            next={nextStep}
            prev={prevStep}
            currentStep={currentStep}
            setIsActive={setIsActive}
            width={400}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  );
};

export default Welcome;

const slides = [
  <div className="welcome-slide">
    <div className="header">Welcome to Q(Fin)!</div>
    <div className="content">
      I would like to personally welcome you on behalf of our entire team.
    </div>
    <img src={welcome1} alt="Confetti" className="welcome-image" />
  </div>,
  <div className="welcome-slide">
    <div className="header">We do not take what just happened lightly!</div>
    <div className="content">
      You have placed your trust in our product, our collective effort, and our
      unwavering commitment to you, our customers.
    </div>
    <img src={welcome2} alt="Handshake" className="welcome-image" />
  </div>,
  <div className="welcome-slide">
    <div className="header">
      The concept is simple: Get more done, more efficiently, with better
      results.
    </div>
    <div className="content">
      You will soon discover the power of Fin, and how he can help elevate your
      business to new levels.
    </div>
    <img
      src={welcome3}
      alt="Person Working At Desk"
      className="welcome-image"
    />
  </div>,
  <div className="welcome-slide">
    <div className="header">
      Keep in mind that while Fin is very impressive, he is not perfect.
    </div>
    <div className="content">
      You should always double check the work. Only with your help can Fin reach
      his full potential.
    </div>
    <img
      src={welcome4}
      alt="Person Giving Presentation"
      className="welcome-image"
    />
  </div>,
  <div className="welcome-slide">
    <div className="header">
      Welcome to the movement that is redefining ROI: Return on Intelligence.
    </div>
    <div className="content">Andrew Fischer, Founder & CEO</div>
    <img src={welcome5} alt="Spaceship" className="welcome-image" />
  </div>,
];
