import React from "react";
import ChatBubble from "./ChatBubble.tsx";
import { Message } from "../../types/types.ts";
import "./chatwindow.css";

interface ChatBubbleProps {
  messages: Message[];
  pinnedFilenames: string[];
  messagesEndRef: React.RefObject<HTMLInputElement>;
}

const ChatBubbles = ({
  messages,
  pinnedFilenames,
  messagesEndRef,
}: ChatBubbleProps) => {
  const messagesLength = messages.length;
  return (
    <div
      className={`messages-container ${
        pinnedFilenames.length > 0 ? "collapse" : ""
      }`}
    >
      {messages.map((message, index) => (
        <ChatBubble
          key={index}
          index={index}
          message={message}
          messagesLength={messagesLength}
        />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatBubbles;
