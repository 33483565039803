import React from "react";
import "./Pricing.css";
import StripePricingTable from "../../features/StripePricingTable/StripePricingTable";

const PersonalPricing: React.FC = () => {
  return (
    <div className="fin-onboarding-container">
      <div className="fin-onboarding-wrapper">
        <StripePricingTable type="personal" />
      </div>
    </div>
  );
};

export default PersonalPricing;
