import React, { useState } from "react";
import { Resizable } from "re-resizable";
import Chatwindow from "../../components/Chatbox/chatwindow.tsx";
import { useLocation } from 'react-router-dom';

import "./ChatSidebar.css";

const CHANGE_POS_WIDTH = 600;

const CustomHandle = () => <div className="chat-resize-handle"></div>;

const ChatSidebar = () => {
  const [width, setWidth] = useState(350);
  const [position, setPosition] = useState("relative");
  const [maxWidth, setMaxWidth] = useState(() => window.innerWidth - 220);

  const handleResize = (e, direction, ref, d) => {
    const currentWidth = width + d.width;
    setMaxWidth(window.innerWidth - 220);
    if (currentWidth > CHANGE_POS_WIDTH) {
      setPosition("absolute");
    } else {
      setPosition("relative");
    }
  };

  const getPage = () => {
      const location = useLocation();
      return String(location.pathname.split('/').filter(Boolean).pop());
  };

  let minwidth: string = getPage() === "chat" ? 1641 : 380;

  return (
    <div className="chat-sidebar">
      <Resizable
        className={`chat-sidebar-resizeable ${position}`}
        minWidth={minwidth}
        maxWidth={`${maxWidth}px`}
        size={{ width: `${width}px`, height: "100%" }}
        handleComponent={{ left: <CustomHandle /> }}
        handleClasses={{
          top: "hidden",
          bottom: "hidden",
          right: "hidden",
          topRight: "hidden",
          bottomRight: "hidden",
          bottomLeft: "hidden",
          topLeft: "hidden",
        }}
        onResize={handleResize}
        onResizeStop={(e, direction, ref, d) => {
          const newWidth = width + d.width;
          setWidth(newWidth);
        }}
      >
        <div className="chat-window-container">
          <Chatwindow />
        </div>
      </Resizable>
    </div>
  );
};

export default ChatSidebar;
