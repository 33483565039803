// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import usePortfolios from "../../hooks/usePortfolios";
import Alert from "../../components/Alert/Alert";
import getString from "../../utils/getString";
import DataTable from "../../components/DataTable/DataTable";
import isNumeric from "../../utils/isNumeric";
import usePortfolioUtils from "../../hooks/usePortfolioUtils";
import TextInput from "../../components/Fields/TextInput";
import Select from "../../components/Fields/Select";

function Optimize() {
  const [selectedPortfolio, setSelectedPortfolio] = useState("all");
  const [optimizeFor, setOptimizeFor] = useState("-");
  const [lowerBound, setLowerBound] = useState<string | number>("-");
  const [upperBound, setUpperBound] = useState<string | number>("-");
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const {
    state: { user },
  } = useAppContext();

  const { portfolios, patchPortfolios, processing } = usePortfolios({
    onError: (err) => {
      setAlertProps({ text: err, type: "error" });
    },
  });
  const { getOptimizationPreferences } = usePortfolioUtils(portfolios);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (optimizeFor === "-") {
      setAlertProps({ text: getString("invalidMetric"), type: "error" });
      return;
    }
    if (
      !isNumeric(lowerBound) ||
      Number(lowerBound) < -5 ||
      Number(lowerBound) > 5
    ) {
      setAlertProps({ text: getString("invalidLowerBound"), type: "error" });
      return;
    }
    if (
      !isNumeric(upperBound) ||
      Number(upperBound) < -5 ||
      Number(upperBound) > 5
    ) {
      setAlertProps({ text: getString("invalidUpperBound"), type: "error" });
      return;
    }
    const toUpdate =
      selectedPortfolio === "all"
        ? portfolios
        : portfolios.filter((portfolio) => {
            return portfolio.id === parseInt(selectedPortfolio);
          });
    setAlertProps({ text: getString("savingPreferences"), type: "info" });
    patchPortfolios(
      toUpdate.map((portfolio) => ({
        id: portfolio.id,
        optimization_preferences: {
          metric: optimizeFor,
          upper_bound: upperBound,
          lower_bound: lowerBound,
        },
      })),
    )
      .then(() => {
        setAlertProps({ text: getString("preferencesSaved"), type: "success" });
      })
      .catch((err) => {
        setAlertProps({
          text: getString("errorSavingPreferences"),
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (portfolios.length > 0) {
      const properties = getOptimizationPreferences("all");
      if (properties.metric !== undefined) {
        setOptimizeFor(properties.metric);
      }
      if (properties.lower_bound !== undefined) {
        setLowerBound(properties.lower_bound);
      }
      if (properties.upper_bound !== undefined) {
        setUpperBound(properties.upper_bound);
      }
    }
  }, [portfolios]);

  return (
    <div className="optimize-container">
      {!user?.subscription?.has_optimize_access && (
        <div className="feature-disabled"></div>
      )}
      {alertProps.text && <Alert {...alertProps} />}
      <div style={{ paddingBottom: "40px" }}>
        <h1>{getString("optimizationPreferences")}:</h1>
        <form className="optimize-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <Select
              label={getString("clientPortfolio")}
              value={selectedPortfolio}
              data-testid="clientPortfolio"
              onChange={(e) => {
                setSelectedPortfolio(e.target.value);
                const properties = getOptimizationPreferences(e.target.value);
                setOptimizeFor(properties.metric);
                setLowerBound(properties.lower_bound);
                setUpperBound(properties.upper_bound);
              }}
              options={[
                { value: "all", text: "All" },
                ...portfolios.map((portfolio) => ({
                  value: portfolio.id,
                  text: portfolio.name,
                })),
              ]}
            />
            <Select
              label={`${getString("optimizeFor")}:`}
              value={optimizeFor}
              data-testid="optimizeFor"
              onChange={(e) => setOptimizeFor(e.target.value)}
              options={[
                { text: getString("volatility") },
                { text: getString("sharpRatio") },
                { text: getString("quadraticUtility") },
                { text: getString("riskTarget") },
                { text: getString("returnTarget") },
              ]}
            />
          </div>
          <h2>{getString("customBounds")}:</h2>
          <div className="form-row">
            <TextInput
              label={getString("lowerBound")}
              data-testid="lowerBound"
              value={lowerBound}
              onChange={(e) => setLowerBound(e.target.value)}
            />
            <TextInput
              label={getString("upperBound")}
              data-testid="upperBound"
              value={upperBound}
              onChange={(e) => setUpperBound(e.target.value)}
            />
          </div>
          <button
            type="submit"
            style={{ marginTop: 10, backgroundColor: "var(--blue80)" }}
            className="button primary"
            disabled={processing}
          >
            {processing ? getString("processing") : getString("submit")}
          </button>
        </form>
      </div>
      <DataTable
        columnLabels={[
          getString("clientPortfolio"),
          getString("optimizationMetric"),
          getString("lowerBound"),
          getString("upperBound"),
        ]}
        data={portfolios.map((portfolio) => [
          portfolio.name,
          portfolio.optimization_preferences.metric,
          portfolio.optimization_preferences.lower_bound,
          portfolio.optimization_preferences.upper_bound,
        ])}
      />
    </div>
  );
}

export default Optimize;
