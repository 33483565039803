import React, { useEffect, useState } from "react";
import AssignmentName from "./AssignmentName";
import CreateButton from "./CreateButton";
import DeliveryType from "./DeliveryType";
import Description from "./Description";
import DocumentType from "./DocumentType";
import Dropdown from "../../components/Dropdown/Dropdown";
import Frequency from "./Frequency";
import { useAssignmentContext } from "../../contexts/AssignmentContext";
import useFetchPortfolios from "../../hooks/useFetchPortfolios";
import { Assignment } from "../../types/types";
import getString from "../../utils/getString";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import "./Assignment.css";

interface AssignmentFormProps {
  selectedAssignment: Assignment;
  onAddAssignments: (assignments: Assignment[]) => void;
  patchAssignment: (assignment: Assignment, payload: any) => void;
  numAssignments: number;
}

function AssignmentForm({
  onAddAssignments,
  selectedAssignment,
  patchAssignment,
  numAssignments,
}: AssignmentFormProps) {
  const [selectedValue, setSelectedValue] = useState("");

  const {
    state: { startDate },
    actions: {
      setSelectedPortfolios,
      setStartDate,
      setFrequency,
      setDeliveryType,
      setDocumentType,
      setAssignmentName,
      setAssignmentDescription,
    },
  } = useAssignmentContext();
  const [nextRunDate, setNextRunDate] = useState(startDate);
  const { isLoading, error, portfolios } = useFetchPortfolios();

  useEffect(() => {
    if (selectedAssignment) {
      const portfolio = portfolios.filter(
        (portfolio) => portfolio.id === selectedAssignment.portfolio_id,
      );
      setSelectedPortfolios(portfolio);
      setSelectedValue(portfolio[0]);
      setNextRunDate(parseISO(selectedAssignment.next_run_date));
      setFrequency(selectedAssignment.frequency);
      setDeliveryType(selectedAssignment.delivery_type);
      setDocumentType(selectedAssignment.document_type);
      setAssignmentName(selectedAssignment.name);
      setAssignmentDescription(selectedAssignment.description);
    }
  }, [selectedAssignment]);

  const handlePortfolioSelect = (portfolio) => {
    if (portfolio.name === "All") {
      setSelectedPortfolios(portfolios);
    } else {
      setSelectedPortfolios([portfolio]);
    }
  };

  if (isLoading) {
    return <div>{getString("loading")}...</div>;
  }

  if (error) {
    return <div>{getString("errorOccured")}</div>;
  }

  return (
    <div className="new-assignment-form-wrapper">
      <h3 className="section-header">
        {selectedAssignment ? "Update Assignment" : "New Assignment"}
      </h3>
      <div className="section responsive">
        <div className="new-assignment-form-left-container">
          <h4 className="portfolio-header">{getString("portfolio")}:</h4>
          <Dropdown
            displayOption="name"
            options={[...portfolios, { name: "All" }]}
            onSelect={handlePortfolioSelect}
            selectedValue={selectedValue}
            placeholder="Select a portfolio..."
          />
          <AssignmentName />
        </div>
        <Description />
      </div>
      <div className="padded">
        <Frequency />
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <div>{selectedAssignment ? "Next Run Date" : "Start date"}</div>
        <DatePicker
          selected={selectedAssignment ? nextRunDate : startDate}
          onChange={(date) =>
            selectedAssignment ? setNextRunDate(date) : setStartDate(date)
          }
        />
      </div>
      <div className="padded">
        <DocumentType />
      </div>
      <div className="padded">
        <DeliveryType />
      </div>
      <div className="padded assignment-button">
        <CreateButton
          onAddAssignments={onAddAssignments}
          selectedAssignment={selectedAssignment}
          patchAssignment={patchAssignment}
          setSelectedValue={setSelectedValue}
          nextRunDate={nextRunDate}
          numAssignments={numAssignments}
        />
      </div>
    </div>
  );
}

export default AssignmentForm;
