import React from "react";
import { COLORS } from "../Palette/Palette";
import { Task } from "../../types/types";
import './Checklist.css';

interface ChecklistItemProps {
  label: string;
  checked: boolean;
  onToggle: () => void;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({
  label,
  checked,
  onToggle,
}) => {
  const iconStyle = {
    marginRight: "10px",
    flexShrink: "0",
    color: checked ? `${COLORS.blue80}` : `${COLORS.black100}`, // Change color to blue if checked
  };

  return (
    <div
      onClick={onToggle}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <ion-icon
        size="small"
        name={checked ? "checkmark-circle" : "ellipse-outline"}
        style={iconStyle}
      ></ion-icon>
      {label}
    </div>
  );
};

interface ChecklistProps {
  tasks: Task[];
  setTasks: (tasks: Task[]) => void;
  updateTask: (task: Task) => void;
}

const Checklist: React.FC<ChecklistProps> = ({ tasks, updateTask }) => {
  return (
    <div className="checklist-container">
      {tasks.length > 0 ? (
        <>
          {tasks.map((task, index) => (
            <div key={index} className="checkbox-container">
              <input
                type="checkbox"
                id={`task-${index}`}
                className="checkbox-input"
                checked={task.is_completed}
                onChange={() => updateTask(task)}
              />
              <label htmlFor={`task-${index}`} className="checkbox-label">
                {task.title}
              </label>
            </div>
          ))}
        </>
      ) : (
        <p>You have no tasks requiring your attention.</p>
      )}
    </div>
  );
};

export default Checklist;
