import React, { useEffect, useState } from "react";
import { getAssignments } from "../api/api";
import { Assignment } from "../types/types";

const useFetchAssignments = () => {
  const [assignments, setAssignments] = useState([]);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAssignments = async () => {
    getAssignments()
      .then((data) => {
        setAssignments(data);
        setFilteredAssignments(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAssignments();
  }, []);

  const addAssignments = (newAssignments: Assignment[]) => {
    if (newAssignments) {
      setAssignments((prev) => [...prev, ...newAssignments] as any);
    }
  };

  return {
    filteredAssignments,
    isLoading,
    error,
    addAssignments,
    fetchAssignments,
    assignments,
  };
};

export default useFetchAssignments;
