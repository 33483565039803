import { useEffect, RefObject } from "react";

const useInfiniteScroll = (
  loadMore: () => void,
  hasMore: boolean,
  containerRef: RefObject<HTMLDivElement> | null,
) => {
  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef?.current) return;

      const container = containerRef.current;
      const scrollTop = container.scrollTop + container.clientHeight;
      const offsetHeight = container.scrollHeight;

      const threshold = 200;

      if (scrollTop >= offsetHeight - threshold) {
        if (hasMore) {
          loadMore();
        }
      }
    };

    const container = containerRef?.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMore, hasMore, containerRef]);
};

export default useInfiniteScroll;
