import React from "react";
import Box from "../../components/Box/Box.tsx";
import authGraph from "../../assets/auth_graph.png";
import pdfImg from "../../assets/pdf_img.png";
import authHoldings from "../../assets/auth_holdings.png";

export const AUTH_BOXES = {
  policyStance: `"Can you tell me about the Federal Reserve's current policy stance?"`,
  policyStanceAnswer: (
    <div>
      {"The Federal Reserve's current policy stance, as outlined in the "}{" "}
      <span style={{ color: "blue" }}>{"latest meeting minutes,"}</span>
      {
        " reveals key points about their approach to managing economic conditions:"
      }{" "}
      <br />
      <br /> <b>{"Interest Rates:"}</b>{" "}
      {
        "The Federal Reserve decided to maintain the target range for the federal funds rate at 5.25% to 5.5%. They emphasized the importance of assessing incoming data to inform any future adjustments to the target..."
      }
    </div>
  ),
  perfAttribbution: `"Show me Isabelle Wilkersons performance attribution by sector over the last 30 days."`,
  summarizePdf: `"Summarize the attached PDF and highlight specific concerns that could impact our client's portfolios.`,
  summarizePdfAnswer: (
    <div>
      <b>{"Key Points Impacting Client Portfolios"}</b> <br /> <br />{" "}
      <b>
        <i>{"Stock Market Volatility:"}</i>
      </b>{" "}
      {"The historical volatility of China's stock market may pose risks"}{" "}
      <br /> <br />{" "}
      <b>
        <i>{"Regulatory Challenges:"}</i>
      </b>{" "}
      {"Ongoing regulatory issues in China could impact Chinese investments."}{" "}
      <br /> <br />{" "}
      <b>
        <i>{"Economic Slowdown:"}</i>
      </b>{" "}
      {
        " The slowing of China’s economic growth may affect investments tied to Chinese companies as well as companies with..."
      }
    </div>
  ),
  generateReport: `"Please generate a monthly performance report for each of my clients."`,
  generateReportAnswer: (
    <div>
      {
        "A monthly performance report has been generated for each of your clients and stored in your "
      }{" "}
      <span style={{ color: "blue" }}>{"Q(Fin) files."}</span>{" "}
      {" Would you like to set this as a recurring assignment?"}
    </div>
  ),
  marketQuestion: `"What is going on in the markets today?"`,
  marketAnswer: (
    <div>
      <b>{"Potential Federal Reserve Interest Rate Changes:"}</b>{" "}
      {
        " Analysts and financial advisors are emphasizing the long-term benefits of dividend growth stocks, especially in the context of potential Federal Reserve interest rate changes."
      }{" "}
      <br /> <br /> <b>{"Energy Transition Driving Commodity Prices:"}</b>{" "}
      {
        " There is significant discussion around copper prices, with Wall Street banks raising the odds of a future..."
      }
    </div>
  ),
  restrictNews: `"Restrict news sources to the top 10 most popular"`,
  restrictNewsAnswer: `News sources have been updated and applied. Please let me know if there is anything else I can help with.`,
  optimizePortfolio: `"How can I optimize my portfolio?"`,
  portfolioDown: `"Why is my portfolio down?"`,
  portfolioDownAnswer: (
    <div>
      <b>{"XLK (Technology Select Sector SPDR Fund):"}</b>
      <br />
      <br />{" "}
      {
        " Technology stocks have rallied, benefiting from wave of private and public investment in Artificial intelligence, cloud computing, and powerful enterprise software solutions."
      }
    </div>
  ),
};

export const AUTH_SLIDES = [
  <div>
    <Box
      color="#FFFFFF"
      text={AUTH_BOXES["policyStanceAnswer"]}
      top="14%"
      width="350px"
      fadeBottom={true}
    />
    <Box
      color="#dedede"
      text={AUTH_BOXES["policyStance"]}
      top="6.5%"
      left="6%"
      width="260px"
    />
    <Box color="#FFFFFF" image={authGraph} top="60%" left="12%" width="250px" />
    <Box
      color="#dedede"
      top="52%"
      left="9%"
      text={AUTH_BOXES["perfAttribbution"]}
      width="240px"
    />
  </div>,
  <div>
    <Box
      color="#dedede"
      text={AUTH_BOXES["summarizePdfAnswer"]}
      top="21%"
      left="35%"
      width="360px"
      fadeBottom={true}
    />

    <Box
      color="#FFFFFF"
      text={AUTH_BOXES["summarizePdf"]}
      top="11%"
      left="30%"
      width="260px"
    />
    <img src={pdfImg} alt="PDF image" className="pdf-img" />
    <Box
      color="#dedede"
      text={AUTH_BOXES["generateReportAnswer"]}
      top="75%"
      left="30%"
      width="300px"
    />
    <Box
      color="#FFFFFF"
      top="65%"
      left="33%"
      text={AUTH_BOXES["generateReport"]}
      width="230px"
    />
  </div>,
  <div>
    <Box
      color="#dedede"
      text={AUTH_BOXES["marketAnswer"]}
      top="14%"
      width="250px"
      fadeBottom={true}
    />
    <Box
      color="#FFFFFF"
      text={AUTH_BOXES["marketQuestion"]}
      top="6.5%"
      left="57%"
      width="170px"
    />
    <Box
      color="#dedede"
      text={AUTH_BOXES["restrictNewsAnswer"]}
      top="68%"
      left="60%"
      width="250px"
    />
    <Box
      color="#FFFFFF"
      top="60%"
      left="63%"
      text={AUTH_BOXES["restrictNews"]}
      width="250px"
    />
  </div>,
  <div>
    <Box
      color="#FFFFFF"
      image={authHoldings}
      top="14%"
      left="85%"
      width="250px"
    />
    <Box
      color="#dedede"
      text={AUTH_BOXES["optimizePortfolio"]}
      top="7%"
      left="80%"
      width="180px"
    />
    <Box
      color="#FFFFFF"
      text={AUTH_BOXES["portfolioDownAnswer"]}
      top="58%"
      left="84%"
      width="350px"
    />
    <Box
      color="#dedede"
      top="74%"
      left="80%"
      text={AUTH_BOXES["portfolioDown"]}
      width="220px"
    />
  </div>,
];