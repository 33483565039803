export const STRIPE_CUSTOMER_PORTAL_LINKS = {
  development: {
    personal: "https://billing.stripe.com/p/login/test_bIY5mVcsg75hb9CdQQ",
    business: "https://billing.stripe.com/p/login/test_bIY5mVcsg75hb9CdQQ",
  },
  staging: {
    personal: "https://billing.stripe.com/p/login/test_bIY5mVcsg75hb9CdQQ",
    business: "https://billing.stripe.com/p/login/test_bIY5mVcsg75hb9CdQQ",
  },
  production: {
    personal: "https://billing.stripe.com/p/login/bIYeVI5zq9KG7pm3cc",
    business: "https://billing.stripe.com/p/login/bIYeVI5zq9KG7pm3cc",
  },
};
