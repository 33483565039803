import React, { useEffect } from "react";
import IconButton from "../IconButton/IconButton";
import { Message } from "../../types/types.ts";
import { useAppContext } from "../../contexts/AppContext";

import useIntegrations from "../../hooks/useIntegrations";

import "./Social.css";

interface LinkedinButtonProps {
  setIsLinkedinModalOpen: (isOpen: boolean) => void;
  message: Message;
  setSelectedMessage: (message: Message) => void;
}

const LinkedinButton = ({
  message,
  setIsLinkedinModalOpen,
  setSelectedMessage,
}: LinkedinButtonProps) => {
  const {
    state: { user },
    actions: { setIntegrationServices },
  } = useAppContext();

  const { enableService, isServiceConnected } = useIntegrations();
  useEffect(() => {
    if (user) {
      setIntegrationServices(user.integrations);
    }
  }, [user]);

  const handleClick = () => {
    if (!isServiceConnected("linkedin")) {
      setSelectedMessage(message);
      enableService({
        label: "LinkedIn",
        key: "linkedin",
        enabled: true,
        api: undefined,
      });
    }
    setIsLinkedinModalOpen(true);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <ion-icon name="logo-linkedin"></ion-icon>
      </IconButton>
    </div>
  );
};

export default LinkedinButton;
