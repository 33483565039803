import React, { useEffect, useState } from "react";
import { getNews } from "../api/api";

const useFetchNews = () => {
  const [news, setNews] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const pageSize = 20;

  const fetchNews = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      const data = await getNews(pageNumber, pageSize);
      setNews((prevNews) => [...prevNews, ...data.results]);
      setHasMore(data.results.length === pageSize);
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNews(page);
  }, [page]);

  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return {
    news,
    isLoading,
    error,
    loadMore,
    hasMore,
  };
};

export default useFetchNews;
