import React, { useState } from "react";
import "./Tabs.css";

type Tab = { label: string; content: React.ReactNode };

interface Props {
  tabs: Tab[];
}

const Tabs = ({ tabs }: Props) => {
  const [selectedTab, setSelectedTab] = useState<Tab>(tabs[0]);

  return (
    <div className="tabs">
      <div className="tab-bar">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setSelectedTab(tab)}
            className={selectedTab.label == tab.label ? "selected" : ""}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">{selectedTab.content}</div>
    </div>
  );
};

export default Tabs;
