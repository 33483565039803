import React, { ReactNode, useEffect, useRef } from 'react';
import './LightBox.css';

interface LightBoxProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
}

const LightBox: React.FC<LightBoxProps> = ({ isOpen, onClose, children, title }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      const links = contentRef.current.getElementsByTagName('a');
      Array.from(links).forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [isOpen, children]);

  if (!isOpen) return null;

  return (
    <div className="lightbox-overlay" onClick={onClose}>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        <button className="lightbox-close" onClick={onClose}>&times;</button>
        {title && <h2 className="lightbox-title">{title}</h2>}
        <div className="lightbox-body" ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default LightBox;