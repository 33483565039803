export const sortOptions = [
  { value: "None", label: "None" },
  { value: "portfolio_name", label: "Portfolio" },
  { value: "next_run_date", label: "Next Run Date" },
  { value: "frequency", label: "Frequency" },
  { value: "document_type", label: "Document Type" },
  { value: "delivery_type", label: "Delivery" },
];

export const assignmentTableHeaders = [
  "portfolio",
  "assignment",
  "assignmentDescription",
  "assigmentRunDate",
  "assignmentFrequency",
  "assignmentDeliveryType",
  "assignmentDocumentType",
];

export const initialFilters = {
  portfolio: null,
  frequency: null,
  document_type: null,
  delivery_type: null,
  next_run_date: null,
};

export const filterConfig = [
  { name: "portfolio_name", type: "select" },
  { name: "frequency", type: "select" },
  { name: "document_type", type: "select" },
  { name: "delivery_type", type: "select" },
  { name: "next_run_date", type: "select" },
];

export const getPortfolioOptions = (list) => {
  const portfolios = [...new Set(list.map((item) => item.portfolio_name))];
  return portfolios.map((portfolio) => ({
    value: portfolio,
    label: portfolio,
  }));
};

export const getRunDateOptions = (list) => {
  const nextRunDates = [...new Set(list.map((item) => item.next_run_date))];
  return nextRunDates.map((date) => ({ value: date, label: date }));
};

export const STATIC_FILTERS = [
  {
    name: "frequency",
    label: "Frequency",
    type: "select",
    options: [
      { value: "One Time", label: "One Time" },
      { value: "Daily", label: "Daily" },
      { value: "Weekly", label: "Weekly" },
      { value: "Monthly", label: "Monthly" },
      { value: "Quarterly", label: "Quarterly" },
    ],
  },
  {
    name: "document_type",
    label: "Document Type",
    type: "select",
    options: [
      { value: "CSV", label: "CSV" },
      { value: "Word", label: "Word" },
      { value: "PDF", label: "PDF" },
      { value: "PowerPoint", label: "PowerPoint" },
    ],
  },
  {
    name: "delivery_type",
    label: "Delivery Type",
    type: "select",
    options: [
      { value: "Email", label: "Email" },
      { value: "Download", label: "Download" },
    ],
  },
];
