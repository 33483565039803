import NewsCard from "../../features/NewsCard/NewsCard";
import Stats from "../../features/Stats/Stats.tsx";
import React, { useState, useRef } from "react";
import Welcome from "../../features/Onboarding/Welcome.tsx";
import useFetchNews from "../../hooks/useFetchNews";
import { useAppContext } from "../../contexts/AppContext.tsx";
import Modal from "../../components/Modal/Modal.tsx";
import Checklist from "../../components/Checklist/Checklist.tsx";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";


import "./Activity.css";

function Activity() {
  const { news, loadMore, hasMore } = useFetchNews();
  const containerRef = useRef(null);
  const {
    state: { tasks },
    actions: { updateTaskData },
  } = useAppContext();
  useInfiniteScroll(loadMore, hasMore, containerRef);
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);

  return (
    <div className="activity-container">
      <Modal
        isOpen={isChecklistOpen}
        onClose={() => setIsChecklistOpen(false)}
        closeOnClickOutside
        hasCloseButton
      >
        <Checklist tasks={tasks} updateTask={updateTaskData} />
      </Modal>
      <Welcome />
      <Stats setIsChecklistOpen={setIsChecklistOpen} />
      <div className="news-cards" ref={containerRef}>
        {news.map((article, index) => (
          <div key={index}>
            <NewsCard article={article} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Activity;
