import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getInvestor } from "../../api/api.ts";
import InvestorProfileTabs from "../../features/InvestorProfile/InvestorProfileTabs.tsx";

const InvestorProfile = () => {
  const { investorId } = useParams();
  const [investor, setInvestor] = useState(null);

  useEffect(() => {
    if (investorId) {
      getInvestor(investorId).then((resp) => setInvestor(resp));
    }
  }, [investorId]);

  return (
    investor && (
      <>
        <h4 style={{ marginBottom: 30 }} className="settings-subheader">
          Investor Profile
        </h4>
        <h2 className="settings-header">{investor.name}</h2>
        <InvestorProfileTabs investorId={investorId} />
      </>
    )
  );
};

export default InvestorProfile;
