import React, { useState } from "react";
import "./NewsCard.css";
import ReactMarkdown from "react-markdown";
import { CarouselAlt } from "../../components/CarouselAlt/CarouselAlt";

function NewsCard({ article }) {
  const renderPublishedAtRelative = (publishedAtRelative: number): string => {
    if (publishedAtRelative >= 24) {
      const days = Math.floor(publishedAtRelative / 24);
      return (days != 0 ? days.toString() : "<1") + "d ago";
    } else {
      const hours = publishedAtRelative;
      return (hours != 0 ? hours.toString() : "<1") + "h ago";
    }
  };

  const renderReadingTime = (readingTime: number): string => {
    return (
      (Math.round(readingTime) !== 0
        ? Math.round(readingTime).toString()
        : "<1") + "min"
    );
  };

  return (
    <div className="card">
      <CarouselAlt
        slides={[
          <img
            src={article.photo_url}
            alt={article.title}
            className="card-image"
          />,
          <div className="summary">
            <div className="summary-inner">
              <p>Summary</p>
              <ReactMarkdown>{article.summary}</ReactMarkdown>
            </div>
          </div>,
        ]}
      />
      <a target="_blank" className="article-title" href={article.url}>
        <h4>{article.short_title ?? article.title}</h4>
      </a>
      <p>{article.title}</p>
      <div className="article-meta">
        <span className="article-source">Source: {article.source}</span>
        <span className="article-time-data">
          <span className="article-published">
            {renderPublishedAtRelative(article.published_at_relative)}
          </span>
          {article.estimated_reading_time !== null && (
            <span className="article-reading-time">
              {renderReadingTime(article.estimated_reading_time)}
            </span>
          )}
        </span>
      </div>
    </div>
  );
}

export default NewsCard;
