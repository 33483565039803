import React, { useState, useRef, useEffect } from 'react';
import './SlideoutBox.css'; // Import the CSS file for styles

interface SlideoutBoxProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position: { left: number; bottom: number } | null;
}

const SlideoutBox: React.FC<SlideoutBoxProps> = ({ isOpen, onClose, children, position }) => {
  const [height, setHeight] = useState<number | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(null);
    }
  }, [isOpen, children]);

  if (!isOpen || !position) return null;

  const style: React.CSSProperties = {
    position: 'absolute',
    left: `${position.left+10}px`,
    top: `${position.top+60}px`,
    maxHeight: height ? `${500}px` : '0',
    transition: 'max-height 0.3s ease-out',
    overflow: 'auto',
  };

  return (
    <div className="slideout-overlay" onClick={onClose}>
      <div
        className="slideout-content"
        style={style}
        onClick={(e) => e.stopPropagation()}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default SlideoutBox;