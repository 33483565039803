// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useWalkthrough } from "../../features/Walkthrough/Walkthrough.tsx";
import { ERRORS } from "../../constants/error.ts";
import { useAppContext } from "../../contexts/AppContext.tsx";
import {
  BarChart,
  LineChart,
  StyledTable,
  PieChart,
  TextChart,
} from "../home/Charts.tsx";

import useCharts from "../../hooks/useCharts.ts";
import getString from "../../utils/getString.ts";

function Chart({ model }) {
  if (model.complete === false) {
    return (
      <TextChart data={getString("stillProcessingQuery")} title={model.query} />
    );
  } else if (model.query && model.type === "bar") {
    return <BarChart bars={model.data} title={model.query} />;
  } else if (model.query && model.type === "line") {
    return <LineChart lineData={model.data} title={model.query} />;
  } else if (model.query && model.type === "table") {
    return <StyledTable tableData={model.data} title={model.query} />;
  } else if (model.query && model.type === "pie") {
    return <PieChart allocation={model.data} title={model.query} />;
  } else {
    return null;
  }
}

function Chat() {
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const [stats, setStats] = useState([]);
  const [addingChart, setAddingChart] = useState(false);
  const [isAddChartDisabled, setIsAddChartDisabled] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const {
    registerRef,
    unregisterRef,
    currentStep,
    refs,
    nextStep,
    prevStep,
    location,
    isActive,
    setIsActive,
    handleClose,
  } = useWalkthrough();
  const stepId = "chart";
  const chartRef = useRef(null);
  const navigate = useNavigate();
  const {
    state: { user, tasks, numAttentionRequired },
    actions: { setErrorInfo, updateTaskData },
  } = useAppContext();

  useEffect(() => {
    if (isActive && location) {
      navigate({ pathname: location, replace: true });
    }
  }, [navigate, location, isActive]);

  useEffect(() => {
    registerRef(stepId, chartRef);
    return () => unregisterRef(stepId);
  }, [registerRef, unregisterRef, stepId]);

  const { charts, processing, createChart, deleteChart, loading } = useCharts({
    onError: (e) => {
      if (e.message === ERRORS.limit) {
        setErrorInfo({ errorType: e.message, type: "daily charts" });
      } else {
        setAlertProps({ text: e.message, type: "error" });
      }
    },
  });

  function getStats(advisor_id) {
    axios
      .get(`/api/advisor/${advisor_id}/stats`)
      .then((response) => {
        setStats(response.data);
      })
      .catch((e) => setAlertProps({ text: e.message, type: "error" }));
  }

  useEffect(() => {
    if (user && user.advisor_id) {
      getStats(user.advisor_id);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.subscription) {
      const isDisabled = charts.length >= user?.subscription?.chart_limit;
      setIsAddChartDisabled(isDisabled);
      setTooltipText(getString("chartLimit"));
    }
  }, [user, charts]);


  return (
    <div className="chat-container">

    </div>
  );
}

export default Chat;
