import React, { useState } from "react";
import useInvestors from "../../hooks/useInvestors";
import SearchCardList from "../../components/Search/SearchCardList";
import SearchComponent from "../../components/Search/SearchComponent";
import InvestorNotesModal from "../../features/InvestorCard/InvestorNotesModal";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { useAppContext } from "../../contexts/AppContext.tsx";

const Investors = () => {
  const {
    state: { containerRef },
  } = useAppContext();
  const {
    investors,
    loadMore,
    hasMore,
    getProfileDataset,
    saveProfileDataset,
  } = useInvestors();
  useInfiniteScroll(loadMore, hasMore, containerRef);

  const [isNotesOpen, setOpenNotes] = useState(false);
  const [selectedInvestor, setSelectedInvestor] = useState(null);

  return (
    <div>
      <InvestorNotesModal
        isNotesOpen={isNotesOpen}
        selectedInvestor={selectedInvestor}
        setSelectedInvestor={setSelectedInvestor}
        setOpenNotes={setOpenNotes}
        getProfileDataset={getProfileDataset}
        saveProfileDataset={saveProfileDataset}
      />
      <SearchComponent
        data={investors}
        keysToSearch={["name"]}
        renderResults={(searchQuery, searchResults) => (
          <SearchCardList
            searchQuery={searchQuery}
            searchResults={searchResults}
            data={investors}
            setOpenNotes={setOpenNotes}
            setSelectedInvestor={setSelectedInvestor}
          />
        )}
      />
    </div>
  );
};

export default Investors;
