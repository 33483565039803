// @ts-nocheck
import "./monitoring/sentryConfig.ts";
import { sentryCreateBrowserRouter } from "./monitoring/sentryConfig.ts";
import axios from "axios";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  useLoaderData,
  useNavigate,
  Outlet,
} from "react-router-dom";
import Backdrop from "./components/Backdrop/Backdrop.tsx";
import U from "./pages/U.tsx";
import VerifyAccount from "./pages/auth/AuthVerify.tsx";
import SignUp from "./pages/auth/SignUp.tsx";
import AccountType from "./pages/auth/AccountType.tsx";
import BusinessPricing from "./pages/auth/BusinessPricing.tsx";
import PersonalPricing from "./pages/auth/PersonalPricing.tsx";
import Role from "./pages/Role.tsx";
import App from "./pages/App.tsx";
import Home from "./pages/home/Home.tsx";
import Chat from "./pages/chat/Chat.tsx";
import PoliciesLayout from "./pages/policies/Policies.tsx";
import Policy from "./pages/policies/Policy.tsx";
import Profile from "./pages/settings/Profile.tsx";
import Fin from "./pages/fin/Fin.tsx";
import Activity from "./pages/home/Activity.tsx";
import Upload from "./pages/fin/Upload.tsx";
import Assignments from "./pages/home/Assignments.tsx";
import Optimize from "./pages/fin/Optimize.tsx";
import {
  WalkthroughProvider,
  useWalkthrough,
} from "./features/Walkthrough/Walkthrough.tsx";

import Auth from "./pages/auth/Auth.tsx";
import ErrorPage from "./error-page.tsx";
import { TOTAL_WALKTHROUGH_STEPS } from "./constants/global.ts";

import { getCookie, setCookie, removeCookie } from "./cookie.ts";
import "./index.css";
import FinSettings from "./pages/settings/FinSettings.tsx";
import DataControls from "./pages/settings/DataControls.tsx";
import InvestorProfile from "./pages/investors/InvestorProfile.tsx";
import Investors from "./pages/investors/Investors.tsx";

const CSRF_COOKIE_NAME = "csrftoken";
const CSRF_OUTBOUND_HEADER_NAME = "X-CSRFToken";
const CSRF_INBOUND_HEADER_NAME = "csrftoken";

axios.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json";
  config.headers[CSRF_OUTBOUND_HEADER_NAME] = getCookie(CSRF_COOKIE_NAME);
  return config;
});

axios.interceptors.response.use(
  // if response is successful, there's a chance we're getting a new CSRF token
  // in which case we have to set that.
  (response) => {
    const newCsrf = response.headers.get(CSRF_INBOUND_HEADER_NAME);
    if (newCsrf) {
      console.log(`Received a new CSRF token: ${newCsrf}`);
      setCookie(CSRF_COOKIE_NAME, newCsrf);
    }
    return response;
  },

  // If we get unauthorized, clear the cookie and go to /. Note that because
  // we're outside the context of React app, we can't use navigate, so just
  // use the browser's builtin navigation.
  async (e) => {
    if (e.response.status === 500) {
      return Promise.reject(
        new Error(
          "We've detected an error and our developers are investigating. Please try again later.",
        ),
      );
    } else if (e.response.status === 401 || e.response.status === 403) {
      if (getCookie(CSRF_COOKIE_NAME)) {
        await axios.post("/api/auth/logout");
        removeCookie(CSRF_COOKIE_NAME);
      }
      if (!window.location.href.includes("auth#login")) {
        window.location.href = "/";
      }
      const message =
        e.response.data?.detail == "subscription_expired"
          ? "Please reach out to our billing department to renew your subscription."
          : "Authentication failed. Please ensure you've entered the correct credentials.";
      return Promise.reject(new Error(message));
    } else if (e.response.status === 400) {
      return Promise.reject(e);
    } else {
      // Convert our server error response to a javascript error.
      const errorMsg =
        e.response.data.message ?? Object.values(e.response.data).join(" ");

      // reject the promise normally on other errors from server
      return Promise.reject(new Error(errorMsg));
    }
  },
);

async function isAuthenticated() {
  return !!getCookie(CSRF_COOKIE_NAME);
}

function RouteFromRoot() {
  const isAuthenticated = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    isAuthenticated ? navigate("/u") : navigate("/auth");
  });
}

const WalkthroughLayout = ({ children }) => {
  const { isActive } = useWalkthrough();

  return (
    <>
      {isActive && <Backdrop />}
      {children}
      {/* Your routes and other layout components here */}
    </>
  );
};

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    loader: isAuthenticated,
    element: <RouteFromRoot />,
    errorElement: <ErrorPage />,
  },
  {
    path: "auth",
    loader: isAuthenticated,
    element: <Auth />,
    errorElement: <ErrorPage />,
  },
  {
    path: "policy",
    element: <PoliciesLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "terms",
        element: <Policy policyType="terms" />,
      },
      {
        path: "privacy",
        element: <Policy policyType="privacy" />,
      },
      {
        path: "sharing-and-publication",
        element: <Policy policyType="sharing-and-publication" />,
      },
      {
        path: "usage",
        element: <Policy policyType="usage" />,
      },
      {
        path: "risk",
        element: <Policy policyType="risk" />,
      },
    ],
  },
  {
    path: "verify",
    element: <VerifyAccount />,
    errorElement: <ErrorPage />,
  },
  {
    path: "signup",
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "signup/account-type",
    element: <AccountType/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "signup/account-type/business",
    element: <BusinessPricing/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "signup/account-type/personal",
    element: <PersonalPricing/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "u",
    element: (
      <WalkthroughLayout>
        <U />
      </WalkthroughLayout>
    ),
    loader: isAuthenticated,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "role",
        element: <Role />,
      },
      {
        path: "app",
        element: <App />,
        children: [
          {
            path: "settings",
            element: <Outlet />,
            children: [
              {
                path: "profile",
                element: <Profile />,
              },
              {
                path: "fin-settings",
                element: <FinSettings />,
              },
              {
                path: "data-controls",
                element: <DataControls />,
              },
            ],
          },
          {
            path: "home",
            element: <Home />,
          },
          {
            path: "fin",
            element: <Fin />,
            children: [
              {
                path: "activity",
                element: <Activity />,
              },
              {
                path: "upload",
                element: <Upload />,
              },
              {
                path: "assignments",
                element: <Assignments />,
              },
              {
                path: "optimize",
                element: <Optimize />,
              },
            ],
          },
          {
            path: "chat",
            element: <Chat />,
          },
          {
            path: "investors",
            element: <Investors />,
          },
          {
            path: "investors/:investorId",
            element: <InvestorProfile />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WalkthroughProvider totalSteps={TOTAL_WALKTHROUGH_STEPS}>
    <RouterProvider router={router} />
  </WalkthroughProvider>,
);
