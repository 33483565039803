import axios from "axios";
import React, { useEffect, useState } from "react";
import { FinSettings } from "../types/types";

interface Props {
  onError?: (message: string) => void;
}

const useFinSettings = (props?: Props) => {
  const [settings, setSettings] = useState<FinSettings>({
    ai_focus: null,
    verbosity: null,
    characteristics: null,
    additional_datasources: null,
    calibration_questions: null,
  });
  const [processing, setProcessing] = useState(false);

  const saveSettings = async (newSettings: FinSettings) => {
    setProcessing(true);
    const response = await axios.patch("/api/settings/fin", newSettings);
    refresh();
    setProcessing(false);
    return response;
  };

  const refresh = () => {
    axios
      .get("/api/settings/fin")
      .then((response) => {
        setSettings(response.data);
      })
      .catch((e) => {
        if (props?.onError) props.onError(e);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  return { saveSettings, settings, processing };
};

export default useFinSettings;
