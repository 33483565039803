import React from "react";
import { Outlet } from "react-router-dom";
import "./Policies.css";

const PoliciesLayout = () => {
  return (
    <div className="policies-container">
      <div className="policies-inner-container">
        <Outlet />
      </div>
    </div>
  );
};

export default PoliciesLayout;
