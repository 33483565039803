import React, { useState } from "react";
import getString from "../utils/getString";
import { submitContactForm } from "../api/api";

const useContactForm = (
  onResponse: (response: { text: string; type: "success" | "error" }) => void
) => {
  const [processing, setProcessing] = useState(false);

  const submit = async (form: string, values: {}) => {
    setProcessing(true);
    return submitContactForm(form, values)
      .then((response) => {
        setProcessing(false);
        if (onResponse) {
          onResponse({ text: getString("messageSent"), type: "success" });
        }
      })
      .catch((e) => {
        setProcessing(false);
        if (onResponse) {
          onResponse({
            text: getString("errorSendingMessage"),
            type: "error",
          });
        }
      });
  };

  return { submit, processing };
};

export default useContactForm;
