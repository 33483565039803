import React, { useEffect, useState } from "react";
import axios from "axios";
import { usePlaidLink } from "react-plaid-link";
import { authorizeInvestmentAccount, createPlaidLinkToken } from "../api/api";

const usePlaid = (enabled: boolean) => {
  const [linkToken, setLinkToken] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [investmentAccounts, setInvestmentAccounts] = useState([]);

  const { open, ready } = usePlaidLink({
    onSuccess: (public_token) => {
      authorizeInvestmentAccount(public_token).then(() => {
        generateLinkToken();
        refreshAccountList();
      });
    },
    onExit: () => {},
    onEvent: () => {},
    token: linkToken,
  });

  const refreshAccountList = () => {
    setProcessing(true);
    axios
      .get("/api/plaid/accounts")
      .then((response) => {
        setProcessing(false);
        setInvestmentAccounts(response.data);
      })
      .catch((e) => {
        setProcessing(false);
      });
  };

  const generateLinkToken = () => {
    createPlaidLinkToken().then((token) => {
      setLinkToken(token);
    });
  };

  const connectInvestmentAccount = () => {
    open();
  };

  useEffect(() => {
    if (enabled) {
      generateLinkToken();
      refreshAccountList();
    }
  }, []);

  return { connectInvestmentAccount, investmentAccounts, processing };
};

export default usePlaid;
