import React from 'react';
import ReactMarkdown from 'react-markdown';
import LightBox from '../LightBox/LightBox';
import remarkGfm from "remark-gfm";
import './HelpPopup.css';

interface HelpContent {
  content: string;
}

interface HelpPopupProps {
  isOpen: boolean;
  onClose: () => void;
  helpContent: HelpContent;
}

const HelpPopup: React.FC<HelpPopupProps> = ({ isOpen, onClose, helpContent }) => {
  return (
    <LightBox isOpen={isOpen} onClose={onClose} closeOnClickOutside hasCloseButton className="help-modal">
      <div className="help-popup-content">
        {helpContent?.content ? (
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{helpContent.content}</ReactMarkdown>
        ) : (
          <p className="help-popup-error">No help content available.</p>
        )}
      </div>
    </LightBox>
  );
};

export default HelpPopup;