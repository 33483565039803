import React, { useEffect, useState } from "react";
import { getChats } from "../api/api";

const useFetchChats = () => {
  const [chats, setChats] = useState<any[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChats = async () => {
    setIsLoading(true);
    getChats()
      .then((data) => {
        setChats(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchChats();
  }, []);

  return {
    chats,
    isLoading,
    error,
    fetchChats,
  };
};

export default useFetchChats;
