import { useState, useEffect } from "react";

const useFilter = (initialList, initialFilters, filterConfig) => {
  const [filteredItems, setFilteredItems] = useState(initialList);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    let updatedList = initialList;

    filterConfig.forEach(({ name, type }) => {
      if (filters[name]) {
        switch (type) {
          case "text":
            updatedList = updatedList.filter((item) => {
              return item[name].includes(filters[name]);
            });
            break;
          case "date":
            updatedList = updatedList.filter(
              (item) =>
                new Date(item[name]).toDateString() ===
                filters[name].toDateString(),
            );
            break;
          case "select":
            updatedList = updatedList.filter((item) => {
              return item[name].includes(filters[name]);
            });
            break;
          default:
            break;
        }
      }
    });

    setFilteredItems(updatedList);
  }, [filters, initialList, filterConfig]);

  const setFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  return {
    filteredItems,
    setFilter,
    resetFilters,
    filters,
  };
};

export default useFilter;
