import React, { useState, useEffect, useRef } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from "../../components/Button/Button";
import TextInput from "../../components/Fields/TextInput";
import "./SignUp.css";
import { useLocation, useNavigate } from 'react-router-dom';

const SignUp: React.FC = () => {
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const email = location.state?.email || null;
  const password = location.state?.password || null;

  const navigate = useNavigate();

  // Reference to the iframe
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Function to handle requesting a new OTP code
  const handleRequestNewCode = () => {
    if (!phoneNumber) {
      alert('Please enter a valid phone number.');
      return;
    }

    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US'); // Adjust default country code as needed
    if (parsedNumber && parsedNumber.isValid()) {
      const formattedNumber = parsedNumber.format('E.164');
      setFormattedPhoneNumber(formattedNumber);
      console.log("Formatted phone number:", formattedNumber);
      setIsModalOpen(true); // Open the modal
    } else {
      alert('Please enter a valid phone number.');
    }
  };

  // useEffect to handle iframe communication
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Verify the origin of the message for security
      if (event.origin !== 'https://verify-3130-1gsizi.twil.io') return;

      console.log("Received message from iframe:", event.data);

      // If iframe is ready, send the phone number
      if (event.data && event.data.status === 'iframe-ready') {
        console.log("Sending formattedPhoneNumber to iframe:", formattedPhoneNumber);
        iframeRef.current?.contentWindow?.postMessage({ phoneNumber: formattedPhoneNumber }, '*');
      }

      // Handle OTP success event
      if (event.data && event.data.status === 'success') {
        setIsModalOpen(false); // Close the modal on success
        navigate('/signup/account-type', { state: { email, password, phoneNumber: formattedPhoneNumber } });
      }
    };

    // Add event listener for message events
    window.addEventListener('message', handleMessage);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [email, password, formattedPhoneNumber, navigate]);

  return (
    <div className="account-creation-container">
      <div className="account-creation-wrapper">
        <div className="video-placeholder">
          <p>Video Message from the Founder</p>
        </div>
        <div className="form-section">
          <h2>First, let's create your account</h2>
          <p>Verify mobile phone number</p>
          <div className="input-wrapper">
            <TextInput
              type="tel"
              placeholder="(555) 555-5555"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <Button color="Secondary" onClick={handleRequestNewCode}>
            Send Verification Code
          </Button>
          <p className="disclaimer">
            You must have a valid phone number to use QuAIL Technologies services. SMS and data charges may apply.
          </p>
          <div className="account-email">
            <p>Account Email: {email}</p>
            <a href="https://app.quantumai.dev" className="change-email-link">Use a different email</a>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Verification</h2>
            <p>Please complete the verification process.</p>
            <iframe
              ref={iframeRef}
              src="https://verify-3130-1gsizi.twil.io/index.html"
              width="100%"
              height="500px"
              style={{ border: 'none' }}
              onLoad={() => {
                // Send the phone number to the iframe once it loads
                iframeRef.current?.contentWindow?.postMessage({ phoneNumber: formattedPhoneNumber }, '*');
              }}
            />
            <Button color="Secondary" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
