import React, { useEffect, useState } from "react";
import useFetchAssignments from "../../hooks/useFetchAssignments";
import AssignmentForm from "../../features/Assignment/AssignmentForm";
import AssignmentTable from "../../features/Assignment/AssignmentTable";
import { AssignmentContextProvider } from "../../contexts/AssignmentContext";
import getString from "../../utils/getString";
import axios from "axios";
import "../fin/Fin.css";

function Assignments() {
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const { assignments, isLoading, error, addAssignments, fetchAssignments } =
    useFetchAssignments();

  if (isLoading) {
    return <div>{getString("loading")}...</div>;
  }

  if (error) {
    return <div>{getString("errorOccured")}</div>;
  }

  const deleteAssignment = (assignment) => {
    if (assignment.id) {
      axios
        .delete(`/api/fin/assignment/${assignment.id}`)
        .then(() => fetchAssignments());
    }
  };

  const patchAssignment = (selectedAssignment, payload) => {
    if (selectedAssignment.id) {
      axios
        .patch(`/api/fin/assignment/${selectedAssignment.id}`, payload)
        .then(() => {
          setSelectedAssignment(null);
          fetchAssignments();
        });
    }
  };

  return (
    <div className="assignments-wrapper">
      {assignments?.length > 0 ? (
        <AssignmentTable
          assignments={assignments}
          deleteAssignment={deleteAssignment}
          setSelectedAssignment={setSelectedAssignment}
        />
      ) : (
        <div>{getString("scheduleAssignment")}...</div>
      )}
      <AssignmentContextProvider>
        <AssignmentForm
          onAddAssignments={addAssignments}
          selectedAssignment={selectedAssignment}
          patchAssignment={patchAssignment}
          numAssignments={assignments.length}
        />
      </AssignmentContextProvider>
    </div>
  );
}

export default Assignments;
