import React, { useState } from "react";
import Fieldset from "../../components/Fields/Fieldset";
import Button from "../../components/Button/Button";
import useContactForm from "../../hooks/useContactForm";
import { Field } from "../../types/types";

import "./ContactForm.css";

interface Props {
  name: string;
  fields: Field[];
  onResponse: (response: { text: string; type: "success" | "error" }) => void;
}

const ContactForm = ({ name, fields, onResponse }: Props) => {
  const [formValues, setFormValues] = useState({});

  const { submit, processing } = useContactForm(onResponse);

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(name, formValues).then(() => {
      setFormValues({});
    });
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <Fieldset fields={fields} values={formValues} onChange={setFormValues} />
      <div className="settings-actions">
        <Button
          disabled={processing}
          color="Secondary"
          text="Submit Request"
          type="submit"
        />
      </div>
    </form>
  );
};

export default ContactForm;
