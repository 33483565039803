import React from "react";
import Button from "../../components/Button/Button";
import usePlaid from "../../hooks/usePlaid";
import "./ConnectedAccounts.css";
import { updatePlanMessage } from "../../utils/getMessage";
import getString from "../../utils/getString";
import { useAppContext } from "../../contexts/AppContext";
import Tooltip from "../../components/Tooltip/Tooltip";

const ConnectedAccounts = () => {
  const {
    state: { user },
  } = useAppContext();
  const isDisabled = !user?.subscription?.has_integrations_access;
  const { connectInvestmentAccount, investmentAccounts } = usePlaid(
    !isDisabled
  );
  return (
    <div className={`connected-accounts ${isDisabled ? "not-allowed" : ""}`}>
      <Tooltip
        position="right"
        isHidden={!isDisabled}
        wrapperWidth="100%"
        text={updatePlanMessage(
          getString("integrationAccess"),
          user?.subscription?.plan_type
        )}
        minWidth="200px"
      >
        <div className="integrations-header">
          <div className="settings-item-label">Connected Accounts</div>
        </div>
        {investmentAccounts.map((account) => (
          <div className="connected-account" key={account.item_id}>
            {account.name}
          </div>
        ))}
        <Button
          color="Secondary"
          onClick={connectInvestmentAccount}
          disabled={isDisabled}
        >
          + Connect Account
        </Button>
      </Tooltip>
    </div>
  );
};

export default ConnectedAccounts;
