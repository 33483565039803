import React, { useState, useEffect } from "react";

const TypingEffect = ({ text, typingSpeed, onComplete }) => {
  const [displayedContent, setDisplayedContent] = useState([]);
  const [index, setIndex] = useState(0);
  const parsedContent = parseText(text);

  useEffect(() => {
    if (index < parsedContent.length) {
      const timer = setTimeout(() => {
        setDisplayedContent((current) => [...current, parsedContent[index]]);
        setIndex(index + 1);
      }, typingSpeed);
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [index, parsedContent, typingSpeed, onComplete]);

  return (
    <div>
      {displayedContent.map((item, idx) =>
        typeof item === "string"
          ? item
          : React.cloneElement(item, { key: idx }),
      )}
    </div>
  );
};

function parseText(inputText) {
  const elements = [];
  let lastEnd = 0;
  const regex = /\[([^\]]+)\]\(([^\s)]+)\)|\*\*([^*]+)\*\*|^- (.+)$/gm;
  let match;

  while ((match = regex.exec(inputText)) !== null) {
    if (match.index > lastEnd) {
      elements.push(...inputText.substring(lastEnd, match.index).split(""));
    }

    if (match[1] && match[2]) {
      // Link
      elements.push(
        <a href={match[2]} key={`${match.index}`}>
          {match[1]}
        </a>,
      );
    } else if (match[3]) {
      // Bold text
      elements.push(<strong key={`${match.index}`}>{match[3]}</strong>);
    } else if (match[4]) {
      // Bullet point
      elements.push(
        <div style={{ display: "flex" }} key={`${match.index}`}>
          <span>&bull;&nbsp;</span>
          <span>{parseText(match[4])}</span>
        </div>,
      );
    }

    lastEnd = match.index + match[0].length;
  }

  if (lastEnd < inputText.length) {
    elements.push(...inputText.substring(lastEnd).split(""));
  }

  return elements;
}

export default TypingEffect;
