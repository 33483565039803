import React, { useState, ReactNode, useRef } from "react";
import "./Tooltip.css";

type TooltipProps = {
  children: ReactNode;
  text: string;
  position: "top" | "bottom" | "left" | "right";
  minWidth?: string;
  wrapperWidth?: string;
  zIndex?: number;
  isHidden?: boolean;
};

const Tooltip: React.FC<TooltipProps> = ({
  children,
  text,
  position,
  minWidth = "100px",
  wrapperWidth,
  zIndex,
  isHidden = false,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const hideTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const getTooltipStyle = (): React.CSSProperties => {
    switch (position) {
      case "top":
        return {
          bottom: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: "10px",
        };
      case "bottom":
        return {
          top: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "10px",
        };
      case "left":
        return {
          right: "100%",
          top: "50%",
          transform: "translateY(-50%)",
          marginRight: "10px",
        };
      case "right":
        return {
          left: "100%",
          top: "50%",
          transform: "translateY(-50%)",
          marginLeft: "10px",
        };
      default:
        return {};
    }
  };

  const getTriangleStyle = (): React.CSSProperties => {
    const baseStyle: React.CSSProperties = {
      width: "0",
      height: "0",
      position: "absolute",
      borderStyle: "solid",
    };

    switch (position) {
      case "top":
        return {
          ...baseStyle,
          bottom: "-5px",
          left: "50%",
          transform: "translateX(-50%)",
          borderWidth: "5px 5px 0 5px",
          borderColor: "black transparent transparent transparent",
        };
      case "bottom":
        return {
          ...baseStyle,
          top: "-5px",
          left: "50%",
          transform: "translateX(-50%)",
          borderWidth: "0 5px 5px 5px",
          borderColor: "transparent transparent black transparent",
        };
      case "left":
        return {
          ...baseStyle,
          right: "-5px",
          top: "50%",
          transform: "translateY(-50%)",
          borderWidth: "5px 0 5px 5px",
          borderColor: "transparent transparent transparent black",
        };
      case "right":
        return {
          ...baseStyle,
          left: "-5px",
          top: "50%",
          transform: "translateY(-50%)",
          borderWidth: "5px 5px 5px 0",
          borderColor: "transparent black transparent transparent",
        };
      default:
        return {};
    }
  };

  const handleMouseEnter = () => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    hideTimeout.current = setTimeout(() => {
      setShowTooltip(false);
    }, 300);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="tooltip-wrapper"
      style={{ width: wrapperWidth } ?? null}
    >
      {children}
      {showTooltip && !isHidden && (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            position: "absolute",
            padding: "8px",
            backgroundColor: "black",
            color: "white",
            borderRadius: "4px",
            fontSize: "12px",
            whiteSpace: "normal",
            textAlign: "center",
            minWidth: minWidth,
            maxWidth: "200px",
            zIndex: zIndex ?? "",
            ...getTooltipStyle(),
          }}
        >
          {text}
          <div style={!isHidden && getTriangleStyle()}></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
