import React, { useState, useEffect } from "react";
import "./CarouselAlt.css";

interface CarouselAltProps {
  slides: JSX.Element[];
  height?: string;
  width?: string;
}

export const CarouselAlt: React.FC<CarouselAltProps> = ({ slides }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [hover, setHover] = useState(false);

  const goToSlide = (slideIndex: number) => {
    setActiveSlide(slideIndex);
  };

  const getCurrentSlide = () => {
    if (hover) {
      return 1;
    } else {
      return activeSlide;
    }
  };

  return (
    <div className="carousel-alt-wrapper">
      <div className="carousel-alt-inner">
        <div
          className="carousel-alt-slide-container"
          style={{
            width: slides.length * 100 + "%",
            left: -100 * getCurrentSlide() + "%",
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="carousel-alt-slide"
              style={{ width: 100 / slides.length + "%" }}
              onClick={() => goToSlide(index)}
            >
              <div className="slide-inner">{slide}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-alt-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`carousel-alt-dot ${
              index == getCurrentSlide() && "active"
            } ${index == activeSlide && "selected"}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};
