import React, { useEffect, useState } from "react";
import { ChatsActions } from "../../types/types.ts";
import IconButton from "../IconButton/IconButton.tsx";

import "./chatwindow.css";

interface ChatFeedbackProps {
  actionsSelected: ChatsActions;
  updateUserFeedback: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const ChatFeedback = ({
  actionsSelected,
  updateUserFeedback,
}: ChatFeedbackProps) => {
  const ACTIONS = [
    {
      label: "negative",
      icon: "thumbs-down",
      onClick: (e) => updateUserFeedback(e),
      color: "red",
    },
    {
      label: "positive",
      icon: "thumbs-up",
      onClick: (e) => updateUserFeedback(e),
      color: "green",
    },
  ];

  return (
    <div className="chat-feedback">
      {ACTIONS.map((action, index) => {
        return (
          <IconButton
            key={index}
            onClick={action.onClick}
            selected={actionsSelected[action.label]}
          >
            <ion-icon
              style={{ fill: `${action.color}` }}
              name={action.icon}
              id={action.label}
            ></ion-icon>
          </IconButton>
        );
      })}
    </div>
  );
};

export default ChatFeedback;
