import React from "react";
import Modal from "../../components/Modal/Modal.tsx";
import Button from "../../components/Button/Button.tsx";
import TextInput from "../../components/Fields/TextInput.tsx";
import { Message } from "../../types/types.ts";
import { updateFeedback } from "../../api/api.ts";

import "./chatwindow.css";

type DefaultOptions = {
  [key: string]: boolean;
};

interface ChatFeedbackModalProps {
  message: Message;
  setShowActions: (show: boolean) => void;
  customFeedback: string;
  setCustomFeedback: (text: string) => void;
  showFeedbackModal: boolean;
  setShowFeedbackModal: (show: boolean) => void;
  feedbackOptions: DefaultOptions;
  setFeedbackOptions: (options: DefaultOptions) => void;
}

const ChatFeedbackModal = ({
  customFeedback,
  setCustomFeedback,
  message,
  setShowActions,
  showFeedbackModal,
  setShowFeedbackModal,
  feedbackOptions,
  setFeedbackOptions,
}: ChatFeedbackModalProps) => {
  const midpoint = Math.ceil(Object.entries(feedbackOptions).length / 2);
  const leftList = Object.entries(feedbackOptions).slice(0, midpoint);
  const rightList = Object.entries(feedbackOptions).slice(midpoint);

  const handleSubmitCustomFeedback = () => {
    updateFeedback({
      chat_id: message.id,
      content: customFeedback,
      options: feedbackOptions,
    });
    setShowFeedbackModal(false);
    setShowActions(false);
  };

  const handleFeedbackModalClose = () => {
    setShowActions(false);
    setShowFeedbackModal(false);
  };

  const handleSelectOption = (e) => {
    setFeedbackOptions((prevOptions) => ({
      ...prevOptions,
      [e.target.innerText]: !prevOptions[e.target.innerText],
    }));
  };

  return (
    <Modal
      isOpen={showFeedbackModal}
      onClose={handleFeedbackModalClose}
      closeOnClickOutside
      hasCloseButton
      width={600}
    >
      <div className="feedback-modal">
        <h2>Help us improve</h2>
        <h4>
          Provide additional feedback on this answer. Select all that apply.
        </h4>
        <div className="feedback-list">
          <div className="inner-list">
            {leftList.map((option, index) => {
              return (
                <div
                  key={index}
                  className={`chat-bubble-feedback-option ${
                    option[1] && "selected"
                  }`}
                  onClick={handleSelectOption}
                >
                  {option[0]}
                </div>
              );
            })}
          </div>
          <div className="inner-list">
            {rightList.map((option, index) => {
              return (
                <div
                  key={index}
                  className={`chat-bubble-feedback-option ${
                    option[1] && "selected"
                  }`}
                  onClick={handleSelectOption}
                >
                  {option[0]}
                </div>
              );
            })}
          </div>
        </div>
        <div className="custom-feedback">
          <div>How can the response be improved? (optional)</div>
          <TextInput
            placeholder="Your feedback..."
            type="text"
            value={customFeedback}
            onChange={(e) => setCustomFeedback(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSubmitCustomFeedback()}
          />
          <Button
            color="Secondary"
            size="Small"
            onClick={handleSubmitCustomFeedback}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChatFeedbackModal;
