// @ts-nocheck
import axios from "axios";
import { AppContextProvider } from "../contexts/AppContext.tsx";
import { getUsage } from "../api/api.ts";
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Sidebar from "../features/Sidebar/Sidebar";
import React, { useRef, useEffect, useState } from "react";
import Navbar from "../features/Navbar/Navbar.tsx";
import ChatSidebar from "../features/ChatSidebar/ChatSidebar.tsx";

import Alert from "../components/Alert/Alert.tsx";

import "./U.css";

function U() {
  const containerRef = useRef(null);
  const isAuthenticated = useLoaderData();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });

  const [user, setUser] = useState(null);
  const [usage, setUsage] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/");
    }

    axios
      .get("/api/me", { withCredentials: true })
      .then((response) => {
        const u = response.data;
        setUser(u);

        // first we verify
        if (!u.verified_at) {
          navigate("/verify");
          return;
        }

        if (!u.role) {
          navigate("/u/role");
          return;
        }

        if (u.role === "investor") {
          navigate("/u/onboarding");
        } else {
          if (!window.location.pathname.includes("/u/app/")) {
            navigate("/u/app/fin/activity");
          }
        }
      })
      .catch((e) => setAlertProps({ text: e.message, type: "error" }));
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    getUsage().then((response) => setUsage(response));
  }, [user]);

  return (
    <div className="u-wrapper">
      <AppContextProvider
          user={user}
          usage={usage}
          containerRef={containerRef}
        >
        <Navbar />
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          <Sidebar />
          <div className="u-content-container" ref={containerRef}>
            {alertProps.text && <Alert {...alertProps} />}
            <Outlet />
          </div>
          <ChatSidebar />
        </div>
      </AppContextProvider>
    </div>
  );
}

export default U;
