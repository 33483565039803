import React from "react";
import "./Switch.css";

interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  disabled = false,
  "data-testid": testId,
}) => {
  return (
    <label className="switch" data-testid={testId}>
      <input
        disabled={disabled}
        type="checkbox"
        className="input"
        checked={checked}
        onChange={onChange}
      />
      <span className={`switch-slider ${disabled && "disabled"}`}></span>
    </label>
  );
};

export default Switch;
