import axios from "axios";
import { removeCookie } from "../cookie.ts";

export const goToHelp = () => {
  window.open("https://help.quantumai.dev", "_blank", "noopener,noreferrer");
};

export const logout = (e) => {
  e.preventDefault();
  axios
    .post("/api/auth/logout")
    .then(() => {
      removeCookie("csrftoken");
      window.location.pathname = "/";
    })
    .catch((e) => {
      console.error(e);
    });
};
