import React, { useState } from "react";
import "./Pricing.css";
import StripePricingTable from "../../features/StripePricingTable/StripePricingTable";

const BusinessPricing: React.FC = () => {
  const [showContact, setShowContact] = useState(false);

  return (
    <div className="fin-onboarding-container">
      <div className="fin-onboarding-wrapper">
        <StripePricingTable type="business" />
      </div>
    </div>
  );
};

export default BusinessPricing;
