import React, { useEffect, useState } from "react";
import PillSelect from "../../components/PillSelect/PillSelect";
import Slider from "../../components/Slider/Slider";
import Fieldset from "../../components/Fields/Fieldset";
import Button from "../../components/Button/Button";
import useFinSettings from "../../hooks/useFinSettings";
import Alert from "../../components/Alert/Alert";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import NewsSourcesFilter from "../../features/NewsSourcesFilter/NewsSourcesFilter";
import { updatePlanMessage } from "../../utils/getMessage.tsx";
import getString from "../../utils/getString";
import {
  AI_FOCUS_OPTIONS,
  CHARACTERISTICS_OPTIONS,
  CALIBRATION_QUESTIONS_FIELDS,
} from "../../constants/forms";
import SettingsForm from "../../features/SettingsForm/SettingsForm";
import { useAppContext } from "../../contexts/AppContext";

const FinSettings = () => {
  const [aiFocus, setAIFocus] = useState(null);
  const [verbosity, setVerbosity] = useState<number | null>(null);
  const [characteristics, setCharacteristics] = useState(null);
  const [additionalDatasources, setAdditionalDatasources] = useState(null);
  const [calibrationQuestions, setCalibrationQuestions] = useState(null);
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const [isDisabled, setIsDisabled] = useState(false);

  const { settings, saveSettings, processing } = useFinSettings();
  const {
    state: { user },
  } = useAppContext();

  useEffect(() => {
    const calibrationAccess = user?.subscription?.has_calibration_access;
    setIsDisabled(!calibrationAccess);
  }, [user?.subscription?.has_calibration_access]);

  const save = () => {
    saveSettings({
      ai_focus: aiFocus,
      verbosity: verbosity,
      characteristics: characteristics,
      additional_datasources: additionalDatasources,
      calibration_questions: calibrationQuestions,
    })
      .then(() => {
        setAlertProps({ text: getString("preferencesSaved"), type: "success" });
      })
      .catch((err) => {
        setAlertProps({
          text: getString("errorSavingPreferences"),
          type: "error",
        });
      });
  };

  useEffect(() => {
    setAIFocus(settings?.ai_focus);
    setVerbosity(settings?.verbosity);
    setCharacteristics(settings?.characteristics);
    setAdditionalDatasources(settings?.additional_datasources);
    setCalibrationQuestions(settings?.calibration_questions);
  }, [settings]);

  if (
    aiFocus === null ||
    verbosity === null ||
    characteristics === null ||
    additionalDatasources === null ||
    calibrationQuestions === null
  ) {
    return null;
  }

  return (
    <>
      {alertProps.text && <Alert {...alertProps} />}
      <SettingsForm>
        <SettingsForm.Main>
          <h4 style={{ marginBottom: 30 }} className="settings-subheader">
            Fin Settings
          </h4>
          <h2 className="settings-header">Calibration</h2>
          <SettingsForm.Group title="Where to focus?">
            <PillSelect
              options={AI_FOCUS_OPTIONS}
              selected={aiFocus}
              onChange={setAIFocus}
              canAdd={true}
            />
          </SettingsForm.Group>
          <SettingsForm.Group
            align="row"
            title="How detailed should responses be?"
          >
            <Slider
              value={verbosity}
              onChange={(val) => setVerbosity(Number(val))}
              startLabel="Short & Sweet"
              endLabel="Extensive"
            />
          </SettingsForm.Group>
          <SettingsForm.Group title="What qualities should Fin emphasize?">
            <PillSelect
              options={CHARACTERISTICS_OPTIONS}
              selected={characteristics}
              onChange={setCharacteristics}
              canAdd={true}
            />
          </SettingsForm.Group>
          <SettingsForm.Group title="Select news sources">
            <NewsSourcesFilter />
          </SettingsForm.Group>
        </SettingsForm.Main>
        <SettingsForm.Side>
          <SettingsForm.Group title="Calibration Questions">
            <Fieldset
              fields={CALIBRATION_QUESTIONS_FIELDS}
              values={calibrationQuestions}
              onChange={setCalibrationQuestions}
            />
          </SettingsForm.Group>
        </SettingsForm.Side>
      </SettingsForm>
      <Tooltip
        position="right"
        isHidden={!isDisabled}
        text={updatePlanMessage(
          getString("calibrationAccess"),
          user?.subscription?.plan_type,
        )}
        minWidth="200px"
      >
        <div className="settings-actions">
          <Button
            disabled={processing || isDisabled}
            color="Secondary"
            text="Save Changes"
            onClick={save}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default FinSettings;
