import React from "react";
import useFetchPolicy from "../../hooks/useFetchPolicy";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Policy = ({ policyType }) => {
  const { policy, error, isLoading } = useFetchPolicy({
    policyType: policyType,
  });
  return (
    <div>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {policy?.content}
      </ReactMarkdown>
    </div>
  );
};

export default Policy;
