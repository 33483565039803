import React, { useEffect, useState } from "react";
import useInvestors from "../../hooks/useInvestors";
import Modal from "../../components/Modal/Modal.tsx";
import { useAppContext } from "../../contexts/AppContext";
import TextInput from "../../components/Fields/TextInput";
import "./InvestorNotesModal.css";

const InvestorNotesModal = ({
  selectedInvestor,
  isNotesOpen,
  setOpenNotes,
  setSelectedInvestor,
  getProfileDataset,
  saveProfileDataset,
}) => {
  const [notes, setNotes] = useState("");
  const {
    state: { user },
  } = useAppContext();

  useEffect(() => {
    if (selectedInvestor) {
      getProfileDataset(selectedInvestor.id, "investment_profile").then(
        (data) => {
          setNotes(data.notes);
        },
      );
    }
  }, [selectedInvestor]);

  const handleTextChange = (e) => {
    setNotes(e.target.value);
  };

  const handleCloseNotes = () => {
    if (user?.subscription?.has_investor_profile_access) {
      saveProfileDataset(selectedInvestor.id, "investment_profile", {
        notes: notes,
      });
    }
    setOpenNotes(false);
    setSelectedInvestor(null);
    setNotes("");
  };

  return (
    <div>
      <Modal
        isOpen={isNotesOpen}
        onClose={handleCloseNotes}
        closeOnClickOutside
        hasCloseButton
        width={500}
        height={200}
      >
        <div className="investors-modal-inner">
          {!user?.subscription?.has_investor_profile_access && "disabled" ? (
            <div className="investor-notes-block-message">
              Subscribe to a paid plan to use this feature.
            </div>
          ) : (
            <>
              <div className="investors-selected-name">
                {selectedInvestor?.name}
              </div>
              <TextInput
                align="row"
                multiline={true}
                data-testid="name"
                placeholder="Notes..."
                value={notes}
                onChange={handleTextChange}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default InvestorNotesModal;
