import React, { useEffect, useState } from "react";
import { getHelp } from "../api/api";

interface Props {
  helpType: string;
}

const useFetchHelp = ({ helpType }: Props) => {
  const [help, setHelp] = useState<any[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchHelp = async (helpType) => {
    setIsLoading(true);
    getHelp(helpType)
      .then((data) => {
        setHelp(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (helpType) {
      fetchHelp(helpType);
    }
  }, [helpType]);

  return {
    help,
    isLoading,
    error,
  };
};

export default useFetchHelp;
