import React from "react";
import { useAssignmentContext } from "../../contexts/AssignmentContext";
import getString from "../../utils/getString";

import "./Assignment.css";

function AssignmentName() {
  const {
    state: { assignmentName },
    actions: { setAssignmentName },
  } = useAssignmentContext();

  const handleNameChange = (e) => {
    setAssignmentName(e.target.value);
  };

  return (
    <div>
      <h4 className="assignment-name-header">{getString("assignmentName")}</h4>
      <input
        className="assignment-name-input"
        onChange={handleNameChange}
        placeholder="Example: Monthly Risk Report"
        value={assignmentName}
      />
    </div>
  );
}

export default AssignmentName;
