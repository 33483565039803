import React from "react";
import { ChatsActions } from "../../types/types.ts";
import Copy from "../../components/Copy/Copy.tsx";
import ChatFeedback from "./ChatFeedback.tsx";
import LinkedinButton from "../Social/LinkedinButton.tsx";
import { Message } from "../../types/types.ts";
import ChatValidationButton from "./ChatValidationButton.tsx";

import "./chatwindow.css";

interface ChatBubbleActionsProps {
  actionsSelected: ChatsActions;
  updateUserFeedback: (e: React.MouseEvent<HTMLDivElement>) => void;
  messageText: string;
  setValidationOpen: (isOpen: boolean) => void;
  isValid: boolean;
  setIsLinkedinModalOpen: (open: boolean) => void;
  setSelectedMessage: (message: Message) => void;
  message: Message;
}

const ChatBubbleActions = ({
  actionsSelected,
  updateUserFeedback,
  messageText,
  setValidationOpen,
  isValid,
  setIsLinkedinModalOpen,
  setSelectedMessage,
  message,
}: ChatBubbleActionsProps) => {
  return (
    <div className="chat-bubble-actions">
      <LinkedinButton
        message={message}
        setSelectedMessage={setSelectedMessage}
        setIsLinkedinModalOpen={setIsLinkedinModalOpen}
      />
      <Copy text={messageText} />
      <ChatValidationButton
        setValidationOpen={setValidationOpen}
        isValid={isValid}
      />
      <ChatFeedback
        actionsSelected={actionsSelected}
        updateUserFeedback={updateUserFeedback}
      />
    </div>
  );
};

export default ChatBubbleActions;
