// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useWalkthrough } from "../../features/Walkthrough/Walkthrough.tsx";
import Stats from "../../features/Stats/Stats.tsx";
import Pendo from "../../components/Pendo/Pendo.tsx";
import { ERRORS } from "../../constants/error.ts";
import Alert from "../../components/Alert/Alert.tsx";
import Chatwindow from "../../components/Chatbox/chatwindow.tsx";
import { updatePlanMessage } from "../../utils/getMessage.tsx";
import AddChartWidget from "../../features/AddChartWidget/AddChartWidget.tsx";
import Button from "../../components/Button/Button.tsx";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import Modal from "../../components/Modal/Modal.tsx";
import Checklist from "../../components/Checklist/Checklist.tsx";
import { useAppContext } from "../../contexts/AppContext.tsx";

import {
  BarChart,
  LineChart,
  StyledTable,
  PieChart,
  TextChart,
} from "./Charts.tsx";

import "./Home.css";
import useCharts from "../../hooks/useCharts.ts";
import getString from "../../utils/getString.ts";

function Chart({ model }) {
  if (model.complete === false) {
    return (
      <TextChart data={getString("stillProcessingQuery")} title={model.query} />
    );
  } else if (model.query && model.type === "bar") {
    return <BarChart bars={model.data} title={model.query} />;
  } else if (model.query && model.type === "line") {
    return <LineChart lineData={model.data} title={model.query} />;
  } else if (model.query && model.type === "table") {
    return <StyledTable tableData={model.data} title={model.query} />;
  } else if (model.query && model.type === "pie") {
    return <PieChart allocation={model.data} title={model.query} />;
  } else {
    return null;
  }
}

function Home() {
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const [stats, setStats] = useState([]);
  const [addingChart, setAddingChart] = useState(false);
  const [isAddChartDisabled, setIsAddChartDisabled] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const {
    registerRef,
    unregisterRef,
    currentStep,
    refs,
    nextStep,
    prevStep,
    location,
    isActive,
    setIsActive,
    handleClose,
  } = useWalkthrough();
  const stepId = "chart";
  const chartRef = useRef(null);
  const navigate = useNavigate();
  const {
    state: { user, tasks, numAttentionRequired },
    actions: { setErrorInfo, updateTaskData },
  } = useAppContext();

  useEffect(() => {
    if (isActive && location) {
      navigate({ pathname: location, replace: true });
    }
  }, [navigate, location, isActive]);

  useEffect(() => {
    registerRef(stepId, chartRef);
    return () => unregisterRef(stepId);
  }, [registerRef, unregisterRef, stepId]);

  const { charts, processing, createChart, deleteChart, loading } = useCharts({
    onError: (e) => {
      if (e.message === ERRORS.limit) {
        setErrorInfo({ errorType: e.message, type: "daily charts" });
      } else {
        setAlertProps({ text: e.message, type: "error" });
      }
    },
  });

  function getStats(advisor_id) {
    axios
      .get(`/api/advisor/${advisor_id}/stats`)
      .then((response) => {
        setStats(response.data);
      })
      .catch((e) => setAlertProps({ text: e.message, type: "error" }));
  }

  useEffect(() => {
    if (user && user.advisor_id) {
      getStats(user.advisor_id);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.subscription) {
      const isDisabled = charts.length >= user.subscription?.chart_limit;
      setIsAddChartDisabled(isDisabled);
      setTooltipText(
        updatePlanMessage(
          getString("chartLimit"),
          user?.subscription?.plan_type,
        ),
      );
    }
  }, [user, charts]);

  const showPendo = currentStep === 3 && refs["chart"] && isActive;

  return (
    <div className="home-container">
      <Modal
        isOpen={isChecklistOpen}
        onClose={() => setIsChecklistOpen(false)}
        closeOnClickOutside
        hasCloseButton
      >
        <Checklist tasks={tasks} updateTask={updateTaskData} />
      </Modal>
      {alertProps.text && <Alert {...alertProps} />}
      <div ref={chartRef}>
        {showPendo && (
          <Pendo
            content="Click here to generate a new chart"
            targetRef={chartRef}
            next={nextStep}
            prev={prevStep}
            currentStep={currentStep}
            setIsActive={setIsActive}
            handleClose={handleClose}
          />
        )}
      </div>
      <Stats setIsChecklistOpen={setIsChecklistOpen} />
      <div className="charts-wrapper">
        <div className={`new-button-wrapper ${showPendo ? "active" : ""}`}>
          <Tooltip
            position="right"
            text={isAddChartDisabled ? tooltipText : "Add new chart"}
            minWidth="250px"
          >
            <Button
              color="White"
              disabled={isAddChartDisabled}
              onClick={() => setAddingChart(true)}
            >
              <div className="home-new-chart-btn">
                <ion-icon name="add-circle-outline" size="small"></ion-icon>
                <span className="home-new-chart-text">New</span>
              </div>
            </Button>
          </Tooltip>
        </div>
        <div className="charts-container">
          {(addingChart || processing || loading) && (
            <AddChartWidget
              onSubmit={(query: String) => {
                createChart(query);
                setAddingChart(false);
              }}
              onCancel={() => setAddingChart(false)}
              processing={processing}
              loading={loading}
            />
          )}
          {charts.map((c, idx) => {
            return (
              <div
                key={idx}
                className="charts-container-chart"
                data-testid="chartWidget"
              >
                <div
                  className="chart-delete-button"
                  data-testid="chartDeleteButton"
                  onClick={() => deleteChart(c.id)}
                >
                  <ion-icon size="small" name="close-circle-sharp"></ion-icon>
                </div>
                <Chart key={idx} model={c} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Home;
