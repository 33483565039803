import React, { useState, useRef, useEffect } from "react";
import "./Filter.css";
import useClickOutside from "../../hooks/useClickOutside";
import Checkbox from "../Checkbox/Checkbox";

interface FilterProps {
  numTopOptions: number;
  onSelect: (option: any) => void;
  options: any[];
  placeholder: string;
}

const Filter = ({
  numTopOptions,
  options,
  onSelect,
  placeholder,
}: FilterProps) => {
  const [isActive, setIsActive] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);

  useClickOutside(filterRef, () => setIsActive(false));

  return (
    <div className="filter" ref={filterRef}>
      <button onClick={() => setIsActive(!isActive)} className="filter-button">
        {placeholder}
      </button>
      <div className={`filter-list ${isActive ? "active" : "inactive"}`}>
        <div className="filter-list-inner">
          {options &&
            options.map((option, i) => {
              return (
                <div key={i} className="filter-option">
                  <Checkbox option={option} onSelect={onSelect} />
                  {i === numTopOptions - 1 && <hr />}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Filter;
