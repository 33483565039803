import React, { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext.tsx";
import "./AUMSummary.css";
import axios from "axios";

function formatCurrency(value) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}
interface Account {
  name: string;
  value: number;
  change: number;
}

const AUMSummary: React.FC = () => {
  const {
    state: { user },
  } = useAppContext();
  const [AUMStats, setAUMStats] = useState([]);

  useEffect(() => {
    if (user && user.advisor_id) {
      getAumStats(user.advisor_id);
    }
  }, [user]);

  function getAumStats(advisor_id) {
    axios.get(`/api/advisor/${advisor_id}/aum-stats`).then((response) => {
      setAUMStats(response.data);
    });
  }

  return (
    <div className="aum-summary">
      {AUMStats.daily_aum_change && AUMStats.daily_aum_percentage_change && (
        <>
          <h2 className="underlined">Daily Change</h2>
          <span className="total-label">TOTAL</span>
          <div className="total-section">
            <span className="total-value">
              {AUMStats.daily_aum_change &&
                formatCurrency(AUMStats.daily_aum_change)}
            </span>
            <span
              className={`total-change ${
                AUMStats.daily_aum_percentage_change >= 0
                  ? "positive"
                  : "negative"
              }`}
            >
              {AUMStats.daily_aum_percentage_change &&
              AUMStats.daily_aum_percentage_change >= 0
                ? " +"
                : " "}
              {AUMStats.daily_aum_percentage_change?.toFixed(2)}%
            </span>
          </div>
        </>
      )}
      {AUMStats.top_accounts && (
        <>
          <h2>
            <span className="underlined">Top Accounts</span>{" "}
            <span className="subtitle">(by AUM)</span>
          </h2>
          {AUMStats.top_accounts.map((account, index) => (
            <div key={index} className="account">
              <div className="account-name">{account.name}</div>
              <div className="account-value">
                {formatCurrency(account.total_aum)}
                {account.performance && (
                  <span
                    className={`change ${
                      account.performance.day >= 0 ? "positive" : "negative"
                    }`}
                  >
                    {account.performance.day >= 0 ? " +" : " "}
                    {account.performance.day.toFixed(2)}%
                  </span>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default AUMSummary;
