import React, { useEffect, useState } from "react";
import useInvestors from "../../hooks/useInvestors";
import { updatePlanMessage } from "../../utils/getMessage.tsx";
import {
  ESTATE_PLANNING_FIELDS,
  FINANCIAL_INFORMATION_FIELDS,
  INVESTMENT_PROFILE_FIELDS,
  LEGAL_REGULATORY_FIELDS,
  LIQUIDITY_NEEDS_FIELDS,
} from "../../constants/forms";
import SettingsForm from "../SettingsForm/SettingsForm";
import Button from "../../components/Button/Button";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import Fieldset from "../../components/Fields/Fieldset";
import getString from "../../utils/getString";
import Alert from "../../components/Alert/Alert";
import { useAppContext } from "../../contexts/AppContext";

const DATASET_FIELDS = {
  estate_planning: ESTATE_PLANNING_FIELDS,
  financial_information: FINANCIAL_INFORMATION_FIELDS,
  investment_profile: INVESTMENT_PROFILE_FIELDS,
  legal_regulatory: LEGAL_REGULATORY_FIELDS,
  liquidity_needs: LIQUIDITY_NEEDS_FIELDS,
};

const DATASET_LABELS = {
  estate_planning: "Estate Planning",
  financial_information: "Financial Information",
  investment_profile: "Investment Profile",
  legal_regulatory: "Legal Regulatory",
  liquidity_needs: "Liquidity Needs",
};

const InvestorProfileDataset = ({ investorId, datasetId }) => {
  const [data, setData] = useState<{}>(null);
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const [errors, setErrors] = useState(null);
  const { getProfileDataset, saveProfileDataset, processing } = useInvestors();
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    state: { user },
  } = useAppContext();

  useEffect(() => {
    const investorProfileAccess =
      user?.subscription?.has_investor_profile_access;
    setIsDisabled(!investorProfileAccess);
  }, [user?.subscription?.has_investor_profile_access]);

  const save = () => {
    saveProfileDataset(investorId, datasetId, data)
      .then(() => {
        setAlertProps({ text: getString("preferencesSaved"), type: "success" });
        setErrors(null);
      })
      .catch((e) => {
        setAlertProps({
          text: getString("errorSavingPreferences"),
          type: "error",
        });
        setErrors(e.response.data);
      });
  };

  useEffect(() => {
    getProfileDataset(investorId, datasetId).then((data) => setData(data));
  }, []);

  return (
    data && (
      <>
        <SettingsForm>
          <SettingsForm.Main>
            {alertProps.text && <Alert {...alertProps} />}
            <SettingsForm.Group title={DATASET_LABELS[datasetId]}>
              <Fieldset
                fields={DATASET_FIELDS[datasetId]}
                errors={errors}
                values={data}
                onChange={setData}
              />
            </SettingsForm.Group>
          </SettingsForm.Main>
        </SettingsForm>
        <div className="settings-actions">
          <Tooltip
            position="right"
            isHidden={!isDisabled}
            text={updatePlanMessage(
              getString("investorProfileAccess"),
              user?.subscription?.plan_type,
            )}
            minWidth="200px"
          >
            <Button
              disabled={processing || isDisabled}
              color="Secondary"
              text="Save Changes"
              onClick={save}
            />
          </Tooltip>
        </div>
      </>
    )
  );
};

export default InvestorProfileDataset;
