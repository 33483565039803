import React from "react";
import "./ProgressBar.css";

interface ProgressBarProps {
  progress: number; // percentage (0 to 100)
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  // Ensure progress is between 0 and 100
  const validProgress = Math.min(100, Math.max(0, progress));

  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar-filler"
        style={{ width: `${validProgress}%` }} // dynamic width
      />
    </div>
  );
};

export default ProgressBar;
