import React, { useState, useEffect } from "react";
import "./Carousel.css";
import { useWalkthrough } from "../../features/Walkthrough/Walkthrough";

interface CarouselProps {
  slides: JSX.Element[];
  height?: string;
  width?: string;
  showArrows?: boolean;
  autoSlide?: boolean;
  autoSlideInterval?: number;
  loop?: boolean;
}

export const Carousel: React.FC<CarouselProps> = ({
  slides,
  height = "100%",
  width = "100%",
  showArrows = true,
  autoSlide = false,
  autoSlideInterval = 8000,
  loop = false,
}) => {
  const { activeSlide, setActiveSlide } = useWalkthrough();
  const [userInteracted, setUserInteracted] = useState(false);

  const goToSlide = (slideIndex: number) => {
    setActiveSlide(slideIndex);
    setUserInteracted(true);
  };

  const goToNextSlide = () => {
    setActiveSlide((prev) =>
      loop
        ? (prev + 1) % slides.length
        : prev === slides.length - 1
          ? prev
          : prev + 1,
    );
  };

  const goToPrevSlide = () => {
    setActiveSlide((prev) =>
      loop
        ? (prev - 1 + slides.length) % slides.length
        : prev === 0
          ? 0
          : prev - 1,
    );
    setUserInteracted(true);
  };

  useEffect(() => {
    if (autoSlide && !userInteracted) {
      const interval = setInterval(() => {
        goToNextSlide();
      }, autoSlideInterval);

      return () => clearInterval(interval);
    }
  }, [
    autoSlide,
    autoSlideInterval,
    slides.length,
    activeSlide,
    userInteracted,
    loop,
  ]);

  const carouselWrapperStyle = {
    width: width,
    height: height,
    overflow: "hidden",
  };

  const slideContainerStyle = {
    display: "flex",
    width: `${100 * slides.length}%`,
    height: "100%",
    transform: `translateX(-${(activeSlide * 100) / slides.length}%)`,
    transition: "transform 0.5s ease",
  };

  const slideStyle = {
    width: `${100 / slides.length}%`,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "20px",
  };

  const dotStyle = (index: number) => ({
    margin: "0 5px",
    cursor: "pointer",
    color: index === activeSlide ? "black" : "gray",
  });

  return (
    <div className="carousel-wrapper" style={carouselWrapperStyle}>
      {showArrows && (
        <span className="carousel-arrow left" onClick={() => goToPrevSlide()}>
          &#10094;
        </span>
      )}
      <div style={slideContainerStyle}>
        {slides.map((slide, index) => (
          <div key={index} style={slideStyle}>
            <div className="slide-inner">{slide}</div>
          </div>
        ))}
      </div>
      {showArrows && (
        <span className="carousel-arrow right" onClick={() => goToNextSlide()}>
          &#10095;
        </span>
      )}
      <div className="carousel-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            style={dotStyle(index)}
            onClick={() => goToSlide(index)}
          >
            ●
          </span>
        ))}
      </div>
    </div>
  );
};
