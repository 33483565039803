import React from "react";
import { Outlet } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="home-wrapper">
      <div className="home-content">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
