import { Field } from "../types/types";

export const AI_FOCUS_OPTIONS = [
  "Equity",
  "Portfolio Optimization",
  "Fundamental Analysis",
  "Fixed Income",
  "Market Research",
  "Client Management",
  "Reliable Newsources",
  "Sharpe Ratio",
  "Behaviorial Finance",
];

export const CHARACTERISTICS_OPTIONS = [
  "detail-oriented",
  "verifiable",
  "proactive",
  "inquisitive",
];

export const ADDITIONAL_DATASOURCES_OPTIONS = [
  "Yahoo Finance",
  "SEC Filings",
  "Option Pricing",
  "Morningstar",
  "Financial Statements",
];

export const CALIBRATION_QUESTIONS_FIELDS: Field[] = [
  {
    id: "investment_strategy_focus",
    label: "Key investment strategies to focus on",
    placeholder: "ex. Growth, Yield, Tax-loss Harvesting",
  },
  {
    id: "key_performance_metrics",
    label: "Most important performance metrics?",
    placeholder: "ex. Return, Volatility, Information Ratio",
  },
  {
    id: "key_investment_metrics",
    label: "Most important investment metrics?",
    placeholder: "ex. Cash Flow, Book Value, Momentum",
  },
  {
    id: "primary_news_sources",
    label: "Primary sources",
    placeholder: "ex. Bloomberg, WSJ, Morningstar",
  },
  {
    id: "account_review_frequency",
    label: "How often do you review accounts?",
    placeholder: "ex. Daily, Monthly, Quarterly",
  },
  {
    id: "market_focus",
    label: "Specific markets to focus on or avoid?",
    placeholder: "ex. US Equities",
  },
  {
    id: "key_financial_indicators",
    label: "What financial indicators matter most?",
    placeholder: "ex. profitability ratios",
  },
  {
    id: "additional_considerations",
    label: "Anything else that Fin should consider?",
    placeholder: "ex. focus on sharpe ratio",
  },
];

export const DATA_CONTROLS_FORM_FIELDS: Field[] = [
  {
    id: "request_type",
    label: "What is the reason for your request?",
    placeholder: "ex. I would like to delete my data.",
    required: true,
  },
  {
    id: "additional_information",
    label: "Additional Information",
    multiline: true,
  },
];

export const ESTATE_PLANNING_FIELDS: Field[] = [
  {
    id: "will_and_testament",
    label: "Will and Testament",
    placeholder: "ex. Verified by attorney",
  },
  {
    id: "beneficiaries",
    label: "Beneficiaries",
    placeholder: "ex. Step-children",
  },
  {
    id: "trust_structure",
    label: "Trust Structure",
    placeholder: "ex. Charitable Lead Trust",
  },
];

export const FINANCIAL_INFORMATION_FIELDS: Field[] = [
  {
    id: "income_sources",
    label: "Income Sources",
    placeholder: "ex. Consulting, rental property",
  },
  {
    id: "annual_income",
    label: "Annual Income",
  },
  {
    id: "assets",
    label: "Assets",
    placeholder:
      "ex. Savings Account: $55,000, Rental Property: $1,100,000, Stocks: $18,000",
  },
  {
    id: "liabilities",
    label: "Liabilities",
    placeholder: "ex. Home Loan: $190,000 remaining, Credit Card Debt: $4,000",
  },
  {
    id: "tax_status",
    label: "Tax Status",
    placeholder: "ex. filed annually, no tax issues",
  },
];

export const INVESTMENT_PROFILE_FIELDS: Field[] = [
  {
    id: "investment_goals",
    label: "Investment Goals",
    placeholder: "ex. Establish a college fund for children",
  },
  {
    id: "investment_goals_duration",
    label: "Investment Goals Duration",
    placeholder: "ex. Long Term",
  },
  {
    id: "income_needs",
    label: "Income Needs",
    placeholder: "ex. $9,000 monthly post-retirement",
  },
  {
    id: "risk_comprehension",
    label: "Risk Comprehension",
    placeholder: "ex. Low",
  },
  {
    id: "investment_experience",
    label: "Investment Experience",
    placeholder: "ex. 3 years, money market funds",
  },
  {
    id: "loss_capacity",
    label: "Loss Capacity",
    placeholder: "ex. Able to handle 12% loss",
  },
  {
    id: "preferred_asset_classes",
    label: "Preferred Asset Classes",
    placeholder: "ex. Bonds: 30%, Stocks: 55%, Real Estate: 15%",
  },
  {
    id: "esg_preferences",
    label: "ESG Preferences",
    placeholder:
      "ex. Supports sustainable fashion and invests in companies with ethical supply chains",
  },
  {
    id: "desired_investment_strategies",
    label: "Desired Investment Strategies",
    placeholder: "ex. Low-cost ETF investments for sustained growth",
  },
  {
    id: "retirement_age",
    label: "Retirement Age",
    placeholder: "ex. 65",
  },
];

export const LEGAL_REGULATORY_FIELDS: Field[] = [
  {
    id: "ongoing_litigation",
    label: "Ongoing Litigation",
    placeholder: "ex. Trademark infringement case",
  },
  {
    id: "regulatory_considerations",
    label: "Regulatory Considerations",
    placeholder: "ex. Subject to low level compliance",
  },
];

export const LIQUIDITY_NEEDS_FIELDS: Field[] = [
  {
    id: "cash_needs",
    label: "Cash Needs",
    placeholder: "ex. 800",
  },
  {
    id: "large_purchases_future_expenses",
    label: "Large Purchases / Future Expenses",
    placeholder: "ex. Vintage car restoration project, ongoing",
  },
];
