import React, { createContext, useContext, useReducer } from "react";
import { Portfolio } from "../types/types";

export enum ActionTypes {
  SET_FREQUENCY = "SET_FREQUENCY",
  SET_DELIVERY_TYPE = "SET_DELIVERY_TYPE",
  SET_DOCUMENT_TYPE = "SET_DOCUMENT_TYPE",
  SET_ASSIGNMENT_NAME = "SET_ASSIGNMENT_NAME",
  SET_ASSIGNMENT_DESCRIPTION = "SET_ASSIGNMENT_DESCRIPTION",
  SET_SELECTED_PORTFOLIOS = "SET_SELECTED_PORTFOLIOS",
  SET_START_DATE = "SET_START_DATE",
}
export const AssignmentContext = createContext({} as AssignmentStore);

type ContextState = {
  frequency: string[];
  deliveryType: string[];
  documentType: string[];
  assignmentName: string;
  assignmentDescription: string;
  selectedPortfolios: Portfolio[];
  startDate: Date;
};

type setActionType = {
  type: ActionTypes;
  payload: any;
};

type AssignmentContextProviderProps = {
  children: any;
};

const reducer = (state: ContextState, action: setActionType): ContextState => {
  switch (action.type) {
    case ActionTypes.SET_FREQUENCY: {
      return {
        ...state,
        frequency: action.payload,
      };
    }
    case ActionTypes.SET_DELIVERY_TYPE: {
      return {
        ...state,
        deliveryType: action.payload,
      };
    }
    case ActionTypes.SET_DOCUMENT_TYPE: {
      return {
        ...state,
        documentType: action.payload,
      };
    }
    case ActionTypes.SET_ASSIGNMENT_NAME: {
      return {
        ...state,
        assignmentName: action.payload,
      };
    }
    case ActionTypes.SET_ASSIGNMENT_DESCRIPTION: {
      return {
        ...state,
        assignmentDescription: action.payload,
      };
    }
    case ActionTypes.SET_SELECTED_PORTFOLIOS: {
      return {
        ...state,
        selectedPortfolios: action.payload,
      };
    }
    case ActionTypes.SET_START_DATE: {
      return {
        ...state,
        startDate: action.payload,
      };
    }
    default:
      console.warn("Not a valid action type");
      return state;
  }
};

const defaultState: ContextState = {
  frequency: [],
  deliveryType: [],
  documentType: [],
  assignmentName: "",
  assignmentDescription: "",
  selectedPortfolios: [],
  startDate: new Date(),
};

type AssignmentStore = {
  state: ContextState;
  actions: {
    setFrequency: (frequency: string[]) => void;
    setDeliveryType: (type: string[]) => void;
    setDocumentType: (type: string[]) => void;
    setAssignmentName: (name: string) => void;
    setAssignmentDescription: (description: string) => void;
    setSelectedPortfolios: (portfolios: Portfolio[]) => void;
    resetForm: () => void;
    setStartDate: (date: Date) => void;
  };
};

export function AssignmentContextProvider({
  children,
}: AssignmentContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const store: AssignmentStore = {
    state: {
      frequency: state.frequency,
      deliveryType: state.deliveryType,
      documentType: state.documentType,
      assignmentName: state.assignmentName,
      assignmentDescription: state.assignmentDescription,
      selectedPortfolios: state.selectedPortfolios,
      startDate: state.startDate,
    },
    actions: {
      setStartDate: (startDate) => {
        dispatch({
          type: ActionTypes.SET_START_DATE,
          payload: startDate,
        });
      },
      setFrequency: (frequencies) => {
        dispatch({
          type: ActionTypes.SET_FREQUENCY,
          payload: frequencies,
        });
      },
      setDeliveryType: (type) => {
        dispatch({
          type: ActionTypes.SET_DELIVERY_TYPE,
          payload: type,
        });
      },
      setDocumentType: (type) => {
        dispatch({
          type: ActionTypes.SET_DOCUMENT_TYPE,
          payload: type,
        });
      },
      setAssignmentName: (name) => {
        dispatch({
          type: ActionTypes.SET_ASSIGNMENT_NAME,
          payload: name,
        });
      },
      setAssignmentDescription: (description) => {
        dispatch({
          type: ActionTypes.SET_ASSIGNMENT_DESCRIPTION,
          payload: description,
        });
      },
      setSelectedPortfolios: (portfolios) => {
        dispatch({
          type: ActionTypes.SET_SELECTED_PORTFOLIOS,
          payload: portfolios,
        });
      },
      resetForm: () => {
        dispatch({
          type: ActionTypes.SET_SELECTED_PORTFOLIOS,
          payload: [],
        });
        dispatch({
          type: ActionTypes.SET_ASSIGNMENT_DESCRIPTION,
          payload: "",
        });
        dispatch({
          type: ActionTypes.SET_ASSIGNMENT_NAME,
          payload: "",
        });
        dispatch({
          type: ActionTypes.SET_DOCUMENT_TYPE,
          payload: [],
        });
        dispatch({
          type: ActionTypes.SET_DELIVERY_TYPE,
          payload: [],
        });
        dispatch({
          type: ActionTypes.SET_FREQUENCY,
          payload: [],
        });
        dispatch({
          type: ActionTypes.SET_START_DATE,
          payload: new Date(),
        });
      },
    },
  };

  return (
    <AssignmentContext.Provider value={store}>
      {children}
    </AssignmentContext.Provider>
  );
}

export function useAssignmentContext(): AssignmentStore {
  const context = useContext(AssignmentContext);

  if (context === undefined) {
    console.warn(
      "useAssignmentContext has to be used within the AssignmentContextProvider",
    );
  }

  return context;
}
