export const STRIPE_PRICING_TABLES = {
  development: {
    personal: {
      tableId: "prctbl_1Pzjp6LLDUCMtz4pIlvr08Zg",
      publishableKey:
        "pk_test_51OfN5tLLDUCMtz4pwGFqOFp25dB4Po8LF7jneatXXuOpBDJ9uYoNdHUZ3SjKDZQ5zu2bl4H6qQeinauQVfyo5Pwc00XDh9NYHK",
    },
    business: {
      tableId: "prctbl_1PzknPLLDUCMtz4pXeB1nU05",
      publishableKey:
        "pk_test_51OfN5tLLDUCMtz4pwGFqOFp25dB4Po8LF7jneatXXuOpBDJ9uYoNdHUZ3SjKDZQ5zu2bl4H6qQeinauQVfyo5Pwc00XDh9NYHK",
    },
  },
  staging: {
    personal: {
      tableId: "prctbl_1PzlV8LLDUCMtz4pWmb6gBQw",
      publishableKey:
        "pk_test_51OfN5tLLDUCMtz4pwGFqOFp25dB4Po8LF7jneatXXuOpBDJ9uYoNdHUZ3SjKDZQ5zu2bl4H6qQeinauQVfyo5Pwc00XDh9NYHK",
    },
    business: {
      tableId: "prctbl_1PzlYbLLDUCMtz4pbSODKRHT",
      publishableKey:
        "pk_test_51OfN5tLLDUCMtz4pwGFqOFp25dB4Po8LF7jneatXXuOpBDJ9uYoNdHUZ3SjKDZQ5zu2bl4H6qQeinauQVfyo5Pwc00XDh9NYHK",
    },
  },
  production: {
    personal: {
      tableId: "prctbl_1PzkklLLDUCMtz4pMw0DTGlP",
      publishableKey:
        "pk_live_51OfN5tLLDUCMtz4p3GfZkL3Q6jVkVA11hSjigKflTMzUcJIGS5WlDIyOwkQXQr1l18Y2c0B6g9UmczLnHGuAH05u00foMBLXUs",
    },
    business: {
      tableId: "prctbl_1PzkH5LLDUCMtz4p44e50ZvS",
      publishableKey:
        "pk_live_51OfN5tLLDUCMtz4p3GfZkL3Q6jVkVA11hSjigKflTMzUcJIGS5WlDIyOwkQXQr1l18Y2c0B6g9UmczLnHGuAH05u00foMBLXUs",
    },
  },
};
