import React, { useEffect, useState } from "react";
import { getPortfolios } from "../api/api";
import { Portfolio } from "../types/types";

const useFetchPortfolios = (investorId = null) => {
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPortfolios = async (investorId) => {
    setIsLoading(true);
    let params = null;
    if (investorId) {
      params = `investor_id=${investorId}`;
    }
    getPortfolios(params)
      .then((data) => {
        setPortfolios(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPortfolios(investorId);
  }, [investorId]);

  return {
    portfolios,
    isLoading,
    error,
  };
};

export default useFetchPortfolios;
