import React, { useState, useEffect } from "react";
import Switch from "../../components/Switch/Switch";
import Button from "../../components/Button/Button";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import { useAppContext } from "../../contexts/AppContext";
import useIntegrations from "../../hooks/useIntegrations";
import { authRedtail } from "../../api/api";
import Modal from "../../components/Modal/Modal";
import TextInput from "../../components/Fields/TextInput";
import { Alert as AlertType } from "../../types/types";
import getString from "../../utils/getString";
import { updatePlanMessage } from "../../utils/getMessage.tsx";

import "./Integrations.css";

const services = [
  { label: "Redtail", key: "redtail", enabled: true, api: authRedtail },
  { label: "Orion", key: "orion", enabled: true, api: undefined },
  { label: "Wealthbox", key: "wealthbox", enabled: true, api: undefined },
  { label: "LinkedIn", key: "linkedin", enabled: true, api: undefined },
  { label: "Google Drive", key: "googleDrive", enabled: false, api: undefined },
  {
    label: "Google Calendar",
    key: "googleCalendar",
    enabled: false,
    api: undefined,
  },
  { label: "Outlook", key: "outlook", enabled: false, api: undefined },
];

interface IntegrationsProps {
  setAlertProps: (alert: AlertType) => void;
}

const Integrations = ({ setAlertProps }: IntegrationsProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    state: { user, integrationServices },
    actions: { setIntegrationServices },
  } = useAppContext();

  useEffect(() => {
    const integrationsAccess = user?.subscription?.has_integrations_access;
    setIsDisabled(!integrationsAccess);
  }, [user?.subscription?.has_integrations_access]);

  const {
    enableService,
    disableService,
    isServiceConnected,
    isIntegrationProcessing,
    selectedService,
    setSelectedService,
  } = useIntegrations();

  useEffect(() => {
    if (user) {
      setIntegrationServices(user.integrations);
    }
  }, [user]);

  const toggleSwitch = (service) => {
    if (!isServiceConnected(service?.key)) {
      enableService(service);
    } else {
      disableService(service.key);
    }
  };

  const handleAuth = (service) => {
    if (service.api) {
      setIsProcessing(true);
      service
        .api({
          username: username,
          password: password,
        })
        .then((response) => {
          setIsProcessing(false);
          setSelectedService(null);
          if (response.status !== 200) {
            setAlertProps({
              text: getString("authfailed"),
              type: "error",
            });
            toggleSwitch(service);
            return;
          }
          setAlertProps({ text: getString("changesSaved"), type: "success" });
        })
        .catch((e) => {
          setIsProcessing(false);
          toggleSwitch(service);
          setAlertProps({
            text: getString("authfailed"),
            type: "error",
          });
        });
    }
  };

  const handleClose = () => {
    disableService(selectedService.key);
    setSelectedService(null);
  };

  return (
    <>
      {selectedService && (
        <Modal
          isOpen={selectedService !== null}
          onClose={handleClose}
          closeOnClickOutside
          hasCloseButton
        >
          <div className="integrations-auth-modal-content">
            <div>Please enter your {selectedService?.label} login details</div>
            <TextInput
              align="row"
              data-testid="name"
              placeholder="Username"
              value={username ?? ""}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextInput
              align="row"
              data-testid="name"
              placeholder="Password"
              value={password ?? ""}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <Button
              color="Secondary"
              size="Small"
              onClick={() => handleAuth(selectedService)}
              disabled={username.length === 0 || password.length === 0}
            >
              {isProcessing ? "Saving..." : "Submit"}
            </Button>
          </div>
        </Modal>
      )}
      <div className="integrations-header">
        <div className="settings-item-label">Integrations</div>
      </div>
      <div className="integrations-container">
        {services.map((service) => {
          return (
            <div key={service.key} className="integrations-wrapper">
              <div
                className={`${
                  !service.enabled ||
                  isIntegrationProcessing(service.key) ||
                  isDisabled
                    ? "not-allowed"
                    : ""
                }`}
              >
                <Tooltip
                  position="right"
                  isHidden={!isDisabled}
                  text={updatePlanMessage(
                    getString("integrationAccess"),
                    user?.subscription?.plan_type,
                  )}
                  minWidth="200px"
                >
                  <div
                    className={`${
                      !service.enabled ||
                      isIntegrationProcessing(service.key) ||
                      isDisabled
                        ? "integration-disabled"
                        : ""
                    }`}
                  >
                    <Switch
                      checked={integrationServices[service.key]?.connected}
                      onChange={() => toggleSwitch(service)}
                      data-testid={`${service.key}Switch`}
                    />
                  </div>
                </Tooltip>
              </div>
              <label>{service.label}</label>
              {!service.enabled && (
                <div className="coming-soon-tag">Coming Soon</div>
              )}
              {isIntegrationProcessing(service.key) && (
                <div className="processing-tag">Processing</div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Integrations;
