export const sortByProperty = (arr, prop) => {
  return [...arr].sort((a, b) => {
    const valA = a[prop].toUpperCase();
    const valB = b[prop].toUpperCase();

    if (valA < valB) {
      return -1;
    }
    if (valA > valB) {
      return 1;
    }
    return 0; // values are equal
  });
};
