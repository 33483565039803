import { STRIPE_CUSTOMER_PORTAL_LINKS } from "../constants/stripe";
import React from "react";
import { Link } from "react-router-dom";

export function getLimitMessage(type) {
  return `You have reached your limit of ${type} on your current plan. Please upgrade your plan to unlock more. Reach
  out to us at admin@quantumai.dev to upgrade your plan.`;
}

export function getAccessMessage(type) {
  return `You do not have access to the ${type} feature on your current plan. Please upgrade your plan to unlock this feature. Reach
  out to us at admin@quantumai.dev to upgrade your plan.`;
}

export function updatePlanMessage(text, plan_type) {
  const env = process.env.NODE_ENV;
  const link = STRIPE_CUSTOMER_PORTAL_LINKS[env][plan_type];
  return (
    <>
      <span>{text}</span>
      <Link className="home-sidebar-item" to={link} style={{ padding: "0px" }}>
        {" "}
        <span style={{ color: "white", fontSize: "12px" }}>Upgrade Plan</span>
      </Link>
    </>
  );
}
