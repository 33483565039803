import React, { useEffect, useRef, useState } from "react";
import Pill from "./Pill";
import "./PillSelect.css";

interface Props {
  options: string[];
  selected: string[];
  onChange: (newSelected: string[]) => void;
  canAdd?: boolean;
}

const PillSelect = ({ options, selected, onChange, canAdd }: Props) => {
  const [adding, setAdding] = useState(false);
  const [customOptions, setCustomOptions] = useState([]);

  const addPillRef = useRef(null);

  const toggleSelected = (value) => {
    onChange(
      selected.includes(value)
        ? selected.filter((v) => v !== value)
        : [...selected, value]
    );
  };

  const isSelected = (value) => {
    return selected?.includes(value) || selected === value;
  };

  const handleAddPill = (e) => {
    e.preventDefault();
    onChange([...selected, addPillRef.current.value]);
    setAdding(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setAdding(false);
    }
  };

  useEffect(() => {
    if (adding) {
      addPillRef.current.focus();
    }
  }, [adding]);

  useEffect(() => {
    const newOptions = selected.filter(
      (item) => !options.includes(item) && !customOptions.includes(item)
    );
    setCustomOptions([...customOptions, ...newOptions]);
  }, [selected]);

  return (
    <div className="pill-select">
      {options.concat(customOptions).map((option) => (
        <Pill
          key={option}
          selected={isSelected(option)}
          onClick={() => toggleSelected(option)}
          style={{ margin: 5 }}
        >
          {option}
        </Pill>
      ))}
      {canAdd && (
        <Pill
          selected={false}
          onClick={() => setAdding(true)}
          style={{ margin: 5 }}
        >
          {!adding ? (
            "+ Add"
          ) : (
            <form onSubmit={handleAddPill}>
              <input
                ref={addPillRef}
                type="text"
                onKeyDown={handleKeyDown}
                onBlur={() => setAdding(false)}
              />
            </form>
          )}
        </Pill>
      )}
    </div>
  );
};

export default PillSelect;
