import React from "react";
import { getLimitMessage, getAccessMessage } from "../../utils/getMessage.tsx";
import { ERRORS } from "../../constants/error";

import "./HeaderAlert.css";

interface HeaderAlertProps {
  errorInfo: { errorType: string; type: string };
}

const HeaderAlert = ({ errorInfo }: HeaderAlertProps) => {
  if (!errorInfo?.errorType) {
    return null;
  }

  const errortype = errorInfo?.errorType;
  let text = "";
  if (errortype === ERRORS.limit) {
    text = getLimitMessage(errorInfo.type);
  }
  if (errortype === ERRORS.access) {
    text = getAccessMessage(errorInfo.type);
  }

  return <div className="message-container">{text}</div>;
};

export default HeaderAlert;
