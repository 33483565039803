import React, { useState, useCallback, useEffect } from "react";
import { getUsage } from "../../api/api.ts";
import { FileIcon } from "react-file-icon";
import Button from "../../components/Button/Button.tsx";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import { useAppContext } from "../../contexts/AppContext.tsx";
import getString from "../../utils/getString.ts";
import { updatePlanMessage } from "../../utils/getMessage.tsx";
import { getCookie } from "../../cookie.ts";
import useFetchPortfolios from "../../hooks/useFetchPortfolios.ts";
import Dropdown from "../../components/Dropdown/Dropdown.tsx";
import axios from "axios";

import "./Upload.css";

function FileUploadMultiple({
  showDownload = true,
  showDelete = true,
  showAddToFin = false,
  investorId = null,
}) {
  const [fileList, setFileList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(
    !investorId && { id: "", name: "General" },
  );
  const { portfolios } = useFetchPortfolios(investorId);

  const fetchFiles = useCallback(async () => {
    axios.get(`/api/files`).then((data) => {
      setFiles(
        data.data.map((f) => {
          return {
            name: f.name.split("/").pop(),
            link: f.link,
          };
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (selectedPortfolio?.id) {
      fetchPortfolioFiles(selectedPortfolio.id);
    }

    if (selectedPortfolio?.name === "General") {
      fetchFiles();
    }
  }, [selectedPortfolio]);

  const {
    state: { user, errorInfo, files, pinnedFilenames, uploadPinnedFiles },
    actions: { setFiles, setErrorInfo, setUsage, setPinnedFilenames },
  } = useAppContext();

  useEffect(() => {
    const uploadLimit = user?.subscription?.file_upload_limit;
    const numFiles = files.length + uploadPinnedFiles.length;
    const isUploadDisabled = uploadLimit !== null && uploadLimit <= numFiles;
    setIsDisabled(isUploadDisabled);
  }, [user?.subscription?.file_upload_limit, files, uploadPinnedFiles]);

  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };

  const handlePortfolioSelect = (portfolio) => {
    setSelectedPortfolio(portfolio);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!fileList) {
      return;
    }

    const selectedFiles = fileList ? [...fileList] : [];

    // 👇 Create new FormData object and append files
    const data = new FormData();
    selectedFiles.forEach((file, i) => {
      data.append(`file-${i}`, file, file.name);
    });

    // 👇 Uploading the files using the fetch API to the server
    setIsLoading(true);
    let path = "";
    if (selectedPortfolio.id) {
      path = `/api/portfolio/${selectedPortfolio.id}/files`;
    } else {
      path = `/api/files`;
    }
    return fetch(path, {
      method: "POST",
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: data,
    }).then((response) => {
      if (!response.ok) {
        return response.json().then((err) => {
          setErrorInfo({ errorType: err.message, type: "file uploads" });
        });
      }
      setFiles([...files, ...fileList]);
      getUsage().then((response) => setUsage(response));
      setIsLoading(false);
      setFileList([]);
    });
  };

  const handleAddToFin = (file) => {
    if (!pinnedFilenames.includes(file.name)) {
      setPinnedFilenames([...pinnedFilenames, file.name]);
    }
  };

  const fetchPortfolioFiles = useCallback(async (portfolioId) => {
    axios.get(`/api/portfolio/${portfolioId}/files`).then((data) => {
      setFiles(
        data.data.map((f) => {
          return {
            name: f.name.split("/").pop(),
            link: f.link,
          };
        }),
      );
    });
  }, []);

  const deleteFile = (file) => {
    if (selectedPortfolio.id) {
      axios
        .delete(
          `/api/portfolio/${selectedPortfolio.id}/files?filename=${file.name}`,
        )
        .then(() => fetchPortfolioFiles(selectedPortfolio.id));
    } else {
      axios.delete(`/api/files?filename=${file.name}`).then(() => fetchFiles());
    }
  };

  // files is not an array, but it's iterable, spread to get an array of files
  const selectedFiles = fileList ? [...fileList] : [];
  return (
    <div className="fin-upload-container">
      <div className="fin-upload-left-container">
        <div className="fin-upload-top-container">
          {investorId && (
            <Dropdown
              displayOption="name"
              options={portfolios}
              onSelect={handlePortfolioSelect}
              placeholder={investorId ? "Select portfolio..." : null}
            />
          )}
          <div className="fin-upload-box">
            {selectedFiles.length === 0 && (
              <label
                className={`custom-file-upload button ${
                  !selectedPortfolio && "disabled"
                }`}
              >
                {selectedPortfolio && (
                  <input
                    type="file"
                    onChange={selectedPortfolio && handleFileChange}
                    multiple
                    accept=".docx,.pdf,.xls,.xlsx,.csv,.txt"
                  />
                )}
                {getString("selectFiles")}
              </label>
            )}
            <div className="upload-files-container">
              {selectedFiles.length > 0 && (
                <Tooltip
                  position="right"
                  isHidden={!isDisabled}
                  text={updatePlanMessage(
                    getString("uploadFilesLimit"),
                    user?.subscription?.plan_type,
                  )}
                  minWidth="300px"
                >
                  <Button
                    color="Secondary"
                    disabled={isDisabled}
                    onClick={onSubmit}
                    text={getString("uploadFiles")}
                  />
                </Tooltip>
              )}
              <div className="upload-files-wrapper">
                {loading && errorInfo?.type !== "file uploads" && (
                  <div className="loader"></div>
                )}

                <ul className="fin-upload-files-upload">
                  {selectedFiles.map((file, i) => (
                    <li key={i}>{file.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ul className="file-list">
          {files.map((file, index) => (
            <li key={index} className="file-item">
              <div className="file-icon">
                <FileIcon
                  extension={file.name.split(".").pop()}
                  // {...defaultStyles[file.extension]}
                />
              </div>
              <span className="file-name">{file.name}</span>

              {showDownload && (
                <div>
                  <a
                    target="_blank"
                    href={file.link}
                    className="button file-browser-button"
                  >
                    {getString("download")}
                  </a>
                </div>
              )}
              {showDelete && (
                <div>
                  <button
                    onClick={() => deleteFile(file)}
                    className="button delete file-browser-button"
                  >
                    {getString("delete")}
                  </button>
                </div>
              )}
              {showAddToFin && (
                <div>
                  <button
                    onClick={() => handleAddToFin(file)}
                    className="button file-browser-button"
                  >
                    Add to chat
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function Upload({
  showDownload = true,
  showDelete = true,
  showAddToFin = true,
  investorId = null,
}) {
  return (
    <FileUploadMultiple
      showDownload={showDownload}
      showDelete={showDelete}
      showAddToFin={showAddToFin}
      investorId={investorId}
    />
  );
}

export default Upload;
