import axios from "axios";
import ReactMarkdown from "react-markdown";
import React, { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert/Alert.tsx";
import Button from "../../components/Button/Button.tsx";
import TextInput from "../../components/Fields/TextInput.tsx";
import { acceptTerms } from "../../api/api.ts";
import authBanner from "../../assets/auth3.png";
import orionLogo from "../../assets/orion_logo.png";
import googleLogo from "../../assets/google_logo.png";
import quailLogo from "../../assets/color-logo.png";
import getString from "../../utils/getString.ts";
import TermsModal from "../../features/Terms/TermsModal.tsx";
import { Carousel } from "../../components/Carousel/Carousel";
import { AUTH_SLIDES } from "./config.tsx";
import useHashQueryParams from "../../hooks/useHashQueryParams.ts";
import "./Auth.css";

function Auth() {
  const initialMode = window.location.hash.includes("#")
    ? window.location.hash.split("#")[1].split("?")[0]
    : null;
  const [mode, setMode] = useState(initialMode || "login/verify"); // login / register / reset-password-1 / reset-password-2 / login/verify
  if (initialMode === null) {
    window.location.hash = mode;
  }
  const queryParams = useHashQueryParams();
  const accountCreated = queryParams.get("account_created");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [timezone, setTimezone] = useState("");
  const [alertProps, setAlertProps] = useState({ text: "", type: "" });
  const [acceptedPolicies, setacceptedPolicies] = useState(false);
  const [termsPolicy, setPolicy] = useState(null);
  const isAuthenticated = useLoaderData();
  const navigate = useNavigate();
  const [initiatingDemo, setInitiatingDemo] = useState(false);

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const openTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(tz);
  }, []);

  const login = () => {
    if (!acceptedPolicies) {
      return;
    }
    axios
      .post("api/auth/login", { email, password, timezone })
      .then((response) => {
        setIsTermsModalOpen(false);
        navigate("/u");
      })
      .catch((e) => {
        setAlertProps({ text: e.message, type: "error" });
      });
  };

  const initDemo = () => {
    setInitiatingDemo(true);
    axios
      .post("api/demo")
      .then((response) => {
        navigate("/u");
      })
      .catch((e) => {
        setAlertProps({ text: e.message, type: "error" });
      });
  };

  const handleSubmitTerms = () => {
    if (!acceptedPolicies) {
      return;
    }
    acceptTerms("terms", { policy_id: termsPolicy.id, email: email })
      .then(() => {
        navigate("/verify", { state: { email: email } });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const origin = window.location.origin;
    const serverUrl = `/api/auth/${mode.toLowerCase()}`;

    if (mode === "register" || mode === "login/verify") {
      axios
        .post(serverUrl, { email, password, timezone })
        .then((response) => {
          setEmail(response.data.user_email);
          if (response.data.policy_type) {
            openTermsModal();
            setPolicy(response.data);
          } else {
            navigate("/verify", { state: { email: response.data.user_email } });
          }
        })
        .catch((e) => {
          setAlertProps({ text: e.message, type: "error" });
        });
    } else if (mode === "reset-password-1") {
      axios
        .post(serverUrl, { email, origin })
        .then((response) => {
          setAlertProps({ text: response.data.message, type: "success" });
        })
        .catch((e) => setAlertProps({ text: e.message, type: "error" }));
    } else if (mode === "reset-password-2") {
      axios
        .post(serverUrl, { token, password })
        .then((response) => {
          navigate("/", { replace: true });
        })
        .catch((e) => setAlertProps({ text: e.message, type: "error" }));
    }
  };

  const handleSignUp = () => {
    if (!email || !password) {
      setAlertProps({
        text: "Please provide an email and password to Sign Up.",
        type: "error",
      });
      return;
    }
    navigate("/signup", { state: { email, password } });
  };

  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/u");
    }

    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    setToken(token);
  }, [isAuthenticated, navigate, mode]);

  const customComponents = {
    a: ({ href, children }) => {
      if (href && href.startsWith("/policy")) {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", cursor: "pointer" }}
            href={href}
          >
            {children}
          </a>
        );
      }
    },
  };
  return (
    <div className="auth-container">
      <TermsModal
        closeTermsModal={closeTermsModal}
        isTermsModalOpen={isTermsModalOpen}
        closeOnClickOutside={true}
      >
        <ReactMarkdown components={customComponents}>
          {termsPolicy?.content}
        </ReactMarkdown>
        <div className="terms-container">
          <div className="policy-accept">
            <input
              type="checkbox"
              id="terms"
              checked={acceptedPolicies}
              onChange={() => setacceptedPolicies(!acceptedPolicies)}
            />
            <span>
              I accept the{" "}
              <a
                href="https://app.quantumai.dev/policy/terms"
                className="policy-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              ,{" "}
              <a
                href="https://app.quantumai.dev/policy/privacy"
                className="policy-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              , and{" "}
              <a
                href="https://app.quantumai.dev/policy/risk"
                className="policy-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Risk Disclosures
              </a>
              .
            </span>
          </div>
        </div>
        <Button
          color="Secondary"
          disabled={!acceptedPolicies}
          onClick={handleSubmitTerms}
        >
          Accept
        </Button>
      </TermsModal>
      <div className="auth-wrapper">
        <div className="auth-img-wrapper">
          <img
            src={authBanner}
            alt="Auth Page Banner"
            className="auth-banner"
          />
          <div className="overlay-container">
            <Carousel
              slides={AUTH_SLIDES}
              width={"100%"}
              height={"100%"}
              showArrows={false}
              autoSlide={true}
              loop={true}
            />
          </div>
        </div>
        <div className="form-container">
          <img
              src={quailLogo}
              alt="Auth Page Banner"
              className="auth-banner"
              style={{
                width: '140px',
                maxWidth: '500px',
                position: 'absolute',
                top: '20px',
                right: '20px'
              }}
          />

          <div className="form-box">
            {accountCreated && (
              <div>
                <Alert
                  time={5000}
                  type="success"
                  text="You have successfully subscribed! Please log in to begin using Q(Fin)"
                />
              </div>
            )}
            <div className="auth-alert">
              {alertProps.text && <Alert {...alertProps} />}
            </div>
            {/* title */}
            <h1>Welcome to Q(Fin)</h1>
            <div className="auth-subheader-container">
              <div>Redefining ROI:</div>
              <div>Return on Intelligence</div>
            </div>

            {/* subtitle */}
            <h4>{mode === "reset-password-1" && "Reset Password (Step 1)"}</h4>
            <h4>{mode === "reset-password-2" && "Reset Password (Step 2)"}</h4>

            {/* instructions - only for password reset */}
            <div className="auth-instructions">
              {mode === "reset-password-1" &&
                "Enter the email address for your account."}

              {mode === "reset-password-2" &&
                "Enter the new password you'd like to use."}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="textbox">
                {/* only hiden email when we're in second step of password reset */}
                {mode !== "reset-password-2" && (
                  <TextInput
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
                    required
                  />
                )}
              </div>
              <div className="textbox">
                {/* only hiden password when we're in first step of password reset */}
                {mode !== "reset-password-1" && (
                  <TextInput
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
                    required
                  />
                )}
              </div>
              <div className="button-container column">
                <Button
                  color="Secondary"
                  onClick={handleSubmit}
                  size="Large"
                  text={
                    mode === "reset-password-1" || mode === "reset-password-2"
                      ? "Submit"
                      : "Log in"
                  }
                ></Button>
                {mode === "login/verify" && !accountCreated && (
                  <>
                    <div className="divider-container">
                      <div className="line"></div>
                      <div className="divider-text">or</div>
                      <div className="line"></div>
                    </div>
                    <Button
                      disabled={false}
                      color="White"
                      onClick={handleSignUp}
                      size="Large"
                      text="Sign Up"
                    ></Button>
                  </>
                )}
                {mode === "register" && (
                  <Button
                    color="Text"
                    onClick={() => setMode("login/verify")}
                    text={getString("alreadyHaveAccount")}
                  />
                )}

                {/* {mode === "login/verify" && (
                <Button
                  color="Text"
                  onClick={() => setMode("register")}
                  text={getString("dontHaveAccount")}
                />
              )} */}

                {(mode === "reset-password-1" ||
                  mode === "reset-password-2") && (
                  <Button
                    color="Text"
                    onClick={() => setMode("login/verify")}
                    text={getString("back")}
                    size="Large"
                  />
                )}
              </div>
            </form>
          </div>
          <div className="auth-action-container">
            {mode === "login/verify" && (
              <div
                className="auth-action"
                onClick={() => setMode("reset-password-1")}
              >
                {getString("forgotPassword")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
