import React from "react";

import "./IconButton.css";

interface IconButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}

const IconButton = ({
  children,
  disabled,
  onClick,
  selected,
}: IconButtonProps) => {
  return (
    <div className={`icon-button-wrapper ${disabled && "disabled-wrapper"}`}>
      <div
        onClick={onClick}
        className={`icon-button ${selected && "selected"} ${
          disabled && "disabled"
        }`}
      >
        <div className={`icon-button-inner ${disabled && "no-events"}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default IconButton;
