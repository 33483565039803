import React, { useEffect, useState } from "react";
import { getPolicy } from "../api/api";
import { Portfolio } from "../types/types";

interface Props {
  policyType: string;
}

const useFetchPolicy = ({ policyType }: Props) => {
  const [policy, setPolicy] = useState<any[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPolicy = async (policyType) => {
    setIsLoading(true);
    getPolicy(policyType)
      .then((data) => {
        setPolicy(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (policyType) {
      fetchPolicy(policyType);
    }
  }, [policyType]);

  return {
    policy,
    isLoading,
    error,
  };
};

export default useFetchPolicy;
