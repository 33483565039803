import React, { useEffect, useState } from "react";
import { getTasks, patchTask } from "../api/api";

const useFetchTasks = () => {
  const [tasks, setTasks] = useState<any[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTasks = async () => {
    setIsLoading(true);
    getTasks()
      .then((data) => {
        setTasks(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  const updateTask = async (task) => {
    setIsLoading(true);
    patchTask(task.id, { is_completed: task.is_completed }).then((response) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return {
    tasks,
    isLoading,
    error,
    updateTask,
  };
};

export default useFetchTasks;
