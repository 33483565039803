import { useState, useEffect } from "react";

const useSort = (initialList) => {
  const [sortedItems, setSortedItems] = useState(initialList);
  const [sortConfig, setSortConfig] = useState(null);

  useEffect(() => {
    let sortedArray = [...initialList];
    if (sortConfig !== null) {
      sortedArray.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return -1;
        if (a[sortConfig.key] > b[sortConfig.key]) return 1;
        return 0;
      });
    }
    setSortedItems(sortedArray);
  }, [sortConfig, initialList]);

  const sortItems = (key) => {
    if (key === "None") {
      setSortConfig(null);
    } else {
      setSortConfig({ key });
    }
  };

  return {
    sortedItems,
    sortItems,
    sortConfig,
  };
};

export default useSort;
