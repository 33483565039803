import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useAppContext } from "../../contexts/AppContext";
import Tooltip from "../../components/Tooltip/Tooltip.tsx";
import getString from "../../utils/getString";
import { useAssignmentContext } from "../../contexts/AssignmentContext";
import { createAssignments, getUsage } from "../../api/api";
import { Assignment } from "../../types/types";
import { format } from "date-fns";

interface CreateButtonProps {
  onAddAssignments: (assignments: Assignment[]) => void;
  selectedAssignment: Assignment;
  patchAssignment: (assignment: Assignment, payload: any) => void;
  setSelectedValue: (value: any) => void;
  isDisabled?: boolean;
  numAssignments: number;
}

function CreateButton({
  nextRunDate,
  onAddAssignments,
  selectedAssignment,
  patchAssignment,
  setSelectedValue,
  numAssignments,
}: CreateButtonProps) {
  const { state, actions } = useAssignmentContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    state: { user },
    actions: { setErrorInfo, setUsage },
  } = useAppContext();

  useEffect(() => {
    const assignmentLimit = user?.subscription?.assignment_limit;
    const isAssignmentsDisabled =
      assignmentLimit !== null && numAssignments >= assignmentLimit;
    setIsDisabled(isAssignmentsDisabled);
  }, [numAssignments, user?.subscription?.assignment_limit]);

  const isComplete =
    state.assignmentName &&
    state.assignmentDescription &&
    state.documentType.length > 0 &&
    state.deliveryType.length > 0 &&
    state.frequency.length > 0 &&
    state.selectedPortfolios &&
    (state.startDate || nextRunDate) &&
    user;

  const handleSubmit = () => {
    if (state.selectedPortfolios.length === 0) {
      setIsDisabled(true);
      return;
    }
    if (isComplete) {
      const payload = {
        advisor_id: user.advisor_id,
        portfolios: state.selectedPortfolios,
        name: state.assignmentName,
        description: state.assignmentDescription,
        frequency: state.frequency,
        document_type: state.documentType,
        delivery_type: state.deliveryType,
        start_date: format(state.startDate, "yyyy-MM-dd"),
        portfolio_id: state.selectedPortfolios[0].id,
        next_run_date: format(nextRunDate, "yyyy-MM-dd"),
      };
      if (selectedAssignment) {
        patchAssignment(selectedAssignment, payload);
      } else {
        createAssignments(payload).then((response) => {
          getUsage().then((response) => setUsage(response));
          if (!response.ok) {
            setErrorInfo({ errorType: response.message, type: "assignments" });
          }
          onAddAssignments(response.data);
        });
      }
      setSelectedValue(null);
      actions.resetForm();
    }
  };

  const ingestDataMessage = (
    <>
      <span>{getString("ingestData")}</span>
      <Link
        className="home-sidebar-item"
        to={"/u/app/settings/profile"}
        style={{ padding: "0px" }}
      >
        {" "}
        <span style={{ color: "white", fontSize: "12px" }}>
          Connect Accounts
        </span>
      </Link>
    </>
  );

  return (
    <Tooltip
      position="top"
      isHidden={!isDisabled}
      text={ingestDataMessage}
      minWidth="300px"
    >
      <Button
        color="Secondary"
        disabled={isDisabled || !isComplete}
        onClick={handleSubmit}
        text="Submit"
      />
    </Tooltip>
  );
}

export default CreateButton;
