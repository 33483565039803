import { useEffect, useState } from "react";
import { STRIPE_PRICING_TABLES } from "./config";

import "./StripePricingTable.css";

const StripePricingTable = ({ type }) => {
  const env = process.env.NODE_ENV;
  const tableId = STRIPE_PRICING_TABLES[env][type]["tableId"];
  const publishableKey = STRIPE_PRICING_TABLES[env][type]["publishableKey"];

  useEffect(() => {
    if (
      !document.querySelector(
        'script[src="https://js.stripe.com/v3/pricing-table.js"]',
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="stripe-table-container">
      <stripe-pricing-table
        pricing-table-id={tableId}
        publishable-key={publishableKey}
      ></stripe-pricing-table>
    </div>
  );
};

export default StripePricingTable;
