import React, { useState, useEffect } from "react";
import "./AccountType.css";
import { useLocation, useNavigate } from "react-router-dom";
import { createUser } from "../../api/api";

// Single speed variable for all typing effects
const TYPING_SPEED = 40;

const useTypingEffect = (text, speed = TYPING_SPEED, start = true) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  useEffect(() => {
    if (!start || !text) return;

    setIsTypingComplete(false);

    let index = displayedText.length;
    let intervalId = null;

    const type = () => {
      if (index < text.length) {
        setDisplayedText(text.slice(0, index + 1));
        index += 1;
      } else {
        clearInterval(intervalId);
        setIsTypingComplete(true);
      }
    };

    intervalId = setInterval(type, speed);

    return () => {
      clearInterval(intervalId);
    };
  }, [text, speed, start]);

  return [displayedText, isTypingComplete];
};

const TypingText = ({
  text,
  speed = TYPING_SPEED,
  className,
  onComplete,
  start = true,
}) => {
  const [displayedText, isTypingComplete] = useTypingEffect(text, speed, start);

  useEffect(() => {
    if (isTypingComplete && onComplete) {
      onComplete();
    }
  }, [isTypingComplete, onComplete]);

  return start ? <p className={className}>{displayedText}</p> : null;
};

const BriefcaseIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
  </svg>
);

const UserIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);

const AccountType = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [showNameInput, setShowNameInput] = useState(false);
  const [showNameInputField, setShowNameInputField] = useState(false);
  const [showGreeting, setShowGreeting] = useState(false);
  const [showAccountTypes, setShowAccountTypes] = useState(false);
  const [showAccountQuestion, setShowAccountQuestion] = useState(false);
  const [showAccountButtons, setShowAccountButtons] = useState(false);
  const email = location.state?.email || null;
  const password = location.state?.password || null;
  const phoneNumber = location.state?.phoneNumber || null;

  const introText =
    "I am your AI-powered digital financial analyst trained to be safe, secure, and trustworthy.";

  const handleNameSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      createUser(email, password, name, phoneNumber).then((response) => {
        setShowNameInputField(false);
        setShowGreeting(true);
      });
    }
  };

  const handleBusinessPricing = () => {
    navigate("/signup/account-type/business", {
      state: { email, password, phoneNumber },
    });
  };

  const handlePersonalPricing = () => {
    navigate("/signup/account-type/personal", {
      state: { email, password, phoneNumber },
    });
  };

  const splitName = (fullName: string): NameParts => {
    const nameParts = fullName.trim().split(" ");
    const firstName = nameParts[0];
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
    return { firstName, lastName };
  };

  const { firstName, lastName } = splitName(name);

  return (
    <div className="fin-onboarding-account-container">
      <div className="fin-onboarding-account-wrapper">
        <div className="fin-onboarding-account-items">
          <h1 className="fin-title">Hi, I'm Fin!</h1>
          <TypingText
            key="introText"
            text={introText}
            className="fin-text"
            onComplete={() => setShowNameInput(true)}
            start={true}
          />

          {!showGreeting && (
            <>
              <TypingText
                key="knowEachOther"
                text="What is your name?"
                className="fin-text"
                onComplete={() => setShowNameInputField(true)}
                start={showNameInput}
              />

              {showNameInputField && (
                <form onSubmit={handleNameSubmit}>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="fin-input"
                  />
                </form>
              )}
            </>
          )}

          <TypingText
            key={`greet-${name}`}
            text={`It is a pleasure to meet you, ${firstName}. I am excited to start working together!`}
            className="fin-text"
            onComplete={() => setShowAccountQuestion(true)}
            start={showGreeting}
          />

          <TypingText
            key="businessOrPersonal"
            text="Are you using Q(Fin) for business or personal?"
            className="fin-text"
            onComplete={() => setShowAccountButtons(true)}
            start={showAccountQuestion}
          />

          {showAccountButtons && (
            <div className="fin-button-group">
              <button className="fin-button" onClick={handleBusinessPricing}>
                <BriefcaseIcon className="fin-button-icon" />
                <span className="fin-button-title">Business</span>
                <p className="fin-button-description">
                  Manage client accounts, connect to CRMs & Custodians, and so
                  much more.
                </p>
              </button>
              <button className="fin-button" onClick={handlePersonalPricing}>
                <UserIcon className="fin-button-icon" />
                <span className="fin-button-title">Personal</span>
                <p className="fin-button-description">
                  Learn, research, and optimize investment outcomes.
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountType;
