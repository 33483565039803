import React from "react";
import InvestorCard from "../../features/InvestorCard/InvestorCard";

const SearchCardList = ({
  searchQuery,
  searchResults,
  data,
  setOpenNotes,
  setSelectedInvestor,
}) => {
  if (!data) {
    return null;
  }
  const displayList =
    searchQuery === "" ? data : searchResults.map((result) => result.item);

  return (
    <div className="card-list">
      {displayList.map((item, index) => (
        <InvestorCard
          key={index}
          item={item}
          setOpenNotes={setOpenNotes}
          setSelectedInvestor={setSelectedInvestor}
        />
      ))}
    </div>
  );
};

export default SearchCardList;
