import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  onError?: (message: string) => void;
}

const usePortfolios = (props?: Props) => {
  const [portfolios, setPortfolios] = useState([]);
  const [processing, setProcessing] = useState(false);

  const patchPortfolios = async (portfolios) => {
    setProcessing(true);
    const response = await axios.patch("/api/portfolios", { portfolios });
    refresh();
    setProcessing(false);
    return response;
  };

  const refresh = () => {
    setProcessing(true);
    axios
      .get("/api/portfolios")
      .then((response) => {
        setProcessing(false);
        setPortfolios(response.data);
      })
      .catch((e) => {
        if (props?.onError) props.onError(e);
        setProcessing(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  return {
    processing,
    portfolios,
    patchPortfolios,
  };
};

export default usePortfolios;
