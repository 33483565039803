import React from "react";
import RCSlider from "rc-slider";
import "rc-slider/assets/index.css";
import "./Slider.css";

interface Props {
  value: number | number[];
  onChange: (value: number | number[]) => void;
  startLabel: string;
  endLabel: string;
}

const Slider = ({ value, onChange, startLabel, endLabel }: Props) => {
  return (
    <div className="slider">
      <RCSlider
        value={value}
        onChange={onChange}
        styles={{
          track: { backgroundColor: "rgb(63, 116, 254)" },
          handle: { borderColor: "rgb(63, 116, 254)" },
        }}
      />
      {startLabel && <span className="slider-start-label">{startLabel}</span>}
      {endLabel && <span className="slider-end-label">{endLabel}</span>}
    </div>
  );
};

export default Slider;
